import React from 'react';
import * as styles from './styles.module.scss';

const NoiseSVG = ({ color }) => {
  return <svg width="358" height="116" viewBox="0 0 358 116" xmlns="http://www.w3.org/2000/svg">
  <path d="M251.73 0.913113C251.502 0.685089 251.73 0.685089 251.73 0.685089C251.73 0.685089 251.958 0.685089 251.73 0.913113C251.958 0.913113 251.73 0.913113 251.73 0.913113Z" fill={ color }/>
  <path d="M245.81 1.14114C245.936 1.14114 246.038 1.03905 246.038 0.913113C246.038 0.787179 245.936 0.685089 245.81 0.685089C245.684 0.685089 245.582 0.787179 245.582 0.913113C245.582 1.03905 245.684 1.14114 245.81 1.14114Z" fill={ color }/>
  <path d="M239.657 1.14114C239.43 1.14114 239.43 0.913113 239.43 0.913113L239.657 0.685089C239.885 0.685089 239.885 0.913113 239.885 0.913113C239.885 0.913113 239.885 1.14114 239.657 1.14114Z" fill={ color }/>
  <path d="M233.733 1.36844C233.985 1.36844 234.189 1.16426 234.189 0.912391C234.189 0.660524 233.985 0.456345 233.733 0.456345C233.481 0.456345 233.277 0.660524 233.277 0.912391C233.277 1.16426 233.481 1.36844 233.733 1.36844Z" fill={ color }/>
  <path d="M227.811 1.36844C227.583 1.36844 227.355 1.14041 227.355 0.912391C227.355 0.684368 227.583 0.456345 227.811 0.456345C228.039 0.456345 228.267 0.684368 228.267 0.912391C228.267 1.14041 228.039 1.36844 227.811 1.36844Z" fill={ color }/>
  <path d="M221.889 1.36844C221.661 1.36844 221.434 1.14041 221.434 0.912391C221.434 0.684368 221.661 0.456345 221.889 0.456345C222.117 0.456345 222.345 0.684368 222.345 0.912391C222.345 1.14041 222.117 1.36844 221.889 1.36844Z" fill={ color }/>
  <path d="M215.737 1.36844C215.509 1.36844 215.281 1.14041 215.281 0.912391C215.281 0.684368 215.509 0.456345 215.737 0.456345C215.965 0.456345 216.192 0.684368 216.192 0.912391C216.42 1.14041 216.192 1.36844 215.737 1.36844Z" fill={ color }/>
  <path d="M209.815 1.36844C210.067 1.36844 210.271 1.16426 210.271 0.912391C210.271 0.660524 210.067 0.456345 209.815 0.456345C209.563 0.456345 209.359 0.660524 209.359 0.912391C209.359 1.16426 209.563 1.36844 209.815 1.36844Z" fill={ color }/>
  <path d="M203.89 1.36814C203.663 1.36814 203.207 1.14012 203.207 0.68407C203.207 0.456047 203.435 0 203.89 0C204.118 0 204.574 0.228023 204.574 0.68407C204.346 1.14012 204.118 1.36814 203.89 1.36814Z" fill={ color }/>
  <path d="M197.742 1.5969C198.119 1.5969 198.425 1.29063 198.425 0.91283C198.425 0.535028 198.119 0.22876 197.742 0.22876C197.365 0.22876 197.059 0.535028 197.059 0.91283C197.059 1.29063 197.365 1.5969 197.742 1.5969Z" fill={ color }/>
  <path d="M191.816 1.5969C192.194 1.5969 192.5 1.29063 192.5 0.91283C192.5 0.535028 192.194 0.22876 191.816 0.22876C191.439 0.22876 191.133 0.535028 191.133 0.91283C191.133 1.29063 191.439 1.5969 191.816 1.5969Z" fill={ color }/>
  <path d="M185.894 1.5969C186.272 1.5969 186.578 1.29063 186.578 0.91283C186.578 0.535028 186.272 0.22876 185.894 0.22876C185.517 0.22876 185.211 0.535028 185.211 0.91283C185.211 1.29063 185.517 1.5969 185.894 1.5969Z" fill={ color }/>
  <path d="M179.742 1.36814C179.514 1.36814 179.059 1.14012 179.059 0.68407C179.059 0.456047 179.286 0 179.742 0C179.97 0 180.425 0.228023 180.425 0.68407C180.425 1.14012 180.198 1.36814 179.742 1.36814Z" fill={ color }/>
  <path d="M173.819 1.36844C173.591 1.36844 173.363 1.14041 173.363 0.912391C173.363 0.684368 173.591 0.456345 173.819 0.456345C174.047 0.456345 174.275 0.684368 174.275 0.912391C174.502 1.14041 174.275 1.36844 173.819 1.36844Z" fill={ color }/>
  <path d="M167.897 1.36844C168.149 1.36844 168.353 1.16426 168.353 0.912391C168.353 0.660524 168.149 0.456345 167.897 0.456345C167.645 0.456345 167.441 0.660524 167.441 0.912391C167.441 1.16426 167.645 1.36844 167.897 1.36844Z" fill={ color }/>
  <path d="M161.971 1.36844C161.743 1.36844 161.516 1.14041 161.516 0.912391C161.516 0.684368 161.743 0.456345 161.971 0.456345C162.199 0.456345 162.427 0.684368 162.427 0.912391C162.427 1.14041 162.199 1.36844 161.971 1.36844Z" fill={ color }/>
  <path d="M155.823 1.36844C156.074 1.36844 156.278 1.16426 156.278 0.912391C156.278 0.660524 156.074 0.456345 155.823 0.456345C155.571 0.456345 155.367 0.660524 155.367 0.912391C155.367 1.16426 155.571 1.36844 155.823 1.36844Z" fill={ color }/>
  <path d="M149.901 1.36844C149.673 1.36844 149.445 1.14041 149.445 0.912391C149.445 0.684368 149.673 0.456345 149.901 0.456345C150.129 0.456345 150.357 0.684368 150.357 0.912391C150.357 1.14041 150.129 1.36844 149.901 1.36844Z" fill={ color }/>
  <path d="M143.975 1.36844C143.747 1.36844 143.52 1.14041 143.52 0.912391C143.52 0.684368 143.747 0.456345 143.975 0.456345C144.203 0.456345 144.431 0.684368 144.431 0.912391C144.431 1.14041 144.203 1.36844 143.975 1.36844Z" fill={ color }/>
  <path d="M137.827 1.14085C137.599 1.14085 137.371 0.912829 137.371 0.684806C137.371 0.456783 137.599 0.22876 137.827 0.22876C138.055 0.22876 138.282 0.456783 138.282 0.684806C138.282 1.14085 138.055 1.14085 137.827 1.14085Z" fill={ color }/>
  <path d="M131.901 1.36844C132.153 1.36844 132.357 1.16426 132.357 0.912391C132.357 0.660524 132.153 0.456345 131.901 0.456345C131.649 0.456345 131.445 0.660524 131.445 0.912391C131.445 1.16426 131.649 1.36844 131.901 1.36844Z" fill={ color }/>
  <path d="M125.978 1.14114C126.104 1.14114 126.206 1.03905 126.206 0.913113C126.206 0.787179 126.104 0.685089 125.978 0.685089C125.852 0.685089 125.75 0.787179 125.75 0.913113C125.75 1.03905 125.852 1.14114 125.978 1.14114Z" fill={ color }/>
  <path d="M119.829 1.14114C119.602 1.14114 119.602 0.913113 119.602 0.913113C119.602 0.685089 119.829 0.685089 119.829 0.685089C120.057 0.685089 120.057 0.913113 120.057 0.913113C120.285 0.913113 120.057 1.14114 119.829 1.14114Z" fill={ color }/>
  <path d="M113.907 1.14103C114.033 1.14103 114.135 1.03894 114.135 0.913006C114.135 0.787072 114.033 0.684982 113.907 0.684982C113.782 0.684982 113.68 0.787072 113.68 0.913006C113.68 1.03894 113.782 1.14103 113.907 1.14103Z" fill={ color }/>
  <path d="M107.983 0.912392C107.756 0.912392 107.756 0.912392 107.983 0.912392C107.756 0.684368 107.983 0.456345 107.983 0.456345C107.983 0.456345 108.211 0.684368 107.983 0.912392C108.211 0.912392 107.983 0.912392 107.983 0.912392Z" fill={ color }/>
  <path d="M102.06 1.14103C102.186 1.14103 102.288 1.03894 102.288 0.913006C102.288 0.787072 102.186 0.684982 102.06 0.684982C101.934 0.684982 101.832 0.787072 101.832 0.913006C101.832 1.03894 101.934 1.14103 102.06 1.14103Z" fill={ color }/>
  <path d="M284.763 7.06983C284.889 7.06983 284.991 6.96774 284.991 6.84181C284.991 6.71588 284.889 6.61378 284.763 6.61378C284.637 6.61378 284.535 6.71588 284.535 6.84181C284.535 6.96774 284.637 7.06983 284.763 7.06983Z" fill={ color }/>
  <path d="M278.611 7.06983C278.736 7.06983 278.838 6.96774 278.838 6.84181C278.838 6.71588 278.736 6.61378 278.611 6.61378C278.485 6.61378 278.383 6.71588 278.383 6.84181C278.383 6.96774 278.485 7.06983 278.611 7.06983Z" fill={ color }/>
  <path d="M272.69 7.29723C272.462 7.29723 272.234 7.0692 272.234 6.84118C272.234 6.61316 272.462 6.38513 272.69 6.38513C272.918 6.38513 273.146 6.61316 273.146 6.84118C273.146 7.0692 272.918 7.29723 272.69 7.29723Z" fill={ color }/>
  <path d="M266.765 7.52569C267.143 7.52569 267.449 7.21942 267.449 6.84162C267.449 6.46382 267.143 6.15755 266.765 6.15755C266.388 6.15755 266.082 6.46382 266.082 6.84162C266.082 7.21942 266.388 7.52569 266.765 7.52569Z" fill={ color }/>
  <path d="M260.844 7.52569C260.388 7.52569 260.16 7.29766 260.16 6.84162C260.16 6.38557 260.388 6.15755 260.844 6.15755C261.299 6.15755 261.527 6.38557 261.527 6.84162C261.527 7.29766 261.071 7.52569 260.844 7.52569Z" fill={ color }/>
  <path d="M254.691 7.52569C255.069 7.52569 255.375 7.21942 255.375 6.84162C255.375 6.46382 255.069 6.15755 254.691 6.15755C254.314 6.15755 254.008 6.46382 254.008 6.84162C254.008 7.21942 254.314 7.52569 254.691 7.52569Z" fill={ color }/>
  <path d="M248.771 7.75299C248.315 7.75299 247.859 7.29694 247.859 6.8409C247.859 6.38485 248.315 5.9288 248.771 5.9288C249.226 5.9288 249.682 6.38485 249.682 6.8409C249.682 7.29694 249.226 7.75299 248.771 7.75299Z" fill={ color }/>
  <path d="M242.845 7.75299C242.389 7.75299 241.934 7.29694 241.934 6.8409C241.934 6.38485 242.389 5.9288 242.845 5.9288C243.3 5.9288 243.756 6.38485 243.756 6.8409C243.756 7.29694 243.3 7.75299 242.845 7.75299Z" fill={ color }/>
  <path d="M236.696 7.75299C236.241 7.75299 235.785 7.29694 235.785 6.8409C235.785 6.38485 236.241 5.9288 236.696 5.9288C237.152 5.9288 237.608 6.38485 237.608 6.8409C237.608 7.29694 237.38 7.75299 236.696 7.75299Z" fill={ color }/>
  <path d="M230.771 7.75299C230.315 7.75299 229.859 7.29694 229.859 6.8409C229.859 6.38485 230.315 5.9288 230.771 5.9288C231.226 5.9288 231.682 6.38485 231.682 6.8409C231.682 7.29694 231.226 7.75299 230.771 7.75299Z" fill={ color }/>
  <path d="M224.849 7.75299C224.393 7.75299 223.938 7.29694 223.938 6.8409C223.938 6.38485 224.393 5.9288 224.849 5.9288C225.304 5.9288 225.76 6.38485 225.76 6.8409C225.76 7.29694 225.304 7.75299 224.849 7.75299Z" fill={ color }/>
  <path d="M218.696 7.75299C218.241 7.75299 217.785 7.29694 217.785 6.8409C217.785 6.38485 218.241 5.9288 218.696 5.9288C219.152 5.9288 219.608 6.38485 219.608 6.8409C219.835 7.29694 219.38 7.75299 218.696 7.75299Z" fill={ color }/>
  <path d="M212.776 7.75387C212.092 7.75387 211.637 7.29782 211.637 6.61375C211.637 5.92968 212.092 5.47363 212.776 5.47363C213.459 5.47363 213.915 5.92968 213.915 6.61375C213.915 7.29782 213.459 7.75387 212.776 7.75387Z" fill={ color }/>
  <path d="M206.854 7.75387C206.17 7.75387 205.715 7.29782 205.715 6.61375C205.715 5.92968 206.17 5.47363 206.854 5.47363C207.537 5.47363 207.993 5.92968 207.993 6.61375C207.765 7.29782 207.31 7.75387 206.854 7.75387Z" fill={ color }/>
  <path d="M200.928 7.75387C200.245 7.75387 199.789 7.29782 199.789 6.61375C199.789 5.92968 200.245 5.47363 200.928 5.47363C201.612 5.47363 202.067 5.92968 202.067 6.61375C201.839 7.29782 201.384 7.75387 200.928 7.75387Z" fill={ color }/>
  <path d="M194.778 7.75299C194.323 7.75299 193.867 7.29694 193.867 6.8409C193.867 6.38485 194.323 5.9288 194.778 5.9288C195.234 5.9288 195.69 6.38485 195.69 6.8409C195.917 7.29694 195.462 7.75299 194.778 7.75299Z" fill={ color }/>
  <path d="M188.857 7.75299C189.36 7.75299 189.768 7.34463 189.768 6.8409C189.768 6.33716 189.36 5.9288 188.857 5.9288C188.353 5.9288 187.945 6.33716 187.945 6.8409C187.945 7.34463 188.353 7.75299 188.857 7.75299Z" fill={ color }/>
  <path d="M182.931 7.75299C182.475 7.75299 182.02 7.29694 182.02 6.8409C182.02 6.38485 182.475 5.9288 182.931 5.9288C183.386 5.9288 183.842 6.38485 183.842 6.8409C183.842 7.29694 183.386 7.75299 182.931 7.75299Z" fill={ color }/>
  <path d="M176.778 7.75299C176.323 7.75299 175.867 7.29694 175.867 6.8409C175.867 6.38485 176.323 5.9288 176.778 5.9288C177.234 5.9288 177.69 6.38485 177.69 6.8409C177.69 7.29694 177.462 7.75299 176.778 7.75299Z" fill={ color }/>
  <path d="M170.857 7.75299C170.401 7.75299 169.945 7.29694 169.945 6.8409C169.945 6.38485 170.401 5.9288 170.857 5.9288C171.312 5.9288 171.768 6.38485 171.768 6.8409C171.768 7.29694 171.312 7.75299 170.857 7.75299Z" fill={ color }/>
  <path d="M164.935 7.75299C164.479 7.75299 164.023 7.29694 164.023 6.8409C164.023 6.38485 164.479 5.9288 164.935 5.9288C165.39 5.9288 165.846 6.38485 165.846 6.8409C165.846 7.29694 165.39 7.75299 164.935 7.75299Z" fill={ color }/>
  <path d="M158.786 7.75299C158.331 7.75299 157.875 7.29694 157.875 6.8409C157.875 6.38485 158.331 5.9288 158.786 5.9288C159.242 5.9288 159.697 6.38485 159.697 6.8409C159.697 7.29694 159.47 7.75299 158.786 7.75299Z" fill={ color }/>
  <path d="M152.86 7.75299C152.405 7.75299 151.949 7.29694 151.949 6.8409C151.949 6.38485 152.405 5.9288 152.86 5.9288C153.316 5.9288 153.772 6.38485 153.772 6.8409C153.772 7.29694 153.316 7.75299 152.86 7.75299Z" fill={ color }/>
  <path d="M146.937 7.52496C146.482 7.52496 146.254 7.06892 146.254 6.8409C146.254 6.38485 146.71 5.9288 146.937 5.9288C147.393 5.9288 147.849 6.38485 147.849 6.8409C147.621 7.29694 147.393 7.52496 146.937 7.52496Z" fill={ color }/>
  <path d="M141.017 7.52569C140.562 7.52569 140.334 7.29766 140.334 6.84162C140.106 6.38557 140.562 6.15755 141.017 6.15755C141.473 6.15755 141.701 6.38557 141.701 6.84162C141.701 7.29766 141.245 7.52569 141.017 7.52569Z" fill={ color }/>
  <path d="M134.863 7.52569C134.407 7.52569 134.18 7.29766 134.18 6.84162C134.18 6.38557 134.407 6.15755 134.863 6.15755C135.319 6.15755 135.547 6.38557 135.547 6.84162C135.547 7.29766 135.319 7.52569 134.863 7.52569Z" fill={ color }/>
  <path d="M128.941 7.52569C129.319 7.52569 129.625 7.21942 129.625 6.84162C129.625 6.46382 129.319 6.15755 128.941 6.15755C128.564 6.15755 128.258 6.46382 128.258 6.84162C128.258 7.21942 128.564 7.52569 128.941 7.52569Z" fill={ color }/>
  <path d="M123.019 7.52569C122.792 7.52569 122.336 7.29766 122.336 6.84162C122.336 6.38557 122.564 6.15755 123.019 6.15755C123.247 6.15755 123.703 6.38557 123.703 6.84162C123.703 7.29766 123.247 7.52569 123.019 7.52569Z" fill={ color }/>
  <path d="M116.866 7.29723C116.638 7.29723 116.41 7.0692 116.41 6.84118C116.41 6.61316 116.638 6.38513 116.866 6.38513C117.094 6.38513 117.321 6.61316 117.321 6.84118C117.549 7.0692 117.321 7.29723 116.866 7.29723Z" fill={ color }/>
  <path d="M110.944 7.29723C111.196 7.29723 111.4 7.09305 111.4 6.84118C111.4 6.58931 111.196 6.38513 110.944 6.38513C110.692 6.38513 110.488 6.58931 110.488 6.84118C110.488 7.09305 110.692 7.29723 110.944 7.29723Z" fill={ color }/>
  <path d="M105.022 7.29723C104.794 7.29723 104.566 7.0692 104.566 6.84118C104.566 6.61316 104.794 6.38513 105.022 6.38513C105.25 6.38513 105.478 6.61316 105.478 6.84118C105.478 7.0692 105.25 7.29723 105.022 7.29723Z" fill={ color }/>
  <path d="M98.8697 7.29723C99.1213 7.29723 99.3253 7.09305 99.3253 6.84118C99.3253 6.58931 99.1213 6.38513 98.8697 6.38513C98.6181 6.38513 98.4141 6.58931 98.4141 6.84118C98.4141 7.09305 98.6181 7.29723 98.8697 7.29723Z" fill={ color }/>
  <path d="M92.9466 7.06983C93.0724 7.06983 93.1744 6.96774 93.1744 6.84181C93.1744 6.71588 93.0724 6.61378 92.9466 6.61378C92.8207 6.61378 92.7188 6.71588 92.7188 6.84181C92.7188 6.96774 92.8207 7.06983 92.9466 7.06983Z" fill={ color }/>
  <path d="M87.0247 7.06983C86.7969 7.06983 86.7969 6.84181 86.7969 6.84181C86.7969 6.61379 87.0247 6.61378 87.0247 6.61378C87.2525 6.61378 87.2525 6.84181 87.2525 6.84181C87.2525 7.06983 87.0247 7.06983 87.0247 7.06983Z" fill={ color }/>
  <path d="M81.1028 7.06983C81.2286 7.06983 81.3306 6.96774 81.3306 6.84181C81.3306 6.71588 81.2286 6.61378 81.1028 6.61378C80.977 6.61378 80.875 6.71588 80.875 6.84181C80.875 6.96774 80.977 7.06983 81.1028 7.06983Z" fill={ color }/>
  <path d="M74.9505 7.06983C75.0763 7.06983 75.1783 6.96774 75.1783 6.84181C75.1783 6.71588 75.0763 6.61378 74.9505 6.61378C74.8247 6.61378 74.7227 6.71588 74.7227 6.84181C74.7227 6.96774 74.8247 7.06983 74.9505 7.06983Z" fill={ color }/>
  <path d="M299.572 12.9976C299.697 12.9976 299.799 12.8955 299.799 12.7695C299.799 12.6436 299.697 12.5415 299.572 12.5415C299.446 12.5415 299.344 12.6436 299.344 12.7695C299.344 12.8955 299.446 12.9976 299.572 12.9976Z" fill={ color }/>
  <path d="M293.647 13.226C293.419 13.226 293.191 12.998 293.191 12.77C293.191 12.542 293.419 12.3139 293.647 12.3139C293.875 12.3139 294.103 12.542 294.103 12.77C294.103 12.998 293.875 13.226 293.647 13.226Z" fill={ color }/>
  <path d="M287.722 13.4544C287.267 13.4544 287.039 13.2264 287.039 12.7703C287.039 12.3143 287.267 12.0862 287.722 12.0862C288.178 12.0862 288.406 12.3143 288.406 12.7703C288.406 13.2264 288.178 13.4544 287.722 13.4544Z" fill={ color }/>
  <path d="M281.802 13.6818C281.346 13.6818 280.891 13.2257 280.891 12.7697C280.891 12.3136 281.346 11.8576 281.802 11.8576C282.257 11.8576 282.713 12.3136 282.713 12.7697C282.485 13.2257 282.257 13.6818 281.802 13.6818Z" fill={ color }/>
  <path d="M275.65 13.6818C276.153 13.6818 276.561 13.2734 276.561 12.7697C276.561 12.2659 276.153 11.8576 275.65 11.8576C275.146 11.8576 274.738 12.2659 274.738 12.7697C274.738 13.2734 275.146 13.6818 275.65 13.6818Z" fill={ color }/>
  <path d="M269.729 13.9102C270.358 13.9102 270.868 13.3998 270.868 12.7701C270.868 12.1405 270.358 11.63 269.729 11.63C269.1 11.63 268.59 12.1405 268.59 12.7701C268.59 13.3998 269.1 13.9102 269.729 13.9102Z" fill={ color }/>
  <path d="M263.803 14.1378C263.12 14.1378 262.664 13.6818 262.664 12.9977C262.664 12.3136 263.12 11.8576 263.803 11.8576C264.487 11.8576 264.942 12.3136 264.942 12.9977C264.942 13.4538 264.487 14.1378 263.803 14.1378Z" fill={ color }/>
  <path d="M257.656 14.1375C258.411 14.1375 259.023 13.525 259.023 12.7694C259.023 12.0138 258.411 11.4013 257.656 11.4013C256.901 11.4013 256.289 12.0138 256.289 12.7694C256.289 13.525 256.901 14.1375 257.656 14.1375Z" fill={ color }/>
  <path d="M251.73 14.1375C251.047 14.1375 250.363 13.4535 250.363 12.7694C250.363 12.0853 251.047 11.4013 251.73 11.4013C252.414 11.4013 253.097 12.0853 253.097 12.7694C253.097 13.4535 252.414 14.1375 251.73 14.1375Z" fill={ color }/>
  <path d="M245.808 14.1375C245.125 14.1375 244.441 13.4535 244.441 12.7694C244.441 12.0853 245.125 11.4013 245.808 11.4013C246.492 11.4013 247.175 12.0853 247.175 12.7694C247.175 13.6815 246.492 14.1375 245.808 14.1375Z" fill={ color }/>
  <path d="M239.656 14.1375C238.972 14.1375 238.289 13.4535 238.289 12.7694C238.289 12.0853 238.972 11.4013 239.656 11.4013C240.339 11.4013 241.023 12.0853 241.023 12.7694C241.251 13.6815 240.567 14.1375 239.656 14.1375Z" fill={ color }/>
  <path d="M233.734 14.1375C234.489 14.1375 235.101 13.525 235.101 12.7694C235.101 12.0138 234.489 11.4013 233.734 11.4013C232.979 11.4013 232.367 12.0138 232.367 12.7694C232.367 13.525 232.979 14.1375 233.734 14.1375Z" fill={ color }/>
  <path d="M227.812 14.3663C226.901 14.3663 226.445 13.6822 226.445 12.9981C226.445 12.3141 227.129 11.63 227.812 11.63C228.723 11.63 229.179 12.3141 229.179 12.9981C229.179 13.6822 228.496 14.3663 227.812 14.3663Z" fill={ color }/>
  <path d="M221.886 14.1375C222.641 14.1375 223.253 13.525 223.253 12.7694C223.253 12.0138 222.641 11.4013 221.886 11.4013C221.131 11.4013 220.52 12.0138 220.52 12.7694C220.52 13.525 221.131 14.1375 221.886 14.1375Z" fill={ color }/>
  <path d="M215.738 14.3663C214.827 14.3663 214.371 13.6822 214.371 12.9981C214.371 12.3141 215.055 11.63 215.738 11.63C216.649 11.63 217.105 12.3141 217.105 12.9981C217.105 13.6822 216.649 14.3663 215.738 14.3663Z" fill={ color }/>
  <path d="M209.812 14.1375C210.567 14.1375 211.179 13.525 211.179 12.7694C211.179 12.0138 210.567 11.4013 209.812 11.4013C209.057 11.4013 208.445 12.0138 208.445 12.7694C208.445 13.525 209.057 14.1375 209.812 14.1375Z" fill={ color }/>
  <path d="M203.89 14.1375C204.645 14.1375 205.257 13.525 205.257 12.7694C205.257 12.0138 204.645 11.4013 203.89 11.4013C203.135 11.4013 202.523 12.0138 202.523 12.7694C202.523 13.525 203.135 14.1375 203.89 14.1375Z" fill={ color }/>
  <path d="M197.742 14.1375C198.497 14.1375 199.109 13.525 199.109 12.7694C199.109 12.0138 198.497 11.4013 197.742 11.4013C196.987 11.4013 196.375 12.0138 196.375 12.7694C196.375 13.525 196.987 14.1375 197.742 14.1375Z" fill={ color }/>
  <path d="M191.816 14.1375C192.571 14.1375 193.183 13.525 193.183 12.7694C193.183 12.0138 192.571 11.4013 191.816 11.4013C191.061 11.4013 190.449 12.0138 190.449 12.7694C190.449 13.525 191.061 14.1375 191.816 14.1375Z" fill={ color }/>
  <path d="M185.894 14.1375C185.211 14.1375 184.527 13.4535 184.527 12.7694C184.527 12.0853 185.211 11.4013 185.894 11.4013C186.578 11.4013 187.261 12.0853 187.261 12.7694C187.261 13.6815 186.578 14.1375 185.894 14.1375Z" fill={ color }/>
  <path d="M179.742 14.1375C179.058 14.1375 178.375 13.4535 178.375 12.7694C178.375 12.0853 179.058 11.4013 179.742 11.4013C180.425 11.4013 181.109 12.0853 181.109 12.7694C181.109 13.6815 180.653 14.1375 179.742 14.1375Z" fill={ color }/>
  <path d="M173.82 14.1375C173.137 14.1375 172.453 13.4535 172.453 12.7694C172.453 12.0853 173.137 11.4013 173.82 11.4013C174.503 11.4013 175.187 12.0853 175.187 12.7694C175.187 13.4535 174.503 14.1375 173.82 14.1375Z" fill={ color }/>
  <path d="M167.898 14.1375C167.215 14.1375 166.531 13.4535 166.531 12.7694C166.531 12.0853 167.215 11.4013 167.898 11.4013C168.582 11.4013 169.265 12.0853 169.265 12.7694C169.037 13.4535 168.582 14.1375 167.898 14.1375Z" fill={ color }/>
  <path d="M161.975 13.9102C162.604 13.9102 163.114 13.3998 163.114 12.7701C163.114 12.1405 162.604 11.63 161.975 11.63C161.346 11.63 160.836 12.1405 160.836 12.7701C160.836 13.3998 161.346 13.9102 161.975 13.9102Z" fill={ color }/>
  <path d="M155.823 13.9102C155.139 13.9102 154.684 13.4542 154.684 12.7701C154.684 12.0861 155.139 11.63 155.823 11.63C156.506 11.63 156.962 12.0861 156.962 12.7701C156.962 13.4542 156.506 13.9102 155.823 13.9102Z" fill={ color }/>
  <path d="M149.901 13.9102C150.53 13.9102 151.04 13.3998 151.04 12.7701C151.04 12.1405 150.53 11.63 149.901 11.63C149.272 11.63 148.762 12.1405 148.762 12.7701C148.762 13.3998 149.272 13.9102 149.901 13.9102Z" fill={ color }/>
  <path d="M143.975 13.9102C143.292 13.9102 142.836 13.4542 142.836 12.7701C142.836 12.0861 143.292 11.63 143.975 11.63C144.658 11.63 145.114 12.0861 145.114 12.7701C145.114 13.4542 144.431 13.9102 143.975 13.9102Z" fill={ color }/>
  <path d="M137.827 13.9102C137.143 13.9102 136.688 13.4542 136.688 12.7701C136.688 12.0861 137.143 11.63 137.827 11.63C138.51 11.63 138.966 12.0861 138.966 12.7701C138.966 13.4542 138.51 13.9102 137.827 13.9102Z" fill={ color }/>
  <path d="M131.903 13.9104C131.448 13.9104 130.992 13.4544 130.992 12.9983C130.992 12.5423 131.448 12.0862 131.903 12.0862C132.359 12.0862 132.815 12.5423 132.815 12.9983C132.815 13.4544 132.587 13.9104 131.903 13.9104Z" fill={ color }/>
  <path d="M125.982 13.6818C125.526 13.6818 125.07 13.2257 125.07 12.7697C125.07 12.3136 125.526 11.8576 125.982 11.8576C126.437 11.8576 126.893 12.3136 126.893 12.7697C126.893 13.4538 126.437 13.6818 125.982 13.6818Z" fill={ color }/>
  <path d="M119.829 13.6818C120.332 13.6818 120.74 13.2734 120.74 12.7697C120.74 12.2659 120.332 11.8576 119.829 11.8576C119.326 11.8576 118.918 12.2659 118.918 12.7697C118.918 13.2734 119.326 13.6818 119.829 13.6818Z" fill={ color }/>
  <path d="M113.903 13.6818C114.407 13.6818 114.815 13.2734 114.815 12.7697C114.815 12.2659 114.407 11.8576 113.903 11.8576C113.4 11.8576 112.992 12.2659 112.992 12.7697C112.992 13.2734 113.4 13.6818 113.903 13.6818Z" fill={ color }/>
  <path d="M107.98 13.4544C108.358 13.4544 108.664 13.1481 108.664 12.7703C108.664 12.3925 108.358 12.0862 107.98 12.0862C107.603 12.0862 107.297 12.3925 107.297 12.7703C107.297 13.1481 107.603 13.4544 107.98 13.4544Z" fill={ color }/>
  <path d="M102.058 13.4544C101.603 13.4544 101.375 13.2264 101.375 12.7703C101.375 12.3143 101.603 12.0862 102.058 12.0862C102.514 12.0862 102.742 12.3143 102.742 12.7703C102.742 13.2264 102.286 13.4544 102.058 13.4544Z" fill={ color }/>
  <path d="M95.91 13.4544C95.4544 13.4544 95.2266 13.2264 95.2266 12.7703C95.2266 12.3143 95.4544 12.0862 95.91 12.0862C96.3656 12.0862 96.5934 12.3143 96.5934 12.7703C96.5934 13.2264 96.3656 13.4544 95.91 13.4544Z" fill={ color }/>
  <path d="M89.9842 13.4544C89.7564 13.4544 89.3008 13.2264 89.3008 12.7703C89.3008 12.5423 89.5286 12.0862 89.9842 12.0862C90.4398 12.0862 90.6676 12.3143 90.6676 12.7703C90.6676 13.2264 90.212 13.4544 89.9842 13.4544Z" fill={ color }/>
  <path d="M84.0611 13.4536C83.8333 13.4536 83.6055 13.2256 83.6055 12.9976C83.6055 12.7695 83.8333 12.5415 84.0611 12.5415C84.2889 12.5415 84.5167 12.7695 84.5167 12.9976C84.5167 13.2256 84.2889 13.4536 84.0611 13.4536Z" fill={ color }/>
  <path d="M77.9127 13.2259C77.6848 13.2259 77.457 12.9979 77.457 12.7699C77.457 12.5419 77.6848 12.3138 77.9127 12.3138C78.1405 12.3138 78.3683 12.5419 78.3683 12.7699C78.3683 12.9979 78.1405 13.2259 77.9127 13.2259Z" fill={ color }/>
  <path d="M71.9869 13.2259C72.2385 13.2259 72.4425 13.0217 72.4425 12.7699C72.4425 12.518 72.2385 12.3138 71.9869 12.3138C71.7352 12.3138 71.5312 12.518 71.5312 12.7699C71.5312 13.0217 71.7352 13.2259 71.9869 13.2259Z" fill={ color }/>
  <path d="M66.0637 13.2251C65.8359 13.2251 65.8359 12.9971 65.8359 12.9971C65.8359 12.7691 66.0637 12.7691 66.0637 12.7691C66.2915 12.7691 66.2915 12.9971 66.2915 12.9971C66.2915 12.9971 66.2915 13.2251 66.0637 13.2251Z" fill={ color }/>
  <path d="M59.9153 12.9976C60.0411 12.9976 60.1431 12.8955 60.1431 12.7695C60.1431 12.6436 60.0411 12.5415 59.9153 12.5415C59.7895 12.5415 59.6875 12.6436 59.6875 12.7695C59.6875 12.8955 59.7895 12.9976 59.9153 12.9976Z" fill={ color }/>
  <path d="M53.9919 12.9971C53.9919 12.9971 53.7641 12.9971 53.9919 12.9971C53.7641 12.7691 53.9919 12.7691 53.9919 12.7691C54.2197 12.7691 54.2197 12.7691 53.9919 12.9971C54.2197 12.9971 54.2197 12.9971 53.9919 12.9971Z" fill={ color }/>
  <path d="M320.757 18.9263C320.529 18.9263 320.529 18.9263 320.757 18.9263C320.529 18.6982 320.757 18.4702 320.757 18.4702C320.757 18.6982 320.757 18.6982 320.757 18.9263Z" fill={ color }/>
  <path d="M314.608 19.3823C314.38 19.3823 314.152 19.1543 314.152 18.9263C314.152 18.6982 314.38 18.4702 314.608 18.4702C314.836 18.4702 315.064 18.6982 315.064 18.9263C315.064 19.1543 315.064 19.3823 314.608 19.3823Z" fill={ color }/>
  <path d="M308.685 19.6106C308.229 19.6106 307.773 19.1545 307.773 18.6985C307.773 18.2424 308.229 17.7864 308.685 17.7864C309.14 17.7864 309.596 18.2424 309.596 18.6985C309.596 19.3826 309.14 19.6106 308.685 19.6106Z" fill={ color }/>
  <path d="M302.76 19.8379C302.077 19.8379 301.621 19.3818 301.621 18.6977C301.621 18.0137 302.077 17.5576 302.76 17.5576C303.444 17.5576 303.899 18.0137 303.899 18.6977C303.671 19.3818 303.216 19.8379 302.76 19.8379Z" fill={ color }/>
  <path d="M296.612 20.0666C295.928 20.0666 295.473 19.6106 295.473 18.9265C295.473 18.2424 295.928 17.7864 296.612 17.7864C297.295 17.7864 297.751 18.2424 297.751 18.9265C297.979 19.6106 297.295 20.0666 296.612 20.0666Z" fill={ color }/>
  <path d="M290.687 20.2939C290.004 20.2939 289.32 19.6098 289.32 18.9258C289.32 18.2417 290.004 17.5576 290.687 17.5576C291.371 17.5576 292.054 18.2417 292.054 18.9258C292.054 19.6098 291.371 20.2939 290.687 20.2939Z" fill={ color }/>
  <path d="M284.763 20.2936C283.851 20.2936 283.168 19.6096 283.168 18.6975C283.168 17.7854 283.851 17.1013 284.763 17.1013C285.674 17.1013 286.357 17.7854 286.357 18.6975C286.13 19.6096 285.446 20.2936 284.763 20.2936Z" fill={ color }/>
  <path d="M278.614 20.5224C277.703 20.5224 277.02 19.8383 277.02 18.9262C277.02 18.0141 277.703 17.33 278.614 17.33C279.525 17.33 280.209 18.0141 280.209 18.9262C280.209 19.6103 279.525 20.5224 278.614 20.5224Z" fill={ color }/>
  <path d="M272.688 20.5224C271.777 20.5224 271.094 19.8383 271.094 18.9262C271.094 18.0141 271.777 17.33 272.688 17.33C273.6 17.33 274.283 18.0141 274.283 18.9262C274.283 19.8383 273.6 20.5224 272.688 20.5224Z" fill={ color }/>
  <path d="M266.768 20.7497C267.774 20.7497 268.59 19.933 268.59 18.9255C268.59 17.918 267.774 17.1013 266.768 17.1013C265.761 17.1013 264.945 17.918 264.945 18.9255C264.945 19.933 265.761 20.7497 266.768 20.7497Z" fill={ color }/>
  <path d="M260.842 20.7497C261.849 20.7497 262.664 19.933 262.664 18.9255C262.664 17.918 261.849 17.1013 260.842 17.1013C259.835 17.1013 259.02 17.918 259.02 18.9255C259.02 19.933 259.835 20.7497 260.842 20.7497Z" fill={ color }/>
  <path d="M254.694 20.7497C255.7 20.7497 256.516 19.933 256.516 18.9255C256.516 17.918 255.7 17.1013 254.694 17.1013C253.687 17.1013 252.871 17.918 252.871 18.9255C252.871 19.933 253.687 20.7497 254.694 20.7497Z" fill={ color }/>
  <path d="M248.768 20.5231C247.857 20.5231 246.945 19.6111 246.945 18.699C246.945 17.7869 247.857 16.8748 248.768 16.8748C249.679 16.8748 250.59 17.7869 250.59 18.699C250.59 19.6111 249.679 20.5231 248.768 20.5231Z" fill={ color }/>
  <path d="M242.846 20.5231C241.935 20.5231 241.023 19.6111 241.023 18.699C241.023 17.7869 241.935 16.8748 242.846 16.8748C243.757 16.8748 244.668 17.7869 244.668 18.699C244.668 19.6111 243.757 20.5231 242.846 20.5231Z" fill={ color }/>
  <path d="M236.694 20.5231C235.782 20.5231 234.871 19.6111 234.871 18.699C234.871 17.7869 235.782 16.8748 236.694 16.8748C237.605 16.8748 238.516 17.7869 238.516 18.699C238.516 19.6111 237.833 20.5231 236.694 20.5231Z" fill={ color }/>
  <path d="M230.772 20.5231C229.86 20.5231 228.949 19.6111 228.949 18.699C228.949 17.7869 229.86 16.8748 230.772 16.8748C231.683 16.8748 232.594 17.7869 232.594 18.699C232.594 19.6111 231.683 20.5231 230.772 20.5231Z" fill={ color }/>
  <path d="M224.85 20.5231C223.939 20.5231 223.027 19.6111 223.027 18.699C223.027 17.7869 223.939 16.8748 224.85 16.8748C225.761 16.8748 226.672 17.7869 226.672 18.699C226.672 19.6111 225.761 20.5231 224.85 20.5231Z" fill={ color }/>
  <path d="M218.697 20.5231C217.786 20.5231 216.875 19.6111 216.875 18.699C216.875 17.7869 217.786 16.8748 218.697 16.8748C219.609 16.8748 220.52 17.7869 220.52 18.699C220.52 19.6111 219.837 20.5231 218.697 20.5231Z" fill={ color }/>
  <path d="M212.776 20.5231C211.864 20.5231 210.953 19.8391 210.953 18.699C210.953 17.7869 211.637 16.8748 212.776 16.8748C213.687 16.8748 214.598 17.5588 214.598 18.699C214.598 19.8391 213.687 20.5231 212.776 20.5231Z" fill={ color }/>
  <path d="M206.854 20.7497C207.86 20.7497 208.676 19.933 208.676 18.9255C208.676 17.918 207.86 17.1013 206.854 17.1013C205.847 17.1013 205.031 17.918 205.031 18.9255C205.031 19.933 205.847 20.7497 206.854 20.7497Z" fill={ color }/>
  <path d="M200.928 20.5231C200.017 20.5231 199.105 19.8391 199.105 18.699C199.105 17.7869 199.789 16.8748 200.928 16.8748C202.067 16.8748 202.75 17.5588 202.75 18.699C202.523 19.8391 201.839 20.5231 200.928 20.5231Z" fill={ color }/>
  <path d="M194.778 20.5224C193.867 20.5224 193.184 19.8383 193.184 18.9262C193.184 18.0141 193.867 17.33 194.778 17.33C195.69 17.33 196.373 18.0141 196.373 18.9262C196.601 19.8383 195.69 20.5224 194.778 20.5224Z" fill={ color }/>
  <path d="M188.856 20.5224C187.945 20.5224 187.262 19.8383 187.262 18.9262C187.262 18.0141 187.945 17.33 188.856 17.33C189.768 17.33 190.451 18.0141 190.451 18.9262C190.451 19.8383 189.768 20.5224 188.856 20.5224Z" fill={ color }/>
  <path d="M182.935 20.5224C182.023 20.5224 181.34 19.8383 181.34 18.9262C181.34 18.0141 182.023 17.33 182.935 17.33C183.846 17.33 184.529 18.0141 184.529 18.9262C184.529 19.8383 183.846 20.5224 182.935 20.5224Z" fill={ color }/>
  <path d="M176.782 20.5224C175.871 20.5224 175.188 19.8383 175.188 18.9262C175.188 18.0141 175.871 17.33 176.782 17.33C177.693 17.33 178.377 18.0141 178.377 18.9262C178.377 19.6103 177.693 20.5224 176.782 20.5224Z" fill={ color }/>
  <path d="M170.856 20.2936C169.945 20.2936 169.262 19.6096 169.262 18.6975C169.262 17.7854 169.945 17.1013 170.856 17.1013C171.768 17.1013 172.451 17.7854 172.451 18.6975C172.451 19.6096 171.768 20.2936 170.856 20.2936Z" fill={ color }/>
  <path d="M164.935 20.2936C164.023 20.2936 163.34 19.6096 163.34 18.6975C163.34 17.7854 164.023 17.1013 164.935 17.1013C165.846 17.1013 166.529 17.7854 166.529 18.6975C166.301 19.6096 165.618 20.2936 164.935 20.2936Z" fill={ color }/>
  <path d="M158.785 20.2939C157.874 20.2939 157.418 19.6098 157.418 18.9258C157.418 18.0137 158.101 17.5576 158.785 17.5576C159.696 17.5576 160.152 18.2417 160.152 18.9258C160.379 19.6098 159.696 20.2939 158.785 20.2939Z" fill={ color }/>
  <path d="M152.863 20.2939C152.18 20.2939 151.496 19.6098 151.496 18.9258C151.496 18.2417 152.18 17.5576 152.863 17.5576C153.546 17.5576 154.23 18.2417 154.23 18.9258C154.23 19.6098 153.774 20.2939 152.863 20.2939Z" fill={ color }/>
  <path d="M146.937 20.2939C146.254 20.2939 145.57 19.6098 145.57 18.9258C145.57 18.2417 146.254 17.5576 146.937 17.5576C147.621 17.5576 148.304 18.2417 148.304 18.9258C148.304 19.6098 147.621 20.2939 146.937 20.2939Z" fill={ color }/>
  <path d="M141.015 20.2939C141.77 20.2939 142.382 19.6814 142.382 18.9258C142.382 18.1702 141.77 17.5576 141.015 17.5576C140.26 17.5576 139.648 18.1702 139.648 18.9258C139.648 19.6814 140.26 20.2939 141.015 20.2939Z" fill={ color }/>
  <path d="M134.863 20.0663C134.18 20.0663 133.496 19.6103 133.496 18.6982C133.496 17.7861 133.952 17.33 134.863 17.33C135.546 17.33 136.23 17.7861 136.23 18.6982C136.23 19.6103 135.546 20.0663 134.863 20.0663Z" fill={ color }/>
  <path d="M128.94 20.0665C128.256 20.0665 127.801 19.6105 127.801 18.9264C127.801 18.2423 128.256 17.7863 128.94 17.7863C129.623 17.7863 130.079 18.2423 130.079 18.9264C130.079 19.6105 129.623 20.0665 128.94 20.0665Z" fill={ color }/>
  <path d="M123.018 20.0665C122.335 20.0665 121.879 19.6105 121.879 18.9264C121.879 18.2423 122.335 17.7863 123.018 17.7863C123.701 17.7863 124.157 18.2423 124.157 18.9264C124.157 19.6105 123.474 20.0665 123.018 20.0665Z" fill={ color }/>
  <path d="M116.866 19.8379C116.182 19.8379 115.727 19.3818 115.727 18.6977C115.727 18.0137 116.182 17.5576 116.866 17.5576C117.549 17.5576 118.005 18.0137 118.005 18.6977C118.005 19.3818 117.549 19.8379 116.866 19.8379Z" fill={ color }/>
  <path d="M110.942 19.8392C110.487 19.8392 110.031 19.3832 110.031 18.9271C110.031 18.4711 110.487 18.015 110.942 18.015C111.398 18.015 111.854 18.4711 111.854 18.9271C111.854 19.3832 111.626 19.8392 110.942 19.8392Z" fill={ color }/>
  <path d="M105.021 19.8392C104.565 19.8392 104.109 19.3832 104.109 18.9271C104.109 18.4711 104.565 18.015 105.021 18.015C105.476 18.015 105.932 18.4711 105.932 18.9271C105.932 19.3832 105.476 19.8392 105.021 19.8392Z" fill={ color }/>
  <path d="M98.8683 19.8392C99.3716 19.8392 99.7795 19.4309 99.7795 18.9271C99.7795 18.4234 99.3716 18.015 98.8683 18.015C98.365 18.015 97.957 18.4234 97.957 18.9271C97.957 19.4309 98.365 19.8392 98.8683 19.8392Z" fill={ color }/>
  <path d="M92.9464 19.6105C92.4908 19.6105 92.0352 19.1544 92.0352 18.6984C92.0352 18.2423 92.4908 17.7863 92.9464 17.7863C93.402 17.7863 93.8576 18.2423 93.8576 18.6984C93.8576 19.3824 93.402 19.6105 92.9464 19.6105Z" fill={ color }/>
  <path d="M87.0233 19.6097C86.5677 19.6097 86.3398 19.3817 86.3398 18.9256C86.3398 18.4696 86.5677 18.2416 87.0233 18.2416C87.4789 18.2416 87.7067 18.4696 87.7067 18.9256C87.7067 19.1537 87.4789 19.6097 87.0233 19.6097Z" fill={ color }/>
  <path d="M81.1014 19.6097C80.6458 19.6097 80.418 19.3817 80.418 18.9256C80.418 18.4696 80.6458 18.2416 81.1014 18.2416C81.557 18.2416 81.7848 18.4696 81.7848 18.9256C81.7848 19.1537 81.3292 19.6097 81.1014 19.6097Z" fill={ color }/>
  <path d="M74.9491 19.3832C74.7212 19.3832 74.2656 19.1552 74.2656 18.6991C74.2656 18.4711 74.4934 18.015 74.9491 18.015C75.1769 18.015 75.6325 18.2431 75.6325 18.6991C75.6325 19.1552 75.4047 19.3832 74.9491 19.3832Z" fill={ color }/>
  <path d="M69.0259 19.3823C68.7981 19.3823 68.5703 19.1543 68.5703 18.9263C68.5703 18.6982 68.7981 18.4702 69.0259 18.4702C69.2538 18.4702 69.4816 18.6982 69.4816 18.9263C69.4816 19.1543 69.2538 19.3823 69.0259 19.3823Z" fill={ color }/>
  <path d="M63.1041 19.3823C62.8763 19.3823 62.6484 19.1543 62.6484 18.9263C62.6484 18.6982 62.8763 18.4702 63.1041 18.4702C63.3319 18.4702 63.5597 18.6982 63.5597 18.9263C63.5597 19.1543 63.3319 19.3823 63.1041 19.3823Z" fill={ color }/>
  <path d="M56.9556 19.1537C56.7278 19.1537 56.5 18.9256 56.5 18.6976C56.5 18.4696 56.7278 18.2416 56.9556 18.2416C57.1834 18.2416 57.4112 18.4696 57.4112 18.6976C57.4112 18.9256 57.1834 19.1537 56.9556 19.1537Z" fill={ color }/>
  <path d="M51.0286 19.1538C51.1544 19.1538 51.2564 19.0518 51.2564 18.9258C51.2564 18.7999 51.1544 18.6978 51.0286 18.6978C50.9028 18.6978 50.8008 18.7999 50.8008 18.9258C50.8008 19.0518 50.9028 19.1538 51.0286 19.1538Z" fill={ color }/>
  <path d="M45.1084 18.9263C44.8805 18.9263 44.8805 18.9263 45.1084 18.9263C44.8805 18.6982 44.8805 18.4702 45.1084 18.4702C45.1084 18.6982 45.3362 18.6982 45.1084 18.9263C45.3362 18.9263 45.1084 18.9263 45.1084 18.9263Z" fill={ color }/>
  <path d="M38.9567 18.9258C38.7289 18.6978 38.9567 18.6978 38.9567 18.6978C39.1845 18.6978 39.1845 18.6978 38.9567 18.9258C39.1845 18.9258 39.1845 18.9258 38.9567 18.9258Z" fill={ color }/>
  <path d="M329.642 25.0826C329.768 25.0826 329.87 24.9805 329.87 24.8546C329.87 24.7287 329.768 24.6266 329.642 24.6266C329.516 24.6266 329.414 24.7287 329.414 24.8546C329.414 24.9805 329.516 25.0826 329.642 25.0826Z" fill={ color }/>
  <path d="M323.719 25.5385C323.263 25.5385 323.035 25.3105 323.035 24.8544C323.035 24.3984 323.263 24.1704 323.719 24.1704C324.174 24.1704 324.402 24.3984 324.402 24.8544C324.402 25.3105 323.946 25.5385 323.719 25.5385Z" fill={ color }/>
  <path d="M317.567 25.7669C317.112 25.7669 316.656 25.3108 316.656 24.8548C316.656 24.3987 317.112 23.9427 317.567 23.9427C318.023 23.9427 318.479 24.3987 318.479 24.8548C318.479 25.3108 318.251 25.7669 317.567 25.7669Z" fill={ color }/>
  <path d="M311.644 26.2227C312.399 26.2227 313.011 25.6102 313.011 24.8546C313.011 24.099 312.399 23.4864 311.644 23.4864C310.889 23.4864 310.277 24.099 310.277 24.8546C310.277 25.6102 310.889 26.2227 311.644 26.2227Z" fill={ color }/>
  <path d="M305.724 26.4512C304.812 26.4512 304.129 25.7671 304.129 24.855C304.129 23.9429 304.812 23.2589 305.724 23.2589C306.635 23.2589 307.318 23.9429 307.318 24.855C307.09 25.7671 306.407 26.4512 305.724 26.4512Z" fill={ color }/>
  <path d="M299.571 26.4512C298.66 26.4512 297.977 25.7671 297.977 24.855C297.977 23.9429 298.66 23.2589 299.571 23.2589C300.482 23.2589 301.166 23.9429 301.166 24.855C301.394 25.7671 300.71 26.4512 299.571 26.4512Z" fill={ color }/>
  <path d="M293.651 26.6785C292.739 26.6785 291.828 25.7664 291.828 24.8543C291.828 23.9422 292.739 23.0301 293.651 23.0301C294.562 23.0301 295.473 23.9422 295.473 24.8543C295.473 25.7664 294.79 26.6785 293.651 26.6785Z" fill={ color }/>
  <path d="M287.725 26.6785C286.586 26.6785 285.902 25.7664 285.902 24.8543C285.902 23.9422 286.814 23.0301 287.725 23.0301C288.864 23.0301 289.547 23.9422 289.547 24.8543C289.547 25.7664 288.864 26.6785 287.725 26.6785Z" fill={ color }/>
  <path d="M281.8 26.9069C280.661 26.9069 279.75 25.9948 279.75 24.8547C279.75 23.7146 280.661 22.8025 281.8 22.8025C282.939 22.8025 283.851 23.7146 283.851 24.8547C283.851 25.9948 282.712 26.9069 281.8 26.9069Z" fill={ color }/>
  <path d="M275.652 26.9069C274.513 26.9069 273.602 25.9948 273.602 24.8547C273.602 23.7146 274.513 22.8025 275.652 22.8025C276.791 22.8025 277.702 23.7146 277.702 24.8547C277.702 25.9948 276.791 26.9069 275.652 26.9069Z" fill={ color }/>
  <path d="M269.726 26.9069C270.858 26.9069 271.776 25.9881 271.776 24.8547C271.776 23.7213 270.858 22.8025 269.726 22.8025C268.594 22.8025 267.676 23.7213 267.676 24.8547C267.676 25.9881 268.594 26.9069 269.726 26.9069Z" fill={ color }/>
  <path d="M263.804 26.9069C262.665 26.9069 261.754 25.9948 261.754 24.8547C261.754 23.7146 262.665 22.8025 263.804 22.8025C264.943 22.8025 265.855 23.7146 265.855 24.8547C265.855 25.9948 264.943 26.9069 263.804 26.9069Z" fill={ color }/>
  <path d="M257.656 26.9069C258.788 26.9069 259.706 25.9881 259.706 24.8547C259.706 23.7213 258.788 22.8025 257.656 22.8025C256.523 22.8025 255.605 23.7213 255.605 24.8547C255.605 25.9881 256.523 26.9069 257.656 26.9069Z" fill={ color }/>
  <path d="M251.73 26.9069C250.591 26.9069 249.68 25.9948 249.68 24.8547C249.68 23.7146 250.591 22.8025 251.73 22.8025C252.869 22.8025 253.78 23.7146 253.78 24.8547C253.78 25.9948 252.869 26.9069 251.73 26.9069Z" fill={ color }/>
  <path d="M245.808 26.9069C246.94 26.9069 247.858 25.9881 247.858 24.8547C247.858 23.7213 246.94 22.8025 245.808 22.8025C244.676 22.8025 243.758 23.7213 243.758 24.8547C243.758 25.9881 244.676 26.9069 245.808 26.9069Z" fill={ color }/>
  <path d="M239.66 26.9069C238.521 26.9069 237.609 25.9948 237.609 24.8547C237.609 23.7146 238.521 22.8025 239.66 22.8025C240.799 22.8025 241.71 23.7146 241.71 24.8547C241.938 25.9948 241.027 26.9069 239.66 26.9069Z" fill={ color }/>
  <path d="M233.734 26.9069C232.595 26.9069 231.684 25.9948 231.684 24.8547C231.684 23.7146 232.595 22.8025 233.734 22.8025C234.873 22.8025 235.784 23.7146 235.784 24.8547C235.784 25.9948 234.873 26.9069 233.734 26.9069Z" fill={ color }/>
  <path d="M227.812 26.9069C226.673 26.9069 225.762 25.9948 225.762 24.8547C225.762 23.7146 226.673 22.8025 227.812 22.8025C228.951 22.8025 229.862 23.7146 229.862 24.8547C229.862 25.9948 228.951 26.9069 227.812 26.9069Z" fill={ color }/>
  <path d="M221.886 26.9069C223.019 26.9069 223.937 25.9881 223.937 24.8547C223.937 23.7213 223.019 22.8025 221.886 22.8025C220.754 22.8025 219.836 23.7213 219.836 24.8547C219.836 25.9881 220.754 26.9069 221.886 26.9069Z" fill={ color }/>
  <path d="M215.738 26.9069C214.599 26.9069 213.688 25.9948 213.688 24.8547C213.688 23.7146 214.599 22.8025 215.738 22.8025C216.877 22.8025 217.788 23.7146 217.788 24.8547C217.788 25.9948 216.877 26.9069 215.738 26.9069Z" fill={ color }/>
  <path d="M209.812 26.9069C208.673 26.9069 207.762 25.9948 207.762 24.8547C207.762 23.7146 208.673 22.8025 209.812 22.8025C210.951 22.8025 211.862 23.7146 211.862 24.8547C211.862 25.9948 210.951 26.9069 209.812 26.9069Z" fill={ color }/>
  <path d="M203.89 26.9069C202.751 26.9069 201.84 25.9948 201.84 24.8547C201.84 23.7146 202.751 22.8025 203.89 22.8025C205.029 22.8025 205.94 23.7146 205.94 24.8547C205.713 25.9948 205.029 26.9069 203.89 26.9069Z" fill={ color }/>
  <path d="M197.742 26.6793C196.603 26.6793 195.691 25.7672 195.691 24.6271C195.691 23.487 196.603 22.5749 197.742 22.5749C198.881 22.5749 199.792 23.487 199.792 24.6271C199.792 25.7672 198.881 26.6793 197.742 26.6793Z" fill={ color }/>
  <path d="M191.816 26.9069C192.948 26.9069 193.866 25.9881 193.866 24.8547C193.866 23.7213 192.948 22.8025 191.816 22.8025C190.684 22.8025 189.766 23.7213 189.766 24.8547C189.766 25.9881 190.684 26.9069 191.816 26.9069Z" fill={ color }/>
  <path d="M185.893 26.6785C186.899 26.6785 187.715 25.8617 187.715 24.8543C187.715 23.8468 186.899 23.0301 185.893 23.0301C184.886 23.0301 184.07 23.8468 184.07 24.8543C184.07 25.8617 184.886 26.6785 185.893 26.6785Z" fill={ color }/>
  <path d="M179.74 26.6785C178.601 26.6785 177.918 25.7664 177.918 24.8543C177.918 23.9422 178.829 23.0301 179.74 23.0301C180.88 23.0301 181.563 23.9422 181.563 24.8543C181.563 25.7664 180.88 26.6785 179.74 26.6785Z" fill={ color }/>
  <path d="M173.819 26.6785C172.907 26.6785 171.996 25.7664 171.996 24.8543C171.996 23.9422 172.907 23.0301 173.819 23.0301C174.73 23.0301 175.641 23.9422 175.641 24.8543C175.641 25.7664 174.958 26.6785 173.819 26.6785Z" fill={ color }/>
  <path d="M167.897 26.6785C166.985 26.6785 166.074 25.9944 166.074 24.8543C166.074 23.9422 166.758 23.0301 167.897 23.0301C168.808 23.0301 169.719 23.7142 169.719 24.8543C169.719 25.7664 168.808 26.6785 167.897 26.6785Z" fill={ color }/>
  <path d="M161.975 26.4509C161.064 26.4509 160.152 25.7668 160.152 24.6267C160.152 23.4866 160.836 22.8025 161.975 22.8025C162.886 22.8025 163.797 23.4866 163.797 24.6267C163.797 25.7668 162.886 26.4509 161.975 26.4509Z" fill={ color }/>
  <path d="M155.821 26.4511C156.702 26.4511 157.416 25.7364 157.416 24.8549C157.416 23.9734 156.702 23.2587 155.821 23.2587C154.941 23.2587 154.227 23.9734 154.227 24.8549C154.227 25.7364 154.941 26.4511 155.821 26.4511Z" fill={ color }/>
  <path d="M149.899 26.4511C148.988 26.4511 148.305 25.767 148.305 24.8549C148.305 23.9428 148.988 23.2587 149.899 23.2587C150.811 23.2587 151.494 23.9428 151.494 24.8549C151.494 25.767 150.811 26.4511 149.899 26.4511Z" fill={ color }/>
  <path d="M143.977 26.4511C143.066 26.4511 142.383 25.767 142.383 24.8549C142.383 23.9428 143.066 23.2587 143.977 23.2587C144.889 23.2587 145.572 23.9428 145.572 24.8549C145.572 25.767 144.661 26.4511 143.977 26.4511Z" fill={ color }/>
  <path d="M137.825 26.4511C138.706 26.4511 139.42 25.7364 139.42 24.8549C139.42 23.9734 138.706 23.2587 137.825 23.2587C136.944 23.2587 136.23 23.9734 136.23 24.8549C136.23 25.7364 136.944 26.4511 137.825 26.4511Z" fill={ color }/>
  <path d="M131.902 26.2227C130.991 26.2227 130.535 25.5386 130.535 24.8546C130.535 23.9425 131.219 23.4864 131.902 23.4864C132.813 23.4864 133.269 24.1705 133.269 24.8546C133.497 25.5386 132.813 26.2227 131.902 26.2227Z" fill={ color }/>
  <path d="M125.98 26.2227C125.297 26.2227 124.613 25.5386 124.613 24.8546C124.613 24.1705 125.297 23.4864 125.98 23.4864C126.664 23.4864 127.347 24.1705 127.347 24.8546C127.347 25.5386 126.664 26.2227 125.98 26.2227Z" fill={ color }/>
  <path d="M119.828 26.2227C119.144 26.2227 118.461 25.5386 118.461 24.8546C118.461 24.1705 119.144 23.4864 119.828 23.4864C120.511 23.4864 121.195 24.1705 121.195 24.8546C121.195 25.5386 120.739 26.2227 119.828 26.2227Z" fill={ color }/>
  <path d="M113.906 26.2227C114.661 26.2227 115.273 25.6102 115.273 24.8546C115.273 24.099 114.661 23.4864 113.906 23.4864C113.151 23.4864 112.539 24.099 112.539 24.8546C112.539 25.6102 113.151 26.2227 113.906 26.2227Z" fill={ color }/>
  <path d="M107.983 25.9953C108.612 25.9953 109.122 25.4849 109.122 24.8552C109.122 24.2255 108.612 23.7151 107.983 23.7151C107.354 23.7151 106.844 24.2255 106.844 24.8552C106.844 25.4849 107.354 25.9953 107.983 25.9953Z" fill={ color }/>
  <path d="M102.061 25.9953C101.377 25.9953 100.922 25.5393 100.922 24.8552C100.922 24.1711 101.377 23.7151 102.061 23.7151C102.744 23.7151 103.2 24.1711 103.2 24.8552C103.2 25.5393 102.517 25.9953 102.061 25.9953Z" fill={ color }/>
  <path d="M95.9086 25.9953C96.5377 25.9953 97.0476 25.4849 97.0476 24.8552C97.0476 24.2255 96.5377 23.7151 95.9086 23.7151C95.2795 23.7151 94.7695 24.2255 94.7695 24.8552C94.7695 25.4849 95.2795 25.9953 95.9086 25.9953Z" fill={ color }/>
  <path d="M89.9855 25.7669C90.4887 25.7669 90.8967 25.3585 90.8967 24.8548C90.8967 24.351 90.4887 23.9427 89.9855 23.9427C89.4822 23.9427 89.0742 24.351 89.0742 24.8548C89.0742 25.3585 89.4822 25.7669 89.9855 25.7669Z" fill={ color }/>
  <path d="M84.0636 25.7669C83.608 25.7669 83.1523 25.3108 83.1523 24.8548C83.1523 24.3987 83.608 23.9427 84.0636 23.9427C84.5192 23.9427 84.9748 24.3987 84.9748 24.8548C84.9748 25.3108 84.5192 25.7669 84.0636 25.7669Z" fill={ color }/>
  <path d="M77.9112 25.7669C77.4556 25.7669 77 25.3108 77 24.8548C77 24.3987 77.4556 23.9427 77.9112 23.9427C78.3669 23.9427 78.8225 24.3987 78.8225 24.8548C78.8225 25.3108 78.3669 25.7669 77.9112 25.7669Z" fill={ color }/>
  <path d="M71.9894 25.5393C71.5337 25.5393 71.0781 25.0832 71.0781 24.6272C71.0781 24.1711 71.5337 23.7151 71.9894 23.7151C72.445 23.7151 72.6728 24.1711 72.6728 24.6272C72.6728 25.0832 72.445 25.5393 71.9894 25.5393Z" fill={ color }/>
  <path d="M66.0662 25.5384C65.6106 25.5384 65.3828 25.3104 65.3828 24.8543C65.3828 24.3983 65.6106 24.1703 66.0662 24.1703C66.5219 24.1703 66.7497 24.3983 66.7497 24.8543C66.7497 25.3104 66.5219 25.5384 66.0662 25.5384Z" fill={ color }/>
  <path d="M59.9139 25.5384C59.4583 25.5384 59.2305 25.3104 59.2305 24.8543C59.2305 24.3983 59.4583 24.1703 59.9139 24.1703C60.3695 24.1703 60.5973 24.3983 60.5973 24.8543C60.5973 25.0824 60.3695 25.5384 59.9139 25.5384Z" fill={ color }/>
  <path d="M53.9908 25.3111C53.763 25.3111 53.5352 25.0831 53.5352 24.855C53.5352 24.627 53.763 24.399 53.9908 24.399C54.2186 24.399 54.4464 24.627 54.4464 24.855C54.4464 25.0831 54.4464 25.3111 53.9908 25.3111Z" fill={ color }/>
  <path d="M48.0689 25.3111C48.3205 25.3111 48.5245 25.1069 48.5245 24.855C48.5245 24.6032 48.3205 24.399 48.0689 24.399C47.8173 24.399 47.6133 24.6032 47.6133 24.855C47.6133 25.1069 47.8173 25.3111 48.0689 25.3111Z" fill={ color }/>
  <path d="M42.147 25.3111C41.9192 25.3111 41.6914 25.0831 41.6914 24.855C41.6914 24.627 41.9192 24.399 42.147 24.399C42.3748 24.399 42.6027 24.627 42.6027 24.855C42.3748 25.0831 42.3748 25.3111 42.147 25.3111Z" fill={ color }/>
  <path d="M35.9934 25.0826C35.7656 25.0826 35.7656 24.8546 35.7656 24.8546L35.9934 24.6266L36.2212 24.8546C36.2212 24.8546 36.2212 25.0826 35.9934 25.0826Z" fill={ color }/>
  <path d="M30.0732 25.0826C29.8454 25.0826 29.8454 24.8546 30.0732 25.0826C29.8454 24.6266 29.8454 24.6266 30.0732 24.6266C30.301 24.6266 30.301 24.6266 30.0732 25.0826C30.301 24.8546 30.301 25.0826 30.0732 25.0826Z" fill={ color }/>
  <path d="M24.1474 24.8546C23.9196 24.8546 23.9196 24.8546 24.1474 24.8546C23.9196 24.6266 24.1474 24.6266 24.1474 24.6266V24.8546Z" fill={ color }/>
  <path d="M338.527 31.4672C338.299 31.4672 337.844 31.2392 337.844 30.7831C337.844 30.5551 338.072 30.0991 338.527 30.0991C338.755 30.0991 339.211 30.3271 339.211 30.7831C339.211 31.2392 338.983 31.4672 338.527 31.4672Z" fill={ color }/>
  <path d="M332.604 31.923C331.92 31.923 331.465 31.467 331.465 30.7829C331.465 30.0989 331.92 29.6428 332.604 29.6428C333.287 29.6428 333.743 30.0989 333.743 30.7829C333.743 31.467 333.287 31.923 332.604 31.923Z" fill={ color }/>
  <path d="M326.679 32.3791C325.768 32.3791 325.312 31.695 325.312 31.011C325.312 30.0989 325.996 29.6428 326.679 29.6428C327.591 29.6428 328.046 30.3269 328.046 31.011C328.046 31.695 327.363 32.3791 326.679 32.3791Z" fill={ color }/>
  <path d="M320.756 32.6073C319.845 32.6073 318.934 31.9232 318.934 30.7831C318.934 29.871 319.617 28.9589 320.756 28.9589C321.667 28.9589 322.579 29.643 322.579 30.7831C322.351 31.6952 321.667 32.6073 320.756 32.6073Z" fill={ color }/>
  <path d="M314.605 32.8357C313.466 32.8357 312.555 31.9236 312.555 30.7835C312.555 29.6434 313.466 28.7313 314.605 28.7313C315.744 28.7313 316.655 29.6434 316.655 30.7835C316.655 31.9236 315.744 32.8357 314.605 32.8357Z" fill={ color }/>
  <path d="M308.683 32.8357C307.544 32.8357 306.633 31.9236 306.633 30.7835C306.633 29.6434 307.544 28.7313 308.683 28.7313C309.822 28.7313 310.733 29.6434 310.733 30.7835C310.733 31.9236 309.822 32.8357 308.683 32.8357Z" fill={ color }/>
  <path d="M302.762 33.063C301.623 33.063 300.484 32.1509 300.484 30.7828C300.484 29.6427 301.396 28.5026 302.762 28.5026C303.902 28.5026 305.041 29.4146 305.041 30.7828C304.813 31.9229 303.902 33.063 302.762 33.063Z" fill={ color }/>
  <path d="M296.61 33.063C295.243 33.063 294.332 32.1509 294.332 30.7828C294.332 29.4146 295.243 28.5026 296.61 28.5026C297.977 28.5026 298.888 29.4146 298.888 30.7828C298.888 32.1509 297.977 33.063 296.61 33.063Z" fill={ color }/>
  <path d="M290.684 33.063C291.943 33.063 292.962 32.0421 292.962 30.7828C292.962 29.5235 291.943 28.5026 290.684 28.5026C289.426 28.5026 288.406 29.5235 288.406 30.7828C288.406 32.0421 289.426 33.063 290.684 33.063Z" fill={ color }/>
  <path d="M284.762 33.063C286.021 33.063 287.041 32.0421 287.041 30.7828C287.041 29.5235 286.021 28.5026 284.762 28.5026C283.504 28.5026 282.484 29.5235 282.484 30.7828C282.484 32.0421 283.504 33.063 284.762 33.063Z" fill={ color }/>
  <path d="M278.611 33.2915C277.245 33.2915 276.105 32.1514 276.105 30.7832C276.105 29.4151 277.245 28.275 278.611 28.275C279.978 28.275 281.117 29.4151 281.117 30.7832C281.117 32.1514 279.978 33.2915 278.611 33.2915Z" fill={ color }/>
  <path d="M272.69 33.2915C274.073 33.2915 275.195 32.1685 275.195 30.7832C275.195 29.398 274.073 28.275 272.69 28.275C271.306 28.275 270.184 29.398 270.184 30.7832C270.184 32.1685 271.306 33.2915 272.69 33.2915Z" fill={ color }/>
  <path d="M266.768 33.2915C268.152 33.2915 269.274 32.1685 269.274 30.7832C269.274 29.398 268.152 28.275 266.768 28.275C265.384 28.275 264.262 29.398 264.262 30.7832C264.262 32.1685 265.384 33.2915 266.768 33.2915Z" fill={ color }/>
  <path d="M260.842 33.2915C262.226 33.2915 263.348 32.1685 263.348 30.7832C263.348 29.398 262.226 28.275 260.842 28.275C259.458 28.275 258.336 29.398 258.336 30.7832C258.336 32.1685 259.458 33.2915 260.842 33.2915Z" fill={ color }/>
  <path d="M254.693 33.2915C253.327 33.2915 252.188 32.1514 252.188 30.7832C252.188 29.4151 253.327 28.275 254.693 28.275C256.06 28.275 257.199 29.4151 257.199 30.7832C257.199 32.1514 256.06 33.2915 254.693 33.2915Z" fill={ color }/>
  <path d="M248.77 33.2918C247.403 33.2918 246.492 32.1517 246.492 31.0115C246.492 29.6434 247.631 28.7313 248.77 28.7313C250.137 28.7313 251.048 29.8714 251.048 31.0115C251.048 32.1517 250.137 33.2918 248.77 33.2918Z" fill={ color }/>
  <path d="M242.848 33.063C244.107 33.063 245.127 32.0421 245.127 30.7828C245.127 29.5235 244.107 28.5026 242.848 28.5026C241.59 28.5026 240.57 29.5235 240.57 30.7828C240.57 32.0421 241.59 33.063 242.848 33.063Z" fill={ color }/>
  <path d="M236.696 33.063C235.329 33.063 234.418 31.9229 234.418 30.7828C234.418 29.4146 235.557 28.5026 236.696 28.5026C237.835 28.5026 238.974 29.6427 238.974 30.7828C239.202 32.1509 238.063 33.063 236.696 33.063Z" fill={ color }/>
  <path d="M230.774 33.063C232.032 33.063 233.052 32.0421 233.052 30.7828C233.052 29.5235 232.032 28.5026 230.774 28.5026C229.516 28.5026 228.496 29.5235 228.496 30.7828C228.496 32.0421 229.516 33.063 230.774 33.063Z" fill={ color }/>
  <path d="M224.848 33.063C223.482 33.063 222.57 31.9229 222.57 30.7828C222.57 29.4146 223.709 28.5026 224.848 28.5026C226.215 28.5026 227.127 29.6427 227.127 30.7828C227.127 32.1509 225.987 33.063 224.848 33.063Z" fill={ color }/>
  <path d="M218.7 33.063C217.561 33.063 216.422 32.1509 216.422 30.7828C216.422 29.6427 217.333 28.5026 218.7 28.5026C219.839 28.5026 220.978 29.4146 220.978 30.7828C220.978 32.1509 220.067 33.063 218.7 33.063Z" fill={ color }/>
  <path d="M212.774 33.063C211.635 33.063 210.496 32.1509 210.496 30.7828C210.496 29.6427 211.407 28.5026 212.774 28.5026C213.913 28.5026 215.052 29.4146 215.052 30.7828C215.052 32.1509 214.141 33.063 212.774 33.063Z" fill={ color }/>
  <path d="M206.852 33.063C205.713 33.063 204.574 32.1509 204.574 30.7828C204.574 29.6427 205.485 28.5026 206.852 28.5026C207.991 28.5026 209.13 29.4146 209.13 30.7828C208.903 31.9229 207.991 33.063 206.852 33.063Z" fill={ color }/>
  <path d="M200.93 33.063C199.791 33.063 198.652 32.1509 198.652 30.7828C198.652 29.6427 199.564 28.5026 200.93 28.5026C202.07 28.5026 203.209 29.4146 203.209 30.7828C202.981 31.9229 202.07 33.063 200.93 33.063Z" fill={ color }/>
  <path d="M194.777 32.8357C193.638 32.8357 192.727 31.9236 192.727 30.7835C192.727 29.6434 193.638 28.7313 194.777 28.7313C195.916 28.7313 196.827 29.6434 196.827 30.7835C197.055 31.9236 195.916 32.8357 194.777 32.8357Z" fill={ color }/>
  <path d="M188.855 32.8357C187.716 32.8357 186.805 31.9236 186.805 30.7835C186.805 29.6434 187.716 28.7313 188.855 28.7313C189.994 28.7313 190.905 29.6434 190.905 30.7835C190.905 31.9236 189.994 32.8357 188.855 32.8357Z" fill={ color }/>
  <path d="M182.933 32.8357C181.794 32.8357 180.883 31.9236 180.883 30.7835C180.883 29.6434 181.794 28.7313 182.933 28.7313C184.072 28.7313 184.983 29.6434 184.983 30.7835C184.983 31.9236 184.072 32.8357 182.933 32.8357Z" fill={ color }/>
  <path d="M176.781 32.8357C175.642 32.8357 174.73 31.9236 174.73 30.7835C174.73 29.6434 175.642 28.7313 176.781 28.7313C177.92 28.7313 178.831 29.6434 178.831 30.7835C178.831 31.9236 177.92 32.8357 176.781 32.8357Z" fill={ color }/>
  <path d="M170.859 32.8357C169.72 32.8357 168.809 31.9236 168.809 30.7835C168.809 29.6434 169.72 28.7313 170.859 28.7313C171.998 28.7313 172.909 29.6434 172.909 30.7835C172.909 31.9236 171.998 32.8357 170.859 32.8357Z" fill={ color }/>
  <path d="M164.936 32.6073C165.942 32.6073 166.758 31.7905 166.758 30.7831C166.758 29.7756 165.942 28.9589 164.936 28.9589C163.929 28.9589 163.113 29.7756 163.113 30.7831C163.113 31.7905 163.929 32.6073 164.936 32.6073Z" fill={ color }/>
  <path d="M158.783 32.6073C157.872 32.6073 156.961 31.6952 156.961 30.7831C156.961 29.871 157.872 28.9589 158.783 28.9589C159.695 28.9589 160.606 29.871 160.606 30.7831C160.834 31.9232 159.922 32.6073 158.783 32.6073Z" fill={ color }/>
  <path d="M152.862 32.6073C151.95 32.6073 151.039 31.6952 151.039 30.7831C151.039 29.871 151.95 28.9589 152.862 28.9589C153.773 28.9589 154.684 29.871 154.684 30.7831C154.684 31.6952 153.773 32.6073 152.862 32.6073Z" fill={ color }/>
  <path d="M146.94 32.6073C146.028 32.6073 145.117 31.9232 145.117 30.7831C145.117 29.871 145.801 28.9589 146.94 28.9589C147.851 28.9589 148.762 29.643 148.762 30.7831C148.534 31.6952 147.851 32.6073 146.94 32.6073Z" fill={ color }/>
  <path d="M141.017 32.3799C141.897 32.3799 142.611 31.6652 142.611 30.7837C142.611 29.9022 141.897 29.1875 141.017 29.1875C140.136 29.1875 139.422 29.9022 139.422 30.7837C139.422 31.6652 140.136 32.3799 141.017 32.3799Z" fill={ color }/>
  <path d="M134.864 32.3799C133.953 32.3799 133.27 31.6958 133.27 30.7837C133.27 29.8716 133.953 29.1875 134.864 29.1875C135.775 29.1875 136.459 29.8716 136.459 30.7837C136.459 31.6958 135.775 32.3799 134.864 32.3799Z" fill={ color }/>
  <path d="M128.942 32.3799C128.031 32.3799 127.348 31.6958 127.348 30.7837C127.348 29.8716 128.031 29.1875 128.942 29.1875C129.854 29.1875 130.537 29.8716 130.537 30.7837C130.537 31.6958 129.854 32.3799 128.942 32.3799Z" fill={ color }/>
  <path d="M123.02 32.3799C122.109 32.3799 121.426 31.6958 121.426 30.7837C121.426 29.8716 122.109 29.1875 123.02 29.1875C123.932 29.1875 124.615 29.8716 124.615 30.7837C124.615 31.6958 123.704 32.3799 123.02 32.3799Z" fill={ color }/>
  <path d="M116.867 32.1514C117.622 32.1514 118.234 31.5389 118.234 30.7833C118.234 30.0277 117.622 29.4151 116.867 29.4151C116.112 29.4151 115.5 30.0277 115.5 30.7833C115.5 31.5389 116.112 32.1514 116.867 32.1514Z" fill={ color }/>
  <path d="M110.945 32.1514C110.262 32.1514 109.578 31.4673 109.578 30.7833C109.578 30.0992 110.262 29.4151 110.945 29.4151C111.628 29.4151 112.312 30.0992 112.312 30.7833C112.312 31.4673 111.628 32.1514 110.945 32.1514Z" fill={ color }/>
  <path d="M105.019 32.1514C104.336 32.1514 103.652 31.4673 103.652 30.7833C103.652 30.0992 104.336 29.4151 105.019 29.4151C105.703 29.4151 106.386 30.0992 106.386 30.7833C106.158 31.4673 105.703 32.1514 105.019 32.1514Z" fill={ color }/>
  <path d="M98.8695 32.1517C98.1861 32.1517 97.7305 31.6957 97.7305 31.0116C97.7305 30.3275 98.1861 29.8715 98.8695 29.8715C99.553 29.8715 100.009 30.3275 100.009 31.0116C100.236 31.4676 99.553 32.1517 98.8695 32.1517Z" fill={ color }/>
  <path d="M92.9476 31.923C92.2642 31.923 91.8086 31.467 91.8086 30.7829C91.8086 30.0989 92.2642 29.6428 92.9476 29.6428C93.6311 29.6428 94.0867 30.0989 94.0867 30.7829C94.0867 31.467 93.6311 31.923 92.9476 31.923Z" fill={ color }/>
  <path d="M87.0258 31.923C87.6549 31.923 88.1648 31.4126 88.1648 30.7829C88.1648 30.1533 87.6549 29.6428 87.0258 29.6428C86.3967 29.6428 85.8867 30.1533 85.8867 30.7829C85.8867 31.4126 86.3967 31.923 87.0258 31.923Z" fill={ color }/>
  <path d="M81.1027 31.9232C80.647 31.9232 80.1914 31.4672 80.1914 31.0111C80.1914 30.5551 80.647 30.0991 81.1027 30.0991C81.5583 30.0991 82.0139 30.5551 82.0139 31.0111C82.0139 31.4672 81.5583 31.9232 81.1027 31.9232Z" fill={ color }/>
  <path d="M74.9503 31.6957C74.4947 31.6957 74.0391 31.2396 74.0391 30.7836C74.0391 30.3275 74.4947 29.8715 74.9503 29.8715C75.4059 29.8715 75.8615 30.3275 75.8615 30.7836C75.8615 31.2396 75.6337 31.6957 74.9503 31.6957Z" fill={ color }/>
  <path d="M69.0284 31.6957C68.5728 31.6957 68.1172 31.2396 68.1172 30.7836C68.1172 30.3275 68.5728 29.8715 69.0284 29.8715C69.484 29.8715 69.9397 30.3275 69.9397 30.7836C69.9397 31.2396 69.484 31.6957 69.0284 31.6957Z" fill={ color }/>
  <path d="M63.1053 31.6959C62.6497 31.6959 62.4219 31.2399 62.4219 31.0119C62.4219 30.5558 62.8775 30.3278 63.1053 30.3278C63.5609 30.3278 63.7887 30.7838 63.7887 31.0119C63.7887 31.2399 63.5609 31.6959 63.1053 31.6959Z" fill={ color }/>
  <path d="M56.953 31.4672C56.4973 31.4672 56.2695 31.2392 56.2695 30.7831C56.2695 30.3271 56.4973 30.0991 56.953 30.0991C57.4086 30.0991 57.6364 30.3271 57.6364 30.7831C57.6364 31.2392 57.4086 31.4672 56.953 31.4672Z" fill={ color }/>
  <path d="M51.0311 31.4672C51.4085 31.4672 51.7145 31.1609 51.7145 30.7831C51.7145 30.4053 51.4085 30.099 51.0311 30.099C50.6536 30.099 50.3477 30.4053 50.3477 30.7831C50.3477 31.1609 50.6536 31.4672 51.0311 31.4672Z" fill={ color }/>
  <path d="M45.108 31.2399C45.3596 31.2399 45.5636 31.0357 45.5636 30.7838C45.5636 30.532 45.3596 30.3278 45.108 30.3278C44.8563 30.3278 44.6523 30.532 44.6523 30.7838C44.6523 31.0357 44.8563 31.2399 45.108 31.2399Z" fill={ color }/>
  <path d="M38.9556 31.2399C38.7278 31.2399 38.5 31.0119 38.5 30.7838C38.5 30.5558 38.7278 30.3278 38.9556 30.3278C39.1834 30.3278 39.4112 30.5558 39.4112 30.7838C39.4112 31.0119 39.4112 31.2399 38.9556 31.2399Z" fill={ color }/>
  <path d="M33.0337 31.2399C32.8059 31.2399 32.5781 31.0119 32.5781 30.7838C32.5781 30.5558 32.8059 30.3278 33.0337 30.3278C33.2616 30.3278 33.4894 30.5558 33.4894 30.7838C33.4894 31.0119 33.2616 31.2399 33.0337 31.2399Z" fill={ color }/>
  <path d="M27.1106 31.0114C26.8828 31.0114 26.8828 30.7834 26.8828 30.7834C26.8828 30.5554 27.1106 30.5554 27.1106 30.5554C27.3384 30.5554 27.3384 30.7834 27.3384 30.7834C27.3384 31.0114 27.3384 31.0114 27.1106 31.0114Z" fill={ color }/>
  <path d="M21.1865 31.0114C20.9587 31.0114 20.9587 31.0114 21.1865 31.0114C20.9587 30.7834 21.1865 30.5554 21.1865 30.5554C21.1865 30.5554 21.1865 30.7834 21.1865 31.0114Z" fill={ color }/>
  <path d="M15.0352 31.0115C15.0352 31.0115 15.0352 30.7835 15.0352 31.0115C15.0352 30.7835 15.0352 30.7835 15.0352 31.0115C15.0352 30.7835 15.263 30.7835 15.0352 31.0115C15.263 30.7835 15.0352 31.0115 15.0352 31.0115Z" fill={ color }/>
  <path d="M341.719 37.396C342.096 37.396 342.402 37.0897 342.402 36.7119C342.402 36.3341 342.096 36.0278 341.719 36.0278C341.341 36.0278 341.035 36.3341 341.035 36.7119C341.035 37.0897 341.341 37.396 341.719 37.396Z" fill={ color }/>
  <path d="M335.566 38.0802C336.321 38.0802 336.933 37.4677 336.933 36.7121C336.933 35.9565 336.321 35.3439 335.566 35.3439C334.811 35.3439 334.199 35.9565 334.199 36.7121C334.199 37.4677 334.811 38.0802 335.566 38.0802Z" fill={ color }/>
  <path d="M329.643 38.5361C330.649 38.5361 331.465 37.7193 331.465 36.7119C331.465 35.7044 330.649 34.8877 329.643 34.8877C328.636 34.8877 327.82 35.7044 327.82 36.7119C327.82 37.7193 328.636 38.5361 329.643 38.5361Z" fill={ color }/>
  <path d="M323.718 38.9921C322.579 38.9921 321.668 38.08 321.668 36.9399C321.668 35.7998 322.579 34.8877 323.718 34.8877C324.857 34.8877 325.769 35.7998 325.769 36.9399C325.769 38.08 324.857 38.9921 323.718 38.9921Z" fill={ color }/>
  <path d="M317.567 38.9918C318.825 38.9918 319.845 37.9709 319.845 36.7116C319.845 35.4522 318.825 34.4314 317.567 34.4314C316.309 34.4314 315.289 35.4522 315.289 36.7116C315.289 37.9709 316.309 38.9918 317.567 38.9918Z" fill={ color }/>
  <path d="M311.647 39.2203C310.28 39.2203 309.141 38.0802 309.141 36.712C309.141 35.3439 310.28 34.2038 311.647 34.2038C313.013 34.2038 314.152 35.3439 314.152 36.712C314.152 38.0802 313.013 39.2203 311.647 39.2203Z" fill={ color }/>
  <path d="M305.721 39.2203C304.354 39.2203 303.215 38.0802 303.215 36.712C303.215 35.3439 304.354 34.2038 305.721 34.2038C307.088 34.2038 308.227 35.3439 308.227 36.712C308.227 38.3082 307.088 39.2203 305.721 39.2203Z" fill={ color }/>
  <path d="M299.572 39.2203C300.956 39.2203 302.078 38.0973 302.078 36.712C302.078 35.3268 300.956 34.2038 299.572 34.2038C298.188 34.2038 297.066 35.3268 297.066 36.712C297.066 38.0973 298.188 39.2203 299.572 39.2203Z" fill={ color }/>
  <path d="M293.646 39.4479C292.28 39.4479 291.141 38.3077 291.141 36.9396C291.141 35.5715 292.28 34.4314 293.646 34.4314C295.013 34.4314 296.152 35.5715 296.152 36.9396C296.38 38.3077 295.013 39.4479 293.646 39.4479Z" fill={ color }/>
  <path d="M287.725 39.4479C286.358 39.4479 285.219 38.3077 285.219 36.9396C285.219 35.5715 286.358 34.4314 287.725 34.4314C289.092 34.4314 290.231 35.5715 290.231 36.9396C290.231 38.3077 289.092 39.4479 287.725 39.4479Z" fill={ color }/>
  <path d="M281.804 39.4486C283.314 39.4486 284.538 38.2236 284.538 36.7123C284.538 35.2011 283.314 33.9761 281.804 33.9761C280.294 33.9761 279.07 35.2011 279.07 36.7123C279.07 38.2236 280.294 39.4486 281.804 39.4486Z" fill={ color }/>
  <path d="M275.652 39.4486C274.285 39.4486 272.918 38.3085 272.918 36.7123C272.918 35.3442 274.057 33.9761 275.652 33.9761C277.019 33.9761 278.385 35.1162 278.385 36.7123C278.385 38.3085 277.246 39.4486 275.652 39.4486Z" fill={ color }/>
  <path d="M269.729 39.4479C268.362 39.4479 267.223 38.3077 267.223 36.9396C267.223 35.5715 268.362 34.4314 269.729 34.4314C271.095 34.4314 272.234 35.5715 272.234 36.9396C272.234 38.3077 271.095 39.4479 269.729 39.4479Z" fill={ color }/>
  <path d="M263.807 39.4479C262.44 39.4479 261.301 38.3077 261.301 36.9396C261.301 35.5715 262.44 34.4314 263.807 34.4314C265.174 34.4314 266.313 35.5715 266.313 36.9396C266.313 38.3077 265.174 39.4479 263.807 39.4479Z" fill={ color }/>
  <path d="M257.654 39.2203C259.038 39.2203 260.16 38.0973 260.16 36.712C260.16 35.3268 259.038 34.2038 257.654 34.2038C256.27 34.2038 255.148 35.3268 255.148 36.712C255.148 38.0973 256.27 39.2203 257.654 39.2203Z" fill={ color }/>
  <path d="M251.733 39.4479C250.366 39.4479 249.227 38.3077 249.227 36.9396C249.227 35.5715 250.366 34.4314 251.733 34.4314C253.099 34.4314 254.238 35.5715 254.238 36.9396C254.238 38.3077 253.099 39.4479 251.733 39.4479Z" fill={ color }/>
  <path d="M245.807 39.2203C247.191 39.2203 248.313 38.0973 248.313 36.712C248.313 35.3268 247.191 34.2038 245.807 34.2038C244.423 34.2038 243.301 35.3268 243.301 36.712C243.301 38.0973 244.423 39.2203 245.807 39.2203Z" fill={ color }/>
  <path d="M239.658 39.4479C238.291 39.4479 237.152 38.3077 237.152 36.9396C237.152 35.5715 238.291 34.4314 239.658 34.4314C241.025 34.4314 242.164 35.5715 242.164 36.9396C242.392 38.3077 241.253 39.4479 239.658 39.4479Z" fill={ color }/>
  <path d="M233.732 39.2203C235.116 39.2203 236.238 38.0973 236.238 36.712C236.238 35.3268 235.116 34.2038 233.732 34.2038C232.348 34.2038 231.227 35.3268 231.227 36.712C231.227 38.0973 232.348 39.2203 233.732 39.2203Z" fill={ color }/>
  <path d="M227.811 39.2203C226.444 39.2203 225.305 38.0802 225.305 36.712C225.305 35.3439 226.444 34.2038 227.811 34.2038C229.177 34.2038 230.317 35.3439 230.317 36.712C230.317 38.0802 229.177 39.2203 227.811 39.2203Z" fill={ color }/>
  <path d="M221.889 39.2203C223.273 39.2203 224.395 38.0973 224.395 36.712C224.395 35.3268 223.273 34.2038 221.889 34.2038C220.505 34.2038 219.383 35.3268 219.383 36.712C219.383 38.0973 220.505 39.2203 221.889 39.2203Z" fill={ color }/>
  <path d="M215.736 39.2203C214.37 39.2203 213.23 38.0802 213.23 36.712C213.23 35.3439 214.37 34.2038 215.736 34.2038C217.103 34.2038 218.242 35.3439 218.242 36.712C218.242 38.0802 217.103 39.2203 215.736 39.2203Z" fill={ color }/>
  <path d="M209.813 39.2205C208.446 39.2205 207.535 38.0804 207.535 36.9402C207.535 35.5721 208.674 34.66 209.813 34.66C211.18 34.66 212.091 35.8001 212.091 36.9402C212.091 38.0804 211.18 39.2205 209.813 39.2205Z" fill={ color }/>
  <path d="M203.891 39.2205C202.525 39.2205 201.613 38.0804 201.613 36.9402C201.613 35.5721 202.752 34.66 203.891 34.66C205.03 34.66 206.169 35.8001 206.169 36.9402C206.169 38.0804 205.03 39.2205 203.891 39.2205Z" fill={ color }/>
  <path d="M197.739 38.9918C198.997 38.9918 200.017 37.9709 200.017 36.7116C200.017 35.4522 198.997 34.4314 197.739 34.4314C196.481 34.4314 195.461 35.4522 195.461 36.7116C195.461 37.9709 196.481 38.9918 197.739 38.9918Z" fill={ color }/>
  <path d="M191.817 38.9918C190.678 38.9918 189.539 38.0797 189.539 36.7116C189.539 35.3434 190.45 34.4314 191.817 34.4314C192.956 34.4314 194.095 35.3434 194.095 36.7116C194.095 38.0797 193.184 38.9918 191.817 38.9918Z" fill={ color }/>
  <path d="M185.895 38.9918C187.153 38.9918 188.173 37.9709 188.173 36.7116C188.173 35.4522 187.153 34.4314 185.895 34.4314C184.637 34.4314 183.617 35.4522 183.617 36.7116C183.617 37.9709 184.637 38.9918 185.895 38.9918Z" fill={ color }/>
  <path d="M179.743 38.9918C178.604 38.9918 177.465 38.0797 177.465 36.7116C177.465 35.5715 178.376 34.4314 179.743 34.4314C180.882 34.4314 182.021 35.3434 182.021 36.7116C182.021 38.0797 181.11 38.9918 179.743 38.9918Z" fill={ color }/>
  <path d="M173.82 38.992C172.681 38.992 171.77 38.0799 171.77 36.9398C171.77 35.7997 172.681 34.8876 173.82 34.8876C174.959 34.8876 175.87 35.7997 175.87 36.9398C176.098 38.0799 174.959 38.992 173.82 38.992Z" fill={ color }/>
  <path d="M167.898 38.992C166.759 38.992 165.848 38.0799 165.848 36.9398C165.848 35.7997 166.759 34.8876 167.898 34.8876C169.037 34.8876 169.948 35.7997 169.948 36.9398C169.948 37.8519 169.037 38.992 167.898 38.992Z" fill={ color }/>
  <path d="M161.972 38.7644C163.105 38.7644 164.022 37.8456 164.022 36.7122C164.022 35.5788 163.105 34.66 161.972 34.66C160.84 34.66 159.922 35.5788 159.922 36.7122C159.922 37.8456 160.84 38.7644 161.972 38.7644Z" fill={ color }/>
  <path d="M155.824 38.7644C154.685 38.7644 153.773 37.8523 153.773 36.7122C153.773 35.5721 154.685 34.66 155.824 34.66C156.963 34.66 157.874 35.5721 157.874 36.7122C157.874 37.8523 156.963 38.7644 155.824 38.7644Z" fill={ color }/>
  <path d="M149.901 38.536C150.907 38.536 151.723 37.7192 151.723 36.7118C151.723 35.7043 150.907 34.8876 149.901 34.8876C148.894 34.8876 148.078 35.7043 148.078 36.7118C148.078 37.7192 148.894 38.536 149.901 38.536Z" fill={ color }/>
  <path d="M143.975 38.536C144.981 38.536 145.797 37.7192 145.797 36.7118C145.797 35.7043 144.981 34.8876 143.975 34.8876C142.968 34.8876 142.152 35.7043 142.152 36.7118C142.152 37.7192 142.968 38.536 143.975 38.536Z" fill={ color }/>
  <path d="M137.826 38.536C136.915 38.536 136.004 37.6239 136.004 36.7118C136.004 35.7997 136.915 34.8876 137.826 34.8876C138.738 34.8876 139.649 35.7997 139.649 36.7118C139.649 37.6239 138.965 38.536 137.826 38.536Z" fill={ color }/>
  <path d="M131.905 38.536C132.911 38.536 133.727 37.7192 133.727 36.7118C133.727 35.7043 132.911 34.8876 131.905 34.8876C130.898 34.8876 130.082 35.7043 130.082 36.7118C130.082 37.7192 130.898 38.536 131.905 38.536Z" fill={ color }/>
  <path d="M125.981 38.5363C125.07 38.5363 124.387 37.8522 124.387 36.9401C124.387 36.028 125.07 35.3439 125.981 35.3439C126.893 35.3439 127.576 36.028 127.576 36.9401C127.576 37.8522 126.893 38.5363 125.981 38.5363Z" fill={ color }/>
  <path d="M119.829 38.3087C120.71 38.3087 121.424 37.594 121.424 36.7125C121.424 35.831 120.71 35.1163 119.829 35.1163C118.948 35.1163 118.234 35.831 118.234 36.7125C118.234 37.594 118.948 38.3087 119.829 38.3087Z" fill={ color }/>
  <path d="M113.907 38.3087C114.788 38.3087 115.502 37.594 115.502 36.7125C115.502 35.831 114.788 35.1163 113.907 35.1163C113.026 35.1163 112.312 35.831 112.312 36.7125C112.312 37.594 113.026 38.3087 113.907 38.3087Z" fill={ color }/>
  <path d="M107.981 38.3087C107.07 38.3087 106.387 37.6246 106.387 36.7125C106.387 35.8004 107.07 35.1163 107.981 35.1163C108.893 35.1163 109.576 35.8004 109.576 36.7125C109.348 37.6246 108.665 38.3087 107.981 38.3087Z" fill={ color }/>
  <path d="M102.058 38.3078C101.375 38.3078 100.691 37.6237 100.691 36.9397C100.691 36.2556 101.375 35.5715 102.058 35.5715C102.742 35.5715 103.425 36.2556 103.425 36.9397C103.425 37.6237 102.742 38.3078 102.058 38.3078Z" fill={ color }/>
  <path d="M95.9098 38.0802C96.6647 38.0802 97.2767 37.4677 97.2767 36.7121C97.2767 35.9565 96.6647 35.3439 95.9098 35.3439C95.1549 35.3439 94.543 35.9565 94.543 36.7121C94.543 37.4677 95.1549 38.0802 95.9098 38.0802Z" fill={ color }/>
  <path d="M89.984 38.0802C89.3006 38.0802 88.6172 37.3961 88.6172 36.7121C88.6172 36.028 89.3006 35.3439 89.984 35.3439C90.6675 35.3439 91.3509 36.028 91.3509 36.7121C91.3509 37.6242 90.6675 38.0802 89.984 38.0802Z" fill={ color }/>
  <path d="M84.0609 37.8517C84.69 37.8517 85.2 37.3413 85.2 36.7116C85.2 36.082 84.69 35.5715 84.0609 35.5715C83.4318 35.5715 82.9219 36.082 82.9219 36.7116C82.9219 37.3413 83.4318 37.8517 84.0609 37.8517Z" fill={ color }/>
  <path d="M77.9125 37.8517C78.5416 37.8517 79.0515 37.3413 79.0515 36.7116C79.0515 36.082 78.5416 35.5715 77.9125 35.5715C77.2834 35.5715 76.7734 36.082 76.7734 36.7116C76.7734 37.3413 77.2834 37.8517 77.9125 37.8517Z" fill={ color }/>
  <path d="M71.9906 37.8517C71.3072 37.8517 70.8516 37.3957 70.8516 36.7116C70.8516 36.0276 71.3072 35.5715 71.9906 35.5715C72.674 35.5715 73.1297 36.0276 73.1297 36.7116C73.1297 37.3957 72.674 37.8517 71.9906 37.8517Z" fill={ color }/>
  <path d="M66.0675 37.852C65.6119 37.852 65.1562 37.396 65.1562 36.9399C65.1562 36.4839 65.6119 36.0278 66.0675 36.0278C66.5231 36.0278 66.9787 36.4839 66.9787 36.9399C66.9787 37.396 66.5231 37.852 66.0675 37.852Z" fill={ color }/>
  <path d="M59.9151 37.6244C59.4595 37.6244 59.0039 37.1684 59.0039 36.7124C59.0039 36.2563 59.4595 35.8003 59.9151 35.8003C60.3708 35.8003 60.8264 36.2563 60.8264 36.7124C60.8264 37.3964 60.5986 37.6244 59.9151 37.6244Z" fill={ color }/>
  <path d="M53.9894 37.6244C53.5337 37.6244 53.0781 37.1684 53.0781 36.7124C53.0781 36.2563 53.5337 35.8003 53.9894 35.8003C54.445 35.8003 54.9006 36.2563 54.9006 36.7124C54.9006 37.1684 54.445 37.6244 53.9894 37.6244Z" fill={ color }/>
  <path d="M48.0662 37.6247C47.6106 37.6247 47.3828 37.3967 47.3828 36.9407C47.3828 36.4846 47.6106 36.2566 48.0662 36.2566C48.5219 36.2566 48.7497 36.4846 48.7497 36.9407C48.7497 37.1687 48.5219 37.6247 48.0662 37.6247Z" fill={ color }/>
  <path d="M42.1444 37.396C41.6887 37.396 41.4609 37.168 41.4609 36.7119C41.4609 36.2559 41.6887 36.0278 42.1444 36.0278C42.6 36.0278 42.8278 36.2559 42.8278 36.7119C42.6 37.168 42.3722 37.396 42.1444 37.396Z" fill={ color }/>
  <path d="M35.9959 37.396C36.3734 37.396 36.6794 37.0897 36.6794 36.7119C36.6794 36.3341 36.3734 36.0278 35.9959 36.0278C35.6185 36.0278 35.3125 36.3341 35.3125 36.7119C35.3125 37.0897 35.6185 37.396 35.9959 37.396Z" fill={ color }/>
  <path d="M30.0728 37.3963C29.845 37.3963 29.6172 37.1683 29.6172 36.9402C29.6172 36.7122 29.845 36.4842 30.0728 36.4842C30.3006 36.4842 30.5284 36.7122 30.5284 36.9402C30.5284 37.1683 30.3006 37.3963 30.0728 37.3963Z" fill={ color }/>
  <path d="M24.147 37.1687C24.3987 37.1687 24.6027 36.9645 24.6027 36.7126C24.6027 36.4608 24.3987 36.2566 24.147 36.2566C23.8954 36.2566 23.6914 36.4608 23.6914 36.7126C23.6914 36.9645 23.8954 37.1687 24.147 37.1687Z" fill={ color }/>
  <path d="M17.9973 37.1678C17.7695 37.1678 17.7695 36.9398 17.7695 36.9398C17.7695 36.7118 17.9973 36.7118 17.9973 36.7118C18.2252 36.7118 18.2252 36.9398 18.2252 36.9398C18.453 36.9398 18.2252 37.1678 17.9973 37.1678Z" fill={ color }/>
  <path d="M12.0732 36.9402C12.0732 36.9402 11.8454 36.9402 12.0732 36.9402C11.8454 36.7122 11.8454 36.4842 12.0732 36.4842C12.301 36.7122 12.301 36.7122 12.0732 36.9402C12.301 36.9402 12.301 36.9402 12.0732 36.9402Z" fill={ color }/>
  <path d="M6.15203 36.9398C6.15203 36.9398 5.92422 36.9398 6.15203 36.9398C5.92422 36.7118 6.15203 36.7118 6.15203 36.7118C6.15203 36.7118 6.15203 36.7118 6.15203 36.9398Z" fill={ color }/>
  <path d="M350.601 43.5524C350.146 43.5524 349.918 43.0963 349.918 42.8683C349.918 42.6403 350.374 42.1842 350.601 42.1842C350.829 42.1842 351.285 42.6403 351.285 42.8683C351.285 43.0963 351.057 43.5524 350.601 43.5524Z" fill={ color }/>
  <path d="M344.677 44.2363C343.765 44.2363 343.082 43.5522 343.082 42.6401C343.082 41.728 343.765 41.044 344.677 41.044C345.588 41.044 346.271 41.728 346.271 42.6401C346.044 43.5522 345.36 44.2363 344.677 44.2363Z" fill={ color }/>
  <path d="M338.527 44.9208C337.388 44.9208 336.477 44.0087 336.477 42.8686C336.477 41.7285 337.388 40.8164 338.527 40.8164C339.666 40.8164 340.577 41.7285 340.577 42.8686C340.577 44.0087 339.666 44.9208 338.527 44.9208Z" fill={ color }/>
  <path d="M332.602 45.1493C331.235 45.1493 330.324 44.0092 330.324 42.869C330.324 41.5009 331.463 40.5888 332.602 40.5888C333.969 40.5888 334.88 41.7289 334.88 42.869C334.88 44.0092 333.969 45.1493 332.602 45.1493Z" fill={ color }/>
  <path d="M326.682 45.3767C325.315 45.3767 324.176 44.2365 324.176 42.8684C324.176 41.5003 325.315 40.3601 326.682 40.3601C328.049 40.3601 329.188 41.5003 329.188 42.8684C329.188 44.2365 328.049 45.3767 326.682 45.3767Z" fill={ color }/>
  <path d="M320.757 45.3764C319.39 45.3764 318.023 44.2363 318.023 42.6401C318.023 41.2719 319.163 39.9038 320.757 39.9038C322.124 39.9038 323.491 41.0439 323.491 42.6401C323.263 44.2363 322.124 45.3764 320.757 45.3764Z" fill={ color }/>
  <path d="M314.609 45.605C313.014 45.605 311.875 44.4649 311.875 42.8687C311.875 41.2726 313.014 40.1325 314.609 40.1325C316.203 40.1325 317.342 41.2726 317.342 42.8687C317.342 44.2369 316.203 45.605 314.609 45.605Z" fill={ color }/>
  <path d="M308.683 45.605C307.088 45.605 305.949 44.4649 305.949 42.8687C305.949 41.2726 307.088 40.1325 308.683 40.1325C310.278 40.1325 311.417 41.2726 311.417 42.8687C311.417 44.2369 310.278 45.605 308.683 45.605Z" fill={ color }/>
  <path d="M302.761 45.605C301.166 45.605 300.027 44.4649 300.027 42.8687C300.027 41.2726 301.166 40.1325 302.761 40.1325C304.356 40.1325 305.495 41.2726 305.495 42.8687C305.495 44.4649 304.128 45.605 302.761 45.605Z" fill={ color }/>
  <path d="M296.609 45.605C295.014 45.605 293.875 44.2369 293.875 42.8687C293.875 41.2726 295.242 40.1325 296.609 40.1325C298.203 40.1325 299.342 41.5006 299.342 42.8687C299.57 44.4649 298.203 45.605 296.609 45.605Z" fill={ color }/>
  <path d="M290.687 45.605C289.092 45.605 287.953 44.2369 287.953 42.8687C287.953 41.2726 289.32 40.1325 290.687 40.1325C292.282 40.1325 293.421 41.5006 293.421 42.8687C293.421 44.4649 292.282 45.605 290.687 45.605Z" fill={ color }/>
  <path d="M284.765 45.605C283.17 45.605 282.031 44.4649 282.031 42.8687C282.031 41.2726 283.17 40.1325 284.765 40.1325C286.36 40.1325 287.499 41.2726 287.499 42.8687C287.499 44.4649 286.132 45.605 284.765 45.605Z" fill={ color }/>
  <path d="M278.613 45.605C277.018 45.605 275.879 44.4649 275.879 42.8687C275.879 41.2726 277.018 40.1325 278.613 40.1325C280.207 40.1325 281.346 41.2726 281.346 42.8687C281.574 44.2369 280.207 45.605 278.613 45.605Z" fill={ color }/>
  <path d="M272.691 45.605C271.096 45.605 269.957 44.4649 269.957 42.8687C269.957 41.2726 271.096 40.1325 272.691 40.1325C274.285 40.1325 275.424 41.2726 275.424 42.8687C275.424 44.4649 274.285 45.605 272.691 45.605Z" fill={ color }/>
  <path d="M266.765 45.605C265.17 45.605 264.031 44.4649 264.031 42.8687C264.031 41.2726 265.17 40.1325 266.765 40.1325C268.36 40.1325 269.499 41.2726 269.499 42.8687C269.499 44.2369 268.132 45.605 266.765 45.605Z" fill={ color }/>
  <path d="M260.843 45.605C259.248 45.605 258.109 44.4649 258.109 42.8687C258.109 41.2726 259.248 40.1325 260.843 40.1325C262.438 40.1325 263.577 41.2726 263.577 42.8687C263.349 44.2369 262.21 45.605 260.843 45.605Z" fill={ color }/>
  <path d="M254.695 45.605C253.1 45.605 251.961 44.4649 251.961 42.8687C251.961 41.2726 253.1 40.1325 254.695 40.1325C256.289 40.1325 257.428 41.2726 257.428 42.8687C257.428 44.2369 256.289 45.605 254.695 45.605Z" fill={ color }/>
  <path d="M248.769 45.605C250.279 45.605 251.503 44.3799 251.503 42.8687C251.503 41.3575 250.279 40.1325 248.769 40.1325C247.259 40.1325 246.035 41.3575 246.035 42.8687C246.035 44.3799 247.259 45.605 248.769 45.605Z" fill={ color }/>
  <path d="M242.847 45.3764C241.48 45.3764 240.113 44.2363 240.113 42.6401C240.113 41.2719 241.252 39.9038 242.847 39.9038C244.214 39.9038 245.581 41.0439 245.581 42.6401C245.353 44.2363 244.214 45.3764 242.847 45.3764Z" fill={ color }/>
  <path d="M236.693 45.3766C235.327 45.3766 234.188 44.2364 234.188 42.8683C234.188 41.5002 235.327 40.36 236.693 40.36C238.06 40.36 239.199 41.5002 239.199 42.8683C239.427 44.2364 238.288 45.3766 236.693 45.3766Z" fill={ color }/>
  <path d="M230.772 45.3766C229.405 45.3766 228.266 44.2364 228.266 42.8683C228.266 41.5002 229.405 40.36 230.772 40.36C232.138 40.36 233.277 41.5002 233.277 42.8683C233.277 44.2364 232.138 45.3766 230.772 45.3766Z" fill={ color }/>
  <path d="M224.85 45.3766C223.483 45.3766 222.344 44.2364 222.344 42.8683C222.344 41.5002 223.483 40.36 224.85 40.36C226.217 40.36 227.356 41.5002 227.356 42.8683C227.356 44.2364 226.217 45.3766 224.85 45.3766Z" fill={ color }/>
  <path d="M218.697 45.3766C220.081 45.3766 221.203 44.2536 221.203 42.8683C221.203 41.483 220.081 40.36 218.697 40.36C217.313 40.36 216.191 41.483 216.191 42.8683C216.191 44.2536 217.313 45.3766 218.697 45.3766Z" fill={ color }/>
  <path d="M212.775 45.3766C214.159 45.3766 215.281 44.2536 215.281 42.8683C215.281 41.483 214.159 40.36 212.775 40.36C211.391 40.36 210.27 41.483 210.27 42.8683C210.27 44.2536 211.391 45.3766 212.775 45.3766Z" fill={ color }/>
  <path d="M206.854 45.3766C208.238 45.3766 209.359 44.2536 209.359 42.8683C209.359 41.483 208.238 40.36 206.854 40.36C205.47 40.36 204.348 41.483 204.348 42.8683C204.348 44.2536 205.47 45.3766 206.854 45.3766Z" fill={ color }/>
  <path d="M200.928 45.3766C202.312 45.3766 203.434 44.2536 203.434 42.8683C203.434 41.483 202.312 40.36 200.928 40.36C199.544 40.36 198.422 41.483 198.422 42.8683C198.422 44.2536 199.544 45.3766 200.928 45.3766Z" fill={ color }/>
  <path d="M194.778 45.1493C196.036 45.1493 197.056 44.1284 197.056 42.869C197.056 41.6097 196.036 40.5888 194.778 40.5888C193.52 40.5888 192.5 41.6097 192.5 42.869C192.5 44.1284 193.52 45.1493 194.778 45.1493Z" fill={ color }/>
  <path d="M188.856 45.1493C187.489 45.1493 186.578 44.0092 186.578 42.869C186.578 41.5009 187.717 40.5888 188.856 40.5888C190.223 40.5888 191.134 41.7289 191.134 42.869C191.134 44.0092 190.223 45.1493 188.856 45.1493Z" fill={ color }/>
  <path d="M182.93 45.1493C181.564 45.1493 180.652 44.2372 180.652 42.869C180.652 41.5009 181.564 40.5888 182.93 40.5888C184.297 40.5888 185.209 41.5009 185.209 42.869C185.209 44.0092 184.07 45.1493 182.93 45.1493Z" fill={ color }/>
  <path d="M176.782 44.9205C175.643 44.9205 174.504 44.0084 174.504 42.6403C174.504 41.2721 175.415 40.36 176.782 40.36C177.921 40.36 179.06 41.2721 179.06 42.6403C179.06 44.0084 178.149 44.9205 176.782 44.9205Z" fill={ color }/>
  <path d="M170.86 44.9205C169.721 44.9205 168.582 44.0084 168.582 42.6403C168.582 41.2721 169.493 40.36 170.86 40.36C171.999 40.36 173.138 41.2721 173.138 42.6403C173.138 44.0084 171.999 44.9205 170.86 44.9205Z" fill={ color }/>
  <path d="M164.937 44.9208C163.798 44.9208 162.887 44.0087 162.887 42.8686C162.887 41.7285 163.798 40.8164 164.937 40.8164C166.076 40.8164 166.987 41.7285 166.987 42.8686C166.987 44.0087 166.076 44.9208 164.937 44.9208Z" fill={ color }/>
  <path d="M158.785 44.9208C159.917 44.9208 160.835 44.002 160.835 42.8686C160.835 41.7352 159.917 40.8164 158.785 40.8164C157.652 40.8164 156.734 41.7352 156.734 42.8686C156.734 44.002 157.652 44.9208 158.785 44.9208Z" fill={ color }/>
  <path d="M152.863 44.9208C151.724 44.9208 150.812 44.0087 150.812 42.8686C150.812 41.7285 151.724 40.8164 152.863 40.8164C154.002 40.8164 154.913 41.7285 154.913 42.8686C154.913 44.0087 154.002 44.9208 152.863 44.9208Z" fill={ color }/>
  <path d="M146.937 44.6932C145.798 44.6932 144.887 43.7811 144.887 42.641C144.887 41.5009 145.798 40.5888 146.937 40.5888C148.076 40.5888 148.987 41.5009 148.987 42.641C148.759 43.7811 148.076 44.6932 146.937 44.6932Z" fill={ color }/>
  <path d="M141.014 44.6924C139.875 44.6924 139.191 43.7803 139.191 42.8682C139.191 41.728 140.103 41.044 141.014 41.044C141.925 41.044 142.836 41.9561 142.836 42.8682C142.836 43.7803 141.925 44.6924 141.014 44.6924Z" fill={ color }/>
  <path d="M134.865 44.6924C133.954 44.6924 133.043 43.7803 133.043 42.8682C133.043 41.9561 133.954 41.044 134.865 41.044C135.777 41.044 136.688 41.9561 136.688 42.8682C136.688 43.7803 136.005 44.6924 134.865 44.6924Z" fill={ color }/>
  <path d="M128.944 44.6924C128.032 44.6924 127.121 44.0083 127.121 42.8682C127.121 41.9561 127.805 41.044 128.944 41.044C130.083 41.044 130.766 41.728 130.766 42.8682C130.766 43.7803 129.855 44.6924 128.944 44.6924Z" fill={ color }/>
  <path d="M123.018 44.4648C122.107 44.4648 121.195 43.7807 121.195 42.6406C121.195 41.7285 121.879 40.8164 123.018 40.8164C123.929 40.8164 124.84 41.5005 124.84 42.6406C124.612 43.7807 123.929 44.4648 123.018 44.4648Z" fill={ color }/>
  <path d="M116.868 44.465C115.957 44.465 115.273 43.781 115.273 42.8689C115.273 41.9568 115.957 41.2727 116.868 41.2727C117.779 41.2727 118.463 41.9568 118.463 42.8689C118.691 43.781 117.779 44.465 116.868 44.465Z" fill={ color }/>
  <path d="M110.942 44.465C110.031 44.465 109.348 43.781 109.348 42.8689C109.348 41.9568 110.031 41.2727 110.942 41.2727C111.854 41.2727 112.537 41.9568 112.537 42.8689C112.537 43.781 111.854 44.465 110.942 44.465Z" fill={ color }/>
  <path d="M105.02 44.465C105.901 44.465 106.615 43.7504 106.615 42.8689C106.615 41.9873 105.901 41.2727 105.02 41.2727C104.14 41.2727 103.426 41.9873 103.426 42.8689C103.426 43.7504 104.14 44.465 105.02 44.465Z" fill={ color }/>
  <path d="M98.8708 44.2366C99.6257 44.2366 100.238 43.624 100.238 42.8684C100.238 42.1128 99.6257 41.5003 98.8708 41.5003C98.1159 41.5003 97.5039 42.1128 97.5039 42.8684C97.5039 43.624 98.1159 44.2366 98.8708 44.2366Z" fill={ color }/>
  <path d="M92.9489 44.2366C92.2655 44.2366 91.582 43.5525 91.582 42.8684C91.582 42.1844 92.2655 41.5003 92.9489 41.5003C93.6323 41.5003 94.3158 42.1844 94.3158 42.8684C94.3158 43.5525 93.6323 44.2366 92.9489 44.2366Z" fill={ color }/>
  <path d="M87.0231 44.009C86.3397 44.009 85.6562 43.3249 85.6562 42.6409C85.6562 41.9568 86.3397 41.2727 87.0231 41.2727C87.7065 41.2727 88.39 41.9568 88.39 42.6409C88.39 43.553 87.7065 44.009 87.0231 44.009Z" fill={ color }/>
  <path d="M81.1 44.0093C80.4166 44.0093 79.9609 43.5532 79.9609 42.8692C79.9609 42.1851 80.4166 41.7291 81.1 41.7291C81.7834 41.7291 82.239 42.1851 82.239 42.8692C82.239 43.5532 81.7834 44.0093 81.1 44.0093Z" fill={ color }/>
  <path d="M74.9516 44.0093C74.2681 44.0093 73.8125 43.5532 73.8125 42.8692C73.8125 42.1851 74.2681 41.7291 74.9516 41.7291C75.635 41.7291 76.0906 42.1851 76.0906 42.8692C76.0906 43.5532 75.635 44.0093 74.9516 44.0093Z" fill={ color }/>
  <path d="M69.0258 43.7805C68.3423 43.7805 67.8867 43.3245 67.8867 42.6404C67.8867 41.9564 68.3423 41.5003 69.0258 41.5003C69.7092 41.5003 70.1648 41.9564 70.1648 42.6404C70.1648 43.3245 69.7092 43.7805 69.0258 43.7805Z" fill={ color }/>
  <path d="M63.1027 43.7808C62.647 43.7808 62.1914 43.3248 62.1914 42.8687C62.1914 42.4127 62.647 41.9566 63.1027 41.9566C63.5583 41.9566 64.0139 42.4127 64.0139 42.8687C64.0139 43.3248 63.5583 43.7808 63.1027 43.7808Z" fill={ color }/>
  <path d="M56.9542 43.7808C56.4986 43.7808 56.043 43.3248 56.043 42.8687C56.043 42.4127 56.4986 41.9566 56.9542 41.9566C57.4098 41.9566 57.8655 42.4127 57.8655 42.8687C57.8655 43.3248 57.6376 43.7808 56.9542 43.7808Z" fill={ color }/>
  <path d="M51.0284 43.5532C50.5728 43.5532 50.1172 43.0972 50.1172 42.6412C50.1172 42.1851 50.5728 41.7291 51.0284 41.7291C51.4841 41.7291 51.9397 42.1851 51.9397 42.6412C51.9397 43.3252 51.4841 43.5532 51.0284 43.5532Z" fill={ color }/>
  <path d="M45.1053 43.5524C44.6497 43.5524 44.4219 43.3243 44.4219 42.8683C44.4219 42.4123 44.6497 42.1842 45.1053 42.1842C45.5609 42.1842 45.7887 42.4123 45.7887 42.8683C45.7887 43.3243 45.5609 43.5524 45.1053 43.5524Z" fill={ color }/>
  <path d="M38.9569 43.5524C38.5012 43.5524 38.2734 43.3243 38.2734 42.8683C38.2734 42.4123 38.5012 42.1842 38.9569 42.1842C39.4125 42.1842 39.6403 42.4123 39.6403 42.8683C39.6403 43.0963 39.4125 43.5524 38.9569 43.5524Z" fill={ color }/>
  <path d="M33.0337 43.3251C32.8059 43.3251 32.5781 43.0971 32.5781 42.869C32.5781 42.641 32.8059 42.413 33.0337 42.413C33.2616 42.413 33.4894 42.641 33.4894 42.869C33.7172 43.0971 33.4894 43.3251 33.0337 43.3251Z" fill={ color }/>
  <path d="M27.1119 43.3251C27.3635 43.3251 27.5675 43.1209 27.5675 42.869C27.5675 42.6172 27.3635 42.413 27.1119 42.413C26.8602 42.413 26.6562 42.6172 26.6562 42.869C26.6562 43.1209 26.8602 43.3251 27.1119 43.3251Z" fill={ color }/>
  <path d="M21.1861 43.3251C21.4377 43.3251 21.6417 43.1209 21.6417 42.869C21.6417 42.6172 21.4377 42.413 21.1861 42.413C20.9345 42.413 20.7305 42.6172 20.7305 42.869C20.7305 43.1209 20.9345 43.3251 21.1861 43.3251Z" fill={ color }/>
  <path d="M15.0364 43.0966C14.8086 43.0966 14.8086 42.8686 14.8086 42.8686C14.8086 42.6406 15.0364 42.6406 15.0364 42.6406C15.2642 42.6406 15.2642 42.8686 15.2642 42.8686C15.492 42.8686 15.2642 43.0966 15.0364 43.0966Z" fill={ color }/>
  <path d="M9.11226 43.0966C8.88445 43.0966 8.88445 42.8686 9.11226 43.0966C8.88445 42.8686 9.11226 42.6406 9.11226 42.6406C9.11226 42.6406 9.34007 42.6406 9.11226 43.0966C9.34007 42.8686 9.11226 43.0966 9.11226 43.0966Z" fill={ color }/>
  <path d="M3.19039 42.8693C2.96258 42.8693 2.96258 42.8693 3.19039 42.8693C2.96258 42.8693 2.96258 42.6412 3.19039 42.8693C3.19039 42.6412 3.19039 42.8693 3.19039 42.8693Z" fill={ color }/>
  <path d="M353.561 49.2539C353.333 49.2539 353.105 49.0259 353.105 48.7978C353.105 48.5698 353.333 48.3418 353.561 48.3418C353.789 48.3418 354.017 48.5698 354.017 48.7978C354.017 49.0259 353.789 49.2539 353.561 49.2539Z" fill={ color }/>
  <path d="M347.64 50.1654C348.395 50.1654 349.007 49.5528 349.007 48.7972C349.007 48.0416 348.395 47.4291 347.64 47.4291C346.885 47.4291 346.273 48.0416 346.273 48.7972C346.273 49.5528 346.885 50.1654 347.64 50.1654Z" fill={ color }/>
  <path d="M341.714 50.8496C342.847 50.8496 343.765 49.9308 343.765 48.7974C343.765 47.664 342.847 46.7452 341.714 46.7452C340.582 46.7452 339.664 47.664 339.664 48.7974C339.664 49.9308 340.582 50.8496 341.714 50.8496Z" fill={ color }/>
  <path d="M335.565 51.3054C336.949 51.3054 338.07 50.1824 338.07 48.7971C338.07 47.4118 336.949 46.2888 335.565 46.2888C334.181 46.2888 333.059 47.4118 333.059 48.7971C333.059 50.1824 334.181 51.3054 335.565 51.3054Z" fill={ color }/>
  <path d="M329.644 51.5338C328.049 51.5338 326.91 50.3937 326.91 48.7975C326.91 47.2014 328.049 46.0613 329.644 46.0613C331.239 46.0613 332.378 47.2014 332.378 48.7975C332.378 50.3937 331.239 51.5338 329.644 51.5338Z" fill={ color }/>
  <path d="M323.719 51.7612C322.125 51.7612 320.758 50.3931 320.758 48.7969C320.758 47.2007 322.125 45.8326 323.719 45.8326C325.314 45.8326 326.681 47.2007 326.681 48.7969C326.681 50.3931 325.314 51.7612 323.719 51.7612Z" fill={ color }/>
  <path d="M317.567 51.7612C319.203 51.7612 320.529 50.434 320.529 48.7969C320.529 47.1598 319.203 45.8326 317.567 45.8326C315.931 45.8326 314.605 47.1598 314.605 48.7969C314.605 50.434 315.931 51.7612 317.567 51.7612Z" fill={ color }/>
  <path d="M311.645 51.7612C310.05 51.7612 308.684 50.3931 308.684 48.7969C308.684 47.2007 310.05 45.8326 311.645 45.8326C313.24 45.8326 314.607 47.2007 314.607 48.7969C314.607 50.3931 313.24 51.7612 311.645 51.7612Z" fill={ color }/>
  <path d="M305.723 51.7612C307.359 51.7612 308.685 50.434 308.685 48.7969C308.685 47.1598 307.359 45.8326 305.723 45.8326C304.088 45.8326 302.762 47.1598 302.762 48.7969C302.762 50.434 304.088 51.7612 305.723 51.7612Z" fill={ color }/>
  <path d="M299.571 51.7612C297.976 51.7612 296.609 50.3931 296.609 48.7969C296.609 47.2007 297.976 45.8326 299.571 45.8326C301.166 45.8326 302.532 47.2007 302.532 48.7969C302.532 50.3931 301.393 51.7612 299.571 51.7612Z" fill={ color }/>
  <path d="M293.649 51.7612C292.054 51.7612 290.688 50.3931 290.688 48.7969C290.688 47.2007 292.054 45.8326 293.649 45.8326C295.244 45.8326 296.611 47.2007 296.611 48.7969C296.611 50.3931 295.244 51.7612 293.649 51.7612Z" fill={ color }/>
  <path d="M287.727 51.7612C286.132 51.7612 284.766 50.3931 284.766 48.7969C284.766 47.2007 286.132 45.8326 287.727 45.8326C289.322 45.8326 290.689 47.2007 290.689 48.7969C290.689 50.3931 289.322 51.7612 287.727 51.7612Z" fill={ color }/>
  <path d="M281.801 51.7612C283.437 51.7612 284.763 50.434 284.763 48.7969C284.763 47.1598 283.437 45.8326 281.801 45.8326C280.166 45.8326 278.84 47.1598 278.84 48.7969C278.84 50.434 280.166 51.7612 281.801 51.7612Z" fill={ color }/>
  <path d="M275.649 51.7612C274.054 51.7612 272.688 50.3931 272.688 48.7969C272.688 47.2007 274.054 45.8326 275.649 45.8326C277.244 45.8326 278.611 47.2007 278.611 48.7969C278.611 50.3931 277.244 51.7612 275.649 51.7612Z" fill={ color }/>
  <path d="M269.726 51.5338C271.236 51.5338 272.46 50.3087 272.46 48.7975C272.46 47.2863 271.236 46.0613 269.726 46.0613C268.216 46.0613 266.992 47.2863 266.992 48.7975C266.992 50.3087 268.216 51.5338 269.726 51.5338Z" fill={ color }/>
  <path d="M263.804 51.5338C262.209 51.5338 261.07 50.1657 261.07 48.7975C261.07 47.4294 262.437 46.0613 263.804 46.0613C265.399 46.0613 266.538 47.4294 266.538 48.7975C266.538 50.1657 265.399 51.5338 263.804 51.5338Z" fill={ color }/>
  <path d="M257.656 51.5338C259.165 51.5338 260.389 50.3087 260.389 48.7975C260.389 47.2863 259.165 46.0613 257.656 46.0613C256.146 46.0613 254.922 47.2863 254.922 48.7975C254.922 50.3087 256.146 51.5338 257.656 51.5338Z" fill={ color }/>
  <path d="M251.73 51.5338C250.135 51.5338 248.996 50.3937 248.996 48.7975C248.996 47.2014 250.135 46.0613 251.73 46.0613C253.325 46.0613 254.464 47.2014 254.464 48.7975C254.464 50.3937 253.325 51.5338 251.73 51.5338Z" fill={ color }/>
  <path d="M245.808 51.5338C247.318 51.5338 248.542 50.3087 248.542 48.7975C248.542 47.2863 247.318 46.0613 245.808 46.0613C244.298 46.0613 243.074 47.2863 243.074 48.7975C243.074 50.3087 244.298 51.5338 245.808 51.5338Z" fill={ color }/>
  <path d="M239.656 51.5338C238.061 51.5338 236.922 50.3937 236.922 48.7975C236.922 47.2014 238.061 46.0613 239.656 46.0613C241.25 46.0613 242.389 47.2014 242.389 48.7975C242.389 50.3937 241.25 51.5338 239.656 51.5338Z" fill={ color }/>
  <path d="M233.734 51.5338C232.139 51.5338 231 50.3937 231 48.7975C231 47.2014 232.139 46.0613 233.734 46.0613C235.328 46.0613 236.467 47.2014 236.467 48.7975C236.467 50.3937 235.328 51.5338 233.734 51.5338Z" fill={ color }/>
  <path d="M227.812 51.5338C226.445 51.5338 225.078 50.3937 225.078 48.7975C225.078 47.2014 226.217 46.0613 227.812 46.0613C229.179 46.0613 230.546 47.2014 230.546 48.7975C230.546 50.3937 229.179 51.5338 227.812 51.5338Z" fill={ color }/>
  <path d="M221.889 51.5341C220.522 51.5341 219.383 50.394 219.383 49.0258C219.383 47.6577 220.522 46.5176 221.889 46.5176C223.256 46.5176 224.395 47.6577 224.395 49.0258C224.395 50.394 223.256 51.5341 221.889 51.5341Z" fill={ color }/>
  <path d="M215.736 51.3054C214.37 51.3054 213.23 50.1652 213.23 48.7971C213.23 47.429 214.37 46.2888 215.736 46.2888C217.103 46.2888 218.242 47.429 218.242 48.7971C218.242 50.1652 217.331 51.3054 215.736 51.3054Z" fill={ color }/>
  <path d="M209.815 51.3054C211.198 51.3054 212.32 50.1824 212.32 48.7971C212.32 47.4118 211.198 46.2888 209.815 46.2888C208.431 46.2888 207.309 47.4118 207.309 48.7971C207.309 50.1824 208.431 51.3054 209.815 51.3054Z" fill={ color }/>
  <path d="M203.889 51.3054C202.522 51.3054 201.383 50.1652 201.383 48.7971C201.383 47.429 202.522 46.2888 203.889 46.2888C205.256 46.2888 206.395 47.429 206.395 48.7971C206.395 50.1652 205.256 51.3054 203.889 51.3054Z" fill={ color }/>
  <path d="M197.74 51.3054C196.373 51.3054 195.234 50.1652 195.234 48.7971C195.234 47.429 196.373 46.2888 197.74 46.2888C199.107 46.2888 200.246 47.429 200.246 48.7971C200.246 50.1652 199.107 51.3054 197.74 51.3054Z" fill={ color }/>
  <path d="M191.818 51.3054C190.452 51.3054 189.312 50.1652 189.312 48.7971C189.312 47.429 190.452 46.2888 191.818 46.2888C193.185 46.2888 194.324 47.429 194.324 48.7971C194.324 50.1652 193.185 51.3054 191.818 51.3054Z" fill={ color }/>
  <path d="M185.895 51.0781C187.153 51.0781 188.173 50.0572 188.173 48.7978C188.173 47.5385 187.153 46.5176 185.895 46.5176C184.637 46.5176 183.617 47.5385 183.617 48.7978C183.617 50.0572 184.637 51.0781 185.895 51.0781Z" fill={ color }/>
  <path d="M179.743 51.0781C181.001 51.0781 182.021 50.0572 182.021 48.7978C182.021 47.5385 181.001 46.5176 179.743 46.5176C178.485 46.5176 177.465 47.5385 177.465 48.7978C177.465 50.0572 178.485 51.0781 179.743 51.0781Z" fill={ color }/>
  <path d="M173.821 51.0781C172.454 51.0781 171.543 50.166 171.543 48.7978C171.543 47.4297 172.454 46.5176 173.821 46.5176C175.188 46.5176 176.099 47.4297 176.099 48.7978C176.099 50.166 175.188 51.0781 173.821 51.0781Z" fill={ color }/>
  <path d="M167.895 51.0781C166.756 51.0781 165.617 50.166 165.617 48.7978C165.617 47.4297 166.528 46.5176 167.895 46.5176C169.034 46.5176 170.173 47.4297 170.173 48.7978C170.173 50.166 169.034 51.0781 167.895 51.0781Z" fill={ color }/>
  <path d="M161.973 51.0781C163.232 51.0781 164.252 50.0572 164.252 48.7978C164.252 47.5385 163.232 46.5176 161.973 46.5176C160.715 46.5176 159.695 47.5385 159.695 48.7978C159.695 50.0572 160.715 51.0781 161.973 51.0781Z" fill={ color }/>
  <path d="M155.824 50.8496C154.685 50.8496 153.773 49.9375 153.773 48.7974C153.773 47.6573 154.685 46.7452 155.824 46.7452C156.963 46.7452 157.874 47.6573 157.874 48.7974C158.102 49.9375 156.963 50.8496 155.824 50.8496Z" fill={ color }/>
  <path d="M149.898 50.8496C148.759 50.8496 147.848 49.9375 147.848 48.7974C147.848 47.6573 148.759 46.7452 149.898 46.7452C151.037 46.7452 151.948 47.6573 151.948 48.7974C151.948 49.9375 151.037 50.8496 149.898 50.8496Z" fill={ color }/>
  <path d="M143.976 50.8496C142.837 50.8496 141.926 49.9375 141.926 48.7974C141.926 47.6573 142.837 46.7452 143.976 46.7452C145.115 46.7452 146.026 47.6573 146.026 48.7974C145.799 49.9375 145.115 50.8496 143.976 50.8496Z" fill={ color }/>
  <path d="M137.828 50.8496C136.689 50.8496 135.777 49.9375 135.777 48.7974C135.777 47.6573 136.689 46.7452 137.828 46.7452C138.967 46.7452 139.878 47.6573 139.878 48.7974C139.878 49.9375 138.967 50.8496 137.828 50.8496Z" fill={ color }/>
  <path d="M131.905 50.6211C130.765 50.6211 130.082 49.709 130.082 48.797C130.082 47.6568 130.993 46.9728 131.905 46.9728C133.044 46.9728 133.727 47.8849 133.727 48.797C133.727 49.9371 133.044 50.6211 131.905 50.6211Z" fill={ color }/>
  <path d="M125.979 50.6211C126.985 50.6211 127.801 49.8044 127.801 48.797C127.801 47.7895 126.985 46.9728 125.979 46.9728C124.972 46.9728 124.156 47.7895 124.156 48.797C124.156 49.8044 124.972 50.6211 125.979 50.6211Z" fill={ color }/>
  <path d="M119.826 50.6211C118.915 50.6211 118.004 49.9371 118.004 48.797C118.004 47.8849 118.687 46.9728 119.826 46.9728C120.738 46.9728 121.649 47.6568 121.649 48.797C121.649 49.709 120.965 50.6211 119.826 50.6211Z" fill={ color }/>
  <path d="M113.907 50.6214C112.996 50.6214 112.312 49.9374 112.312 49.0253C112.312 48.1132 112.996 47.4291 113.907 47.4291C114.818 47.4291 115.502 48.1132 115.502 49.0253C115.502 49.9374 114.818 50.6214 113.907 50.6214Z" fill={ color }/>
  <path d="M107.981 50.3938C107.07 50.3938 106.387 49.7098 106.387 48.7977C106.387 47.8856 107.07 47.2015 107.981 47.2015C108.893 47.2015 109.576 47.8856 109.576 48.7977C109.576 49.7098 108.893 50.3938 107.981 50.3938Z" fill={ color }/>
  <path d="M102.06 50.3938C101.148 50.3938 100.465 49.7098 100.465 48.7977C100.465 47.8856 101.148 47.2015 102.06 47.2015C102.971 47.2015 103.654 47.8856 103.654 48.7977C103.654 49.7098 102.743 50.3938 102.06 50.3938Z" fill={ color }/>
  <path d="M95.9072 50.3938C96.7879 50.3938 97.5018 49.6792 97.5018 48.7977C97.5018 47.9161 96.7879 47.2015 95.9072 47.2015C95.0265 47.2015 94.3125 47.9161 94.3125 48.7977C94.3125 49.6792 95.0265 50.3938 95.9072 50.3938Z" fill={ color }/>
  <path d="M89.984 50.1654C89.3006 50.1654 88.6172 49.4813 88.6172 48.7972C88.6172 48.1132 89.3006 47.4291 89.984 47.4291C90.6675 47.4291 91.3509 48.1132 91.3509 48.7972C91.3509 49.4813 90.6675 50.1654 89.984 50.1654Z" fill={ color }/>
  <path d="M84.0622 50.1654C83.3788 50.1654 82.6953 49.4813 82.6953 48.7972C82.6953 48.1132 83.3788 47.4291 84.0622 47.4291C84.7456 47.4291 85.429 48.1132 85.429 48.7972C85.429 49.4813 84.7456 50.1654 84.0622 50.1654Z" fill={ color }/>
  <path d="M77.9098 50.1654C77.2264 50.1654 76.543 49.4813 76.543 48.7972C76.543 48.1132 77.2264 47.4291 77.9098 47.4291C78.5933 47.4291 79.2767 48.1132 79.2767 48.7972C79.2767 49.4813 78.5933 50.1654 77.9098 50.1654Z" fill={ color }/>
  <path d="M71.9906 49.9381C71.3072 49.9381 70.8516 49.482 70.8516 48.798C70.8516 48.1139 71.3072 47.6579 71.9906 47.6579C72.674 47.6579 73.1297 48.1139 73.1297 48.798C73.1297 49.482 72.674 49.9381 71.9906 49.9381Z" fill={ color }/>
  <path d="M66.0648 49.9381C65.3814 49.9381 64.9258 49.482 64.9258 48.798C64.9258 48.1139 65.3814 47.6579 66.0648 47.6579C66.7483 47.6579 67.2039 48.1139 67.2039 48.798C67.2039 49.482 66.7483 49.9381 66.0648 49.9381Z" fill={ color }/>
  <path d="M59.9125 49.9381C59.2291 49.9381 58.7734 49.482 58.7734 48.798C58.7734 48.1139 59.2291 47.6579 59.9125 47.6579C60.5959 47.6579 61.0515 48.1139 61.0515 48.798C61.0515 49.482 60.5959 49.9381 59.9125 49.9381Z" fill={ color }/>
  <path d="M53.9894 49.7085C53.5337 49.7085 53.0781 49.2524 53.0781 48.7964C53.0781 48.3403 53.5337 47.8843 53.9894 47.8843C54.445 47.8843 54.9006 48.3403 54.9006 48.7964C54.9006 49.2524 54.6728 49.7085 53.9894 49.7085Z" fill={ color }/>
  <path d="M48.0675 49.7085C47.6119 49.7085 47.1562 49.2524 47.1562 48.7964C47.1562 48.3403 47.6119 47.8843 48.0675 47.8843C48.5231 47.8843 48.9787 48.3403 48.9787 48.7964C48.9787 49.2524 48.5231 49.7085 48.0675 49.7085Z" fill={ color }/>
  <path d="M42.1444 49.4812C41.6887 49.4812 41.4609 49.0251 41.4609 48.7971C41.4609 48.5691 41.9166 48.113 42.1444 48.113C42.6 48.113 42.8278 48.5691 42.8278 48.7971C42.8278 49.0251 42.3722 49.4812 42.1444 49.4812Z" fill={ color }/>
  <path d="M35.9959 49.4812C35.5403 49.4812 35.3125 49.2531 35.3125 48.7971C35.3125 48.341 35.5403 48.113 35.9959 48.113C36.4516 48.113 36.6794 48.341 36.6794 48.7971C36.6794 49.2531 36.4516 49.4812 35.9959 49.4812Z" fill={ color }/>
  <path d="M30.0702 49.4812C29.8423 49.4812 29.3867 49.2531 29.3867 48.7971C29.3867 48.5691 29.6145 48.113 30.0702 48.113C30.298 48.113 30.7536 48.341 30.7536 48.7971C30.7536 49.2531 30.298 49.4812 30.0702 49.4812Z" fill={ color }/>
  <path d="M24.147 49.2539C24.3987 49.2539 24.6027 49.0497 24.6027 48.7978C24.6027 48.546 24.3987 48.3418 24.147 48.3418C23.8954 48.3418 23.6914 48.546 23.6914 48.7978C23.6914 49.0497 23.8954 49.2539 24.147 49.2539Z" fill={ color }/>
  <path d="M17.9986 49.2539C17.7708 49.2539 17.543 49.0259 17.543 48.7978C17.543 48.5698 17.7708 48.3418 17.9986 48.3418C18.2264 48.3418 18.4542 48.5698 18.4542 48.7978C18.4542 49.0259 18.2264 49.2539 17.9986 49.2539Z" fill={ color }/>
  <path d="M12.0728 49.2539C12.3244 49.2539 12.5284 49.0497 12.5284 48.7978C12.5284 48.546 12.3244 48.3418 12.0728 48.3418C11.8212 48.3418 11.6172 48.546 11.6172 48.7978C11.6172 49.0497 11.8212 49.2539 12.0728 49.2539Z" fill={ color }/>
  <path d="M6.14969 49.0254C5.92187 49.0254 5.92188 49.0254 5.92188 48.7974C5.92188 48.5694 5.92187 48.5694 6.14969 48.5694C6.3775 48.5694 6.3775 48.5694 6.3775 48.7974C6.3775 49.0254 6.14969 49.0254 6.14969 49.0254Z" fill={ color }/>
  <path d="M0.227811 49.0254C0.353627 49.0254 0.455621 48.9233 0.455621 48.7974C0.455621 48.6715 0.353627 48.5694 0.227811 48.5694C0.101994 48.5694 0 48.6715 0 48.7974C0 48.9233 0.101994 49.0254 0.227811 49.0254Z" fill={ color }/>
  <path d="M356.523 55.41C356.068 55.41 355.84 55.1819 355.84 54.7259C355.84 54.2698 356.068 54.0418 356.523 54.0418C356.979 54.0418 357.207 54.2698 357.207 54.7259C357.207 55.1819 356.979 55.41 356.523 55.41Z" fill={ color }/>
  <path d="M350.602 56.3215C349.691 56.3215 349.008 55.6374 349.008 54.7253C349.008 53.8132 349.691 53.1291 350.602 53.1291C351.514 53.1291 352.197 53.8132 352.197 54.7253C352.197 55.6374 351.514 56.3215 350.602 56.3215Z" fill={ color }/>
  <path d="M344.677 57.2333C343.31 57.2333 342.398 56.0932 342.398 54.953C342.398 53.5849 343.537 52.6728 344.677 52.6728C346.043 52.6728 346.955 53.8129 346.955 54.953C346.955 56.0932 345.816 57.2333 344.677 57.2333Z" fill={ color }/>
  <path d="M338.527 57.4626C336.932 57.4626 335.793 56.3225 335.793 54.7263C335.793 53.1302 336.932 51.9901 338.527 51.9901C340.121 51.9901 341.26 53.1302 341.26 54.7263C341.488 56.3225 340.121 57.4626 338.527 57.4626Z" fill={ color }/>
  <path d="M332.602 57.6899C331.007 57.6899 329.641 56.3218 329.641 54.7256C329.641 53.1294 331.007 51.7613 332.602 51.7613C334.197 51.7613 335.564 53.1294 335.564 54.7256C335.564 56.5498 334.197 57.6899 332.602 57.6899Z" fill={ color }/>
  <path d="M326.68 57.6899C325.086 57.6899 323.719 56.3218 323.719 54.7256C323.719 53.1294 325.086 51.7613 326.68 51.7613C328.275 51.7613 329.642 53.1294 329.642 54.7256C329.642 56.5498 328.275 57.6899 326.68 57.6899Z" fill={ color }/>
  <path d="M320.758 57.6899C322.394 57.6899 323.72 56.3627 323.72 54.7256C323.72 53.0885 322.394 51.7613 320.758 51.7613C319.123 51.7613 317.797 53.0885 317.797 54.7256C317.797 56.3627 319.123 57.6899 320.758 57.6899Z" fill={ color }/>
  <path d="M314.606 57.6899C316.242 57.6899 317.568 56.3627 317.568 54.7256C317.568 53.0885 316.242 51.7613 314.606 51.7613C312.97 51.7613 311.645 53.0885 311.645 54.7256C311.645 56.3627 312.97 57.6899 314.606 57.6899Z" fill={ color }/>
  <path d="M308.684 57.6899C307.09 57.6899 305.723 56.3218 305.723 54.7256C305.723 53.1294 307.09 51.7613 308.684 51.7613C310.279 51.7613 311.646 53.1294 311.646 54.7256C311.646 56.5498 310.279 57.6899 308.684 57.6899Z" fill={ color }/>
  <path d="M302.758 57.6899C301.164 57.6899 299.797 56.3218 299.797 54.7256C299.797 53.1294 301.164 51.7613 302.758 51.7613C304.353 51.7613 305.72 53.1294 305.72 54.7256C305.72 56.5498 304.353 57.6899 302.758 57.6899Z" fill={ color }/>
  <path d="M296.61 57.6899C295.015 57.6899 293.648 56.3218 293.648 54.7256C293.648 53.1294 295.015 51.7613 296.61 51.7613C298.205 51.7613 299.572 53.1294 299.572 54.7256C299.572 56.3218 298.205 57.6899 296.61 57.6899Z" fill={ color }/>
  <path d="M290.688 57.6899C289.093 57.6899 287.727 56.3218 287.727 54.7256C287.727 53.1294 289.093 51.7613 290.688 51.7613C292.283 51.7613 293.65 53.1294 293.65 54.7256C293.65 56.3218 292.283 57.6899 290.688 57.6899Z" fill={ color }/>
  <path d="M284.762 57.6899C283.168 57.6899 281.801 56.3218 281.801 54.7256C281.801 53.1294 283.168 51.7613 284.762 51.7613C286.357 51.7613 287.724 53.1294 287.724 54.7256C287.724 56.3218 286.357 57.6899 284.762 57.6899Z" fill={ color }/>
  <path d="M278.614 57.6899C280.249 57.6899 281.575 56.3627 281.575 54.7256C281.575 53.0885 280.249 51.7613 278.614 51.7613C276.978 51.7613 275.652 53.0885 275.652 54.7256C275.652 56.3627 276.978 57.6899 278.614 57.6899Z" fill={ color }/>
  <path d="M272.688 57.6899C271.093 57.6899 269.727 56.3218 269.727 54.7256C269.727 53.1294 271.093 51.7613 272.688 51.7613C274.283 51.7613 275.65 53.1294 275.65 54.7256C275.65 56.3218 274.283 57.6899 272.688 57.6899Z" fill={ color }/>
  <path d="M266.766 57.6899C265.172 57.6899 263.805 56.3218 263.805 54.7256C263.805 53.1294 265.172 51.7613 266.766 51.7613C268.361 51.7613 269.728 53.1294 269.728 54.7256C269.5 56.3218 268.361 57.6899 266.766 57.6899Z" fill={ color }/>
  <path d="M260.843 57.6902C259.248 57.6902 258.109 56.3221 258.109 54.9539C258.109 53.3578 259.476 52.2176 260.843 52.2176C262.438 52.2176 263.577 53.5858 263.577 54.9539C263.577 56.3221 262.21 57.6902 260.843 57.6902Z" fill={ color }/>
  <path d="M254.695 57.6902C253.1 57.6902 251.961 56.3221 251.961 54.9539C251.961 53.5858 253.328 52.2176 254.695 52.2176C256.289 52.2176 257.428 53.5858 257.428 54.9539C257.428 56.3221 256.289 57.6902 254.695 57.6902Z" fill={ color }/>
  <path d="M248.769 57.6902C247.174 57.6902 246.035 56.5501 246.035 54.9539C246.035 53.3578 247.174 52.2176 248.769 52.2176C250.364 52.2176 251.503 53.3578 251.503 54.9539C251.503 56.3221 250.364 57.6902 248.769 57.6902Z" fill={ color }/>
  <path d="M242.847 57.4626C241.252 57.4626 240.113 56.3225 240.113 54.7263C240.113 53.1302 241.252 51.9901 242.847 51.9901C244.442 51.9901 245.581 53.1302 245.581 54.7263C245.581 56.3225 244.214 57.4626 242.847 57.4626Z" fill={ color }/>
  <path d="M236.695 57.4626C235.1 57.4626 233.961 56.3225 233.961 54.7263C233.961 53.1302 235.1 51.9901 236.695 51.9901C238.289 51.9901 239.428 53.1302 239.428 54.7263C239.428 56.3225 238.289 57.4626 236.695 57.4626Z" fill={ color }/>
  <path d="M230.773 57.4626C229.178 57.4626 228.039 56.3225 228.039 54.7263C228.039 53.1302 229.178 51.9901 230.773 51.9901C232.367 51.9901 233.507 53.1302 233.507 54.7263C233.507 56.3225 232.367 57.4626 230.773 57.4626Z" fill={ color }/>
  <path d="M224.851 57.4626C223.484 57.4626 222.117 56.3225 222.117 54.7263C222.117 53.3582 223.256 51.9901 224.851 51.9901C226.218 51.9901 227.585 53.1302 227.585 54.7263C227.357 56.3225 226.218 57.4626 224.851 57.4626Z" fill={ color }/>
  <path d="M218.697 57.4617C217.33 57.4617 216.191 56.3216 216.191 54.9535C216.191 53.5853 217.33 52.4452 218.697 52.4452C220.064 52.4452 221.203 53.5853 221.203 54.9535C221.431 56.3216 220.292 57.4617 218.697 57.4617Z" fill={ color }/>
  <path d="M212.775 57.4617C211.409 57.4617 210.27 56.3216 210.27 54.9535C210.27 53.5853 211.409 52.4452 212.775 52.4452C214.142 52.4452 215.281 53.5853 215.281 54.9535C215.281 56.3216 214.142 57.4617 212.775 57.4617Z" fill={ color }/>
  <path d="M206.854 57.2341C205.487 57.2341 204.348 56.094 204.348 54.7259C204.348 53.3577 205.487 52.2176 206.854 52.2176C208.22 52.2176 209.359 53.3577 209.359 54.7259C209.359 56.094 208.22 57.2341 206.854 57.2341Z" fill={ color }/>
  <path d="M200.928 57.2341C199.561 57.2341 198.422 56.094 198.422 54.7259C198.422 53.3577 199.561 52.2176 200.928 52.2176C202.295 52.2176 203.434 53.3577 203.434 54.7259C203.434 56.094 202.295 57.2341 200.928 57.2341Z" fill={ color }/>
  <path d="M194.779 57.2341C193.413 57.2341 192.273 56.094 192.273 54.7259C192.273 53.3577 193.413 52.2176 194.779 52.2176C196.146 52.2176 197.285 53.3577 197.285 54.7259C197.285 56.094 196.146 57.2341 194.779 57.2341Z" fill={ color }/>
  <path d="M188.857 57.2341C190.241 57.2341 191.363 56.1112 191.363 54.7259C191.363 53.3406 190.241 52.2176 188.857 52.2176C187.473 52.2176 186.352 53.3406 186.352 54.7259C186.352 56.1112 187.473 57.2341 188.857 57.2341Z" fill={ color }/>
  <path d="M182.93 57.2333C181.564 57.2333 180.652 56.0932 180.652 54.953C180.652 53.5849 181.791 52.6728 182.93 52.6728C184.297 52.6728 185.209 53.8129 185.209 54.953C185.209 56.0932 184.07 57.2333 182.93 57.2333Z" fill={ color }/>
  <path d="M176.782 57.0057C175.415 57.0057 174.504 55.8656 174.504 54.7255C174.504 53.3573 175.643 52.4452 176.782 52.4452C178.149 52.4452 179.06 53.5853 179.06 54.7255C179.06 56.0936 178.149 57.0057 176.782 57.0057Z" fill={ color }/>
  <path d="M170.86 57.0057C169.721 57.0057 168.582 56.0936 168.582 54.7255C168.582 53.5853 169.493 52.4452 170.86 52.4452C171.999 52.4452 173.138 53.3573 173.138 54.7255C173.138 56.0936 172.227 57.0057 170.86 57.0057Z" fill={ color }/>
  <path d="M164.934 57.0057C163.795 57.0057 162.656 56.0936 162.656 54.7255C162.656 53.5853 163.567 52.4452 164.934 52.4452C166.073 52.4452 167.212 53.3573 167.212 54.7255C166.985 56.0936 166.073 57.0057 164.934 57.0057Z" fill={ color }/>
  <path d="M158.785 57.006C157.646 57.006 156.734 56.0939 156.734 54.9538C156.734 53.8136 157.646 52.9016 158.785 52.9016C159.924 52.9016 160.835 53.8136 160.835 54.9538C161.063 56.0939 160.152 57.006 158.785 57.006Z" fill={ color }/>
  <path d="M152.863 57.006C151.724 57.006 150.812 56.0939 150.812 54.9538C150.812 53.8136 151.724 52.9016 152.863 52.9016C154.002 52.9016 154.913 53.8136 154.913 54.9538C154.913 56.0939 154.002 57.006 152.863 57.006Z" fill={ color }/>
  <path d="M146.937 56.7772C145.798 56.7772 144.887 55.8651 144.887 54.725C144.887 53.5849 145.798 52.6728 146.937 52.6728C148.076 52.6728 148.987 53.5849 148.987 54.725C148.987 55.8651 148.076 56.7772 146.937 56.7772Z" fill={ color }/>
  <path d="M141.015 56.7772C139.876 56.7772 138.965 55.8651 138.965 54.725C138.965 53.5849 139.876 52.6728 141.015 52.6728C142.154 52.6728 143.065 53.5849 143.065 54.725C142.838 55.8651 141.926 56.7772 141.015 56.7772Z" fill={ color }/>
  <path d="M134.865 56.7775C133.726 56.7775 133.043 55.8654 133.043 54.9533C133.043 53.8132 133.954 53.1291 134.865 53.1291C136.005 53.1291 136.688 54.0412 136.688 54.9533C136.916 55.8654 136.005 56.7775 134.865 56.7775Z" fill={ color }/>
  <path d="M128.944 56.7775C127.805 56.7775 127.121 55.8654 127.121 54.9533C127.121 53.8132 128.032 53.1291 128.944 53.1291C129.855 53.1291 130.766 54.0412 130.766 54.9533C130.766 55.8654 129.855 56.7775 128.944 56.7775Z" fill={ color }/>
  <path d="M123.018 56.5499C122.107 56.5499 121.195 55.6378 121.195 54.7257C121.195 53.8136 122.107 52.9016 123.018 52.9016C123.929 52.9016 124.84 53.8136 124.84 54.7257C124.84 55.8659 123.929 56.5499 123.018 56.5499Z" fill={ color }/>
  <path d="M116.865 56.5499C115.954 56.5499 115.043 55.8659 115.043 54.7257C115.043 53.8136 115.726 52.9016 116.865 52.9016C118.005 52.9016 118.688 53.5856 118.688 54.7257C118.688 55.8659 118.005 56.5499 116.865 56.5499Z" fill={ color }/>
  <path d="M110.942 56.5502C110.031 56.5502 109.348 55.8662 109.348 54.9541C109.348 54.042 110.031 53.3579 110.942 53.3579C111.854 53.3579 112.537 54.042 112.537 54.9541C112.537 55.6381 111.854 56.5502 110.942 56.5502Z" fill={ color }/>
  <path d="M105.02 56.3215C105.901 56.3215 106.615 55.6068 106.615 54.7253C106.615 53.8438 105.901 53.1291 105.02 53.1291C104.14 53.1291 103.426 53.8438 103.426 54.7253C103.426 55.6068 104.14 56.3215 105.02 56.3215Z" fill={ color }/>
  <path d="M98.8681 56.3215C99.7488 56.3215 100.463 55.6068 100.463 54.7253C100.463 53.8438 99.7488 53.1291 98.8681 53.1291C97.9874 53.1291 97.2734 53.8438 97.2734 54.7253C97.2734 55.6068 97.9874 56.3215 98.8681 56.3215Z" fill={ color }/>
  <path d="M92.9489 56.3218C92.0376 56.3218 91.582 55.6377 91.582 54.9536C91.582 54.0415 92.2655 53.5855 92.9489 53.5855C93.6323 53.5855 94.3158 54.2696 94.3158 54.9536C94.5436 55.6377 93.8601 56.3218 92.9489 56.3218Z" fill={ color }/>
  <path d="M87.0231 56.0942C86.3397 56.0942 85.6562 55.4101 85.6562 54.726C85.6562 54.042 86.3397 53.3579 87.0231 53.3579C87.7065 53.3579 88.39 54.042 88.39 54.726C88.39 55.6381 87.7065 56.0942 87.0231 56.0942Z" fill={ color }/>
  <path d="M81.1012 56.0942C80.4178 56.0942 79.7344 55.4101 79.7344 54.726C79.7344 54.042 80.4178 53.3579 81.1012 53.3579C81.7847 53.3579 82.4681 54.042 82.4681 54.726C82.2403 55.6381 81.7847 56.0942 81.1012 56.0942Z" fill={ color }/>
  <path d="M74.9516 56.0933C74.2681 56.0933 73.8125 55.6373 73.8125 54.9532C73.8125 54.2691 74.2681 53.8131 74.9516 53.8131C75.635 53.8131 76.0906 54.2691 76.0906 54.9532C76.3184 55.4092 75.635 56.0933 74.9516 56.0933Z" fill={ color }/>
  <path d="M69.0258 55.8657C68.3423 55.8657 67.8867 55.4097 67.8867 54.7256C67.8867 54.0415 68.3423 53.5855 69.0258 53.5855C69.7092 53.5855 70.1648 54.0415 70.1648 54.7256C70.1648 55.4097 69.7092 55.8657 69.0258 55.8657Z" fill={ color }/>
  <path d="M63.1039 55.8657C62.4205 55.8657 61.9648 55.4097 61.9648 54.7256C61.9648 54.0415 62.4205 53.5855 63.1039 53.5855C63.7873 53.5855 64.243 54.0415 64.243 54.7256C64.0151 55.4097 63.5595 55.8657 63.1039 55.8657Z" fill={ color }/>
  <path d="M56.9542 55.866C56.4986 55.866 56.043 55.41 56.043 54.9539C56.043 54.4979 56.4986 54.0418 56.9542 54.0418C57.4098 54.0418 57.8655 54.4979 57.8655 54.9539C58.0933 55.41 57.6376 55.866 56.9542 55.866Z" fill={ color }/>
  <path d="M51.0284 55.6373C50.5728 55.6373 50.1172 55.1812 50.1172 54.7252C50.1172 54.2691 50.5728 53.8131 51.0284 53.8131C51.4841 53.8131 51.9397 54.2691 51.9397 54.7252C51.9397 55.4092 51.4841 55.6373 51.0284 55.6373Z" fill={ color }/>
  <path d="M45.1066 55.6373C44.6509 55.6373 44.1953 55.1812 44.1953 54.7252C44.1953 54.2691 44.6509 53.8131 45.1066 53.8131C45.5622 53.8131 46.0178 54.2691 46.0178 54.7252C45.79 55.1812 45.5622 55.6373 45.1066 55.6373Z" fill={ color }/>
  <path d="M38.9569 55.6386C38.5012 55.6386 38.2734 55.4106 38.2734 54.9545C38.2734 54.4985 38.5012 54.2705 38.9569 54.2705C39.4125 54.2705 39.6403 54.4985 39.6403 54.9545C39.8681 55.1826 39.4125 55.6386 38.9569 55.6386Z" fill={ color }/>
  <path d="M33.0311 55.41C32.5755 55.41 32.3477 55.1819 32.3477 54.7259C32.3477 54.2698 32.5755 54.0418 33.0311 54.0418C33.4867 54.0418 33.7145 54.2698 33.7145 54.7259C33.7145 55.1819 33.4867 55.41 33.0311 55.41Z" fill={ color }/>
  <path d="M27.1119 55.1826C27.3635 55.1826 27.5675 54.9784 27.5675 54.7265C27.5675 54.4746 27.3635 54.2705 27.1119 54.2705C26.8602 54.2705 26.6562 54.4746 26.6562 54.7265C26.6562 54.9784 26.8602 55.1826 27.1119 55.1826Z" fill={ color }/>
  <path d="M21.1861 55.1826C20.9583 55.1826 20.7305 54.9545 20.7305 54.7265C20.7305 54.4985 20.9583 54.2705 21.1861 54.2705C21.4139 54.2705 21.6417 54.4985 21.6417 54.7265C21.6417 54.9545 21.4139 55.1826 21.1861 55.1826Z" fill={ color }/>
  <path d="M15.0337 55.1826C14.8059 55.1826 14.5781 54.9545 14.5781 54.7265C14.5781 54.4985 14.8059 54.2705 15.0337 54.2705C15.2616 54.2705 15.4894 54.4985 15.4894 54.7265C15.4894 54.9545 15.2616 55.1826 15.0337 55.1826Z" fill={ color }/>
  <path d="M9.11453 55.1818C8.88672 55.1818 8.88672 54.9538 8.88672 54.9538C8.88672 54.7257 9.11453 54.7257 9.11453 54.7257C9.34234 54.7257 9.34234 54.9538 9.34234 54.9538C9.34234 54.9538 9.34234 55.1818 9.11453 55.1818Z" fill={ color }/>
  <path d="M3.19039 54.9542C2.96258 54.9542 2.96258 54.9542 3.19039 54.9542C2.96258 54.7262 3.19039 54.4982 3.19039 54.4982C3.19039 54.4982 3.19039 54.7262 3.19039 54.9542Z" fill={ color }/>
  <path d="M353.562 61.3388C353.107 61.3388 352.879 61.1107 352.879 60.6547C352.879 60.1986 353.107 59.9706 353.562 59.9706C354.018 59.9706 354.246 60.1986 354.246 60.6547C354.246 61.1107 354.018 61.3388 353.562 61.3388Z" fill={ color }/>
  <path d="M347.638 62.4779C346.726 62.4779 346.043 61.7938 346.043 60.8817C346.043 59.9696 346.726 59.2855 347.638 59.2855C348.549 59.2855 349.232 59.9696 349.232 60.8817C349.232 61.7938 348.549 62.4779 347.638 62.4779Z" fill={ color }/>
  <path d="M341.716 63.1621C342.974 63.1621 343.994 62.1412 343.994 60.8818C343.994 59.6225 342.974 58.6016 341.716 58.6016C340.457 58.6016 339.438 59.6225 339.438 60.8818C339.438 62.1412 340.457 63.1621 341.716 63.1621Z" fill={ color }/>
  <path d="M335.566 63.6178C337.076 63.6178 338.3 62.3928 338.3 60.8815C338.3 59.3703 337.076 58.1453 335.566 58.1453C334.056 58.1453 332.832 59.3703 332.832 60.8815C332.832 62.3928 334.056 63.6178 335.566 63.6178Z" fill={ color }/>
  <path d="M329.641 63.8474C328.047 63.8474 326.68 62.4793 326.68 60.8831C326.68 59.287 328.047 57.9188 329.641 57.9188C331.236 57.9188 332.603 59.287 332.603 60.8831C332.603 62.4793 331.236 63.8474 329.641 63.8474Z" fill={ color }/>
  <path d="M323.719 63.8474C322.125 63.8474 320.758 62.4793 320.758 60.8831C320.758 59.287 322.125 57.9188 323.719 57.9188C325.314 57.9188 326.681 59.287 326.681 60.8831C326.681 62.4793 325.314 63.8474 323.719 63.8474Z" fill={ color }/>
  <path d="M317.567 63.8474C319.203 63.8474 320.529 62.5203 320.529 60.8831C320.529 59.246 319.203 57.9188 317.567 57.9188C315.931 57.9188 314.605 59.246 314.605 60.8831C314.605 62.5203 315.931 63.8474 317.567 63.8474Z" fill={ color }/>
  <path d="M311.645 63.8474C313.281 63.8474 314.607 62.5203 314.607 60.8831C314.607 59.246 313.281 57.9188 311.645 57.9188C310.01 57.9188 308.684 59.246 308.684 60.8831C308.684 62.5203 310.01 63.8474 311.645 63.8474Z" fill={ color }/>
  <path d="M305.723 63.8474C304.129 63.8474 302.762 62.4793 302.762 60.8831C302.762 59.287 304.129 57.9188 305.723 57.9188C307.318 57.9188 308.685 59.287 308.685 60.8831C308.685 62.4793 307.318 63.8474 305.723 63.8474Z" fill={ color }/>
  <path d="M299.571 63.8474C297.976 63.8474 296.609 62.4793 296.609 60.8831C296.609 59.287 297.976 57.9188 299.571 57.9188C301.166 57.9188 302.532 59.287 302.532 60.8831C302.532 62.4793 301.393 63.8474 299.571 63.8474Z" fill={ color }/>
  <path d="M293.649 63.8474C292.054 63.8474 290.688 62.4793 290.688 60.8831C290.688 59.287 292.054 57.9188 293.649 57.9188C295.244 57.9188 296.611 59.287 296.611 60.8831C296.611 62.4793 295.244 63.8474 293.649 63.8474Z" fill={ color }/>
  <path d="M287.727 63.8474C286.132 63.8474 284.766 62.4793 284.766 60.8831C284.766 59.287 286.132 57.9188 287.727 57.9188C289.322 57.9188 290.689 59.287 290.689 60.8831C290.689 62.4793 289.322 63.8474 287.727 63.8474Z" fill={ color }/>
  <path d="M281.801 63.8474C283.437 63.8474 284.763 62.5203 284.763 60.8831C284.763 59.246 283.437 57.9188 281.801 57.9188C280.166 57.9188 278.84 59.246 278.84 60.8831C278.84 62.5203 280.166 63.8474 281.801 63.8474Z" fill={ color }/>
  <path d="M275.649 63.8474C277.285 63.8474 278.611 62.5203 278.611 60.8831C278.611 59.246 277.285 57.9188 275.649 57.9188C274.013 57.9188 272.688 59.246 272.688 60.8831C272.688 62.5203 274.013 63.8474 275.649 63.8474Z" fill={ color }/>
  <path d="M269.727 63.8474C271.363 63.8474 272.689 62.5203 272.689 60.8831C272.689 59.246 271.363 57.9188 269.727 57.9188C268.092 57.9188 266.766 59.246 266.766 60.8831C266.766 62.5203 268.092 63.8474 269.727 63.8474Z" fill={ color }/>
  <path d="M263.805 63.6187C262.211 63.6187 260.844 62.2506 260.844 60.6544C260.844 59.0582 262.211 57.6901 263.805 57.6901C265.4 57.6901 266.767 59.0582 266.767 60.6544C266.539 62.4786 265.4 63.6187 263.805 63.6187Z" fill={ color }/>
  <path d="M257.656 63.6178C259.165 63.6178 260.389 62.3928 260.389 60.8815C260.389 59.3703 259.165 58.1453 257.656 58.1453C256.146 58.1453 254.922 59.3703 254.922 60.8815C254.922 62.3928 256.146 63.6178 257.656 63.6178Z" fill={ color }/>
  <path d="M251.73 63.6178C250.135 63.6178 248.996 62.2497 248.996 60.8815C248.996 59.5134 250.363 58.1453 251.73 58.1453C253.325 58.1453 254.464 59.5134 254.464 60.8815C254.464 62.2497 253.325 63.6178 251.73 63.6178Z" fill={ color }/>
  <path d="M245.808 63.6178C247.318 63.6178 248.542 62.3928 248.542 60.8815C248.542 59.3703 247.318 58.1453 245.808 58.1453C244.298 58.1453 243.074 59.3703 243.074 60.8815C243.074 62.3928 244.298 63.6178 245.808 63.6178Z" fill={ color }/>
  <path d="M239.656 63.6178C238.061 63.6178 236.922 62.4777 236.922 60.8815C236.922 59.2854 238.061 58.1453 239.656 58.1453C241.25 58.1453 242.389 59.2854 242.389 60.8815C242.389 62.4777 241.25 63.6178 239.656 63.6178Z" fill={ color }/>
  <path d="M233.734 63.6178C235.244 63.6178 236.467 62.3928 236.467 60.8815C236.467 59.3703 235.244 58.1453 233.734 58.1453C232.224 58.1453 231 59.3703 231 60.8815C231 62.3928 232.224 63.6178 233.734 63.6178Z" fill={ color }/>
  <path d="M227.812 63.3914C226.217 63.3914 225.078 62.2513 225.078 60.6551C225.078 59.059 226.217 57.9188 227.812 57.9188C229.407 57.9188 230.546 59.059 230.546 60.6551C230.546 62.2513 229.179 63.3914 227.812 63.3914Z" fill={ color }/>
  <path d="M221.886 63.3914C220.519 63.3914 219.152 62.2513 219.152 60.6551C219.152 59.287 220.291 57.9188 221.886 57.9188C223.253 57.9188 224.62 59.059 224.62 60.6551C224.392 62.2513 223.253 63.3914 221.886 63.3914Z" fill={ color }/>
  <path d="M215.736 63.3905C214.37 63.3905 213.23 62.2504 213.23 60.8823C213.23 59.5141 214.37 58.374 215.736 58.374C217.103 58.374 218.242 59.5141 218.242 60.8823C218.242 62.2504 217.331 63.3905 215.736 63.3905Z" fill={ color }/>
  <path d="M209.815 63.3905C211.198 63.3905 212.32 62.2675 212.32 60.8823C212.32 59.497 211.198 58.374 209.815 58.374C208.431 58.374 207.309 59.497 207.309 60.8823C207.309 62.2675 208.431 63.3905 209.815 63.3905Z" fill={ color }/>
  <path d="M203.889 63.3905C202.522 63.3905 201.383 62.2504 201.383 60.8823C201.383 59.5141 202.522 58.374 203.889 58.374C205.256 58.374 206.395 59.5141 206.395 60.8823C206.395 62.2504 205.256 63.3905 203.889 63.3905Z" fill={ color }/>
  <path d="M197.74 63.3905C196.373 63.3905 195.234 62.2504 195.234 60.8823C195.234 59.5141 196.373 58.374 197.74 58.374C199.107 58.374 200.246 59.5141 200.246 60.8823C200.246 62.2504 199.107 63.3905 197.74 63.3905Z" fill={ color }/>
  <path d="M191.818 63.3905C193.202 63.3905 194.324 62.2675 194.324 60.8823C194.324 59.497 193.202 58.374 191.818 58.374C190.434 58.374 189.312 59.497 189.312 60.8823C189.312 62.2675 190.434 63.3905 191.818 63.3905Z" fill={ color }/>
  <path d="M185.897 63.3905C187.28 63.3905 188.402 62.2675 188.402 60.8823C188.402 59.497 187.28 58.374 185.897 58.374C184.513 58.374 183.391 59.497 183.391 60.8823C183.391 62.2675 184.513 63.3905 185.897 63.3905Z" fill={ color }/>
  <path d="M179.743 63.1621C178.376 63.1621 177.465 62.0219 177.465 60.8818C177.465 59.5137 178.604 58.6016 179.743 58.6016C181.11 58.6016 182.021 59.7417 182.021 60.8818C182.249 62.0219 181.11 63.1621 179.743 63.1621Z" fill={ color }/>
  <path d="M173.821 63.1621C172.454 63.1621 171.543 62.0219 171.543 60.8818C171.543 59.5137 172.682 58.6016 173.821 58.6016C175.188 58.6016 176.099 59.7417 176.099 60.8818C176.099 62.0219 175.188 63.1621 173.821 63.1621Z" fill={ color }/>
  <path d="M167.895 63.1621C169.153 63.1621 170.173 62.1412 170.173 60.8818C170.173 59.6225 169.153 58.6016 167.895 58.6016C166.637 58.6016 165.617 59.6225 165.617 60.8818C165.617 62.1412 166.637 63.1621 167.895 63.1621Z" fill={ color }/>
  <path d="M161.973 63.1621C163.232 63.1621 164.252 62.1412 164.252 60.8818C164.252 59.6225 163.232 58.6016 161.973 58.6016C160.715 58.6016 159.695 59.6225 159.695 60.8818C159.695 62.1412 160.715 63.1621 161.973 63.1621Z" fill={ color }/>
  <path d="M155.824 62.9348C154.685 62.9348 153.773 62.0227 153.773 60.8826C153.773 59.7425 154.685 58.8304 155.824 58.8304C156.963 58.8304 157.874 59.7425 157.874 60.8826C158.102 62.0227 157.191 62.9348 155.824 62.9348Z" fill={ color }/>
  <path d="M149.898 62.9348C148.759 62.9348 147.848 62.0227 147.848 60.8826C147.848 59.7425 148.759 58.8304 149.898 58.8304C151.037 58.8304 151.948 59.7425 151.948 60.8826C151.948 62.0227 151.037 62.9348 149.898 62.9348Z" fill={ color }/>
  <path d="M143.976 62.9348C142.837 62.9348 141.926 62.0227 141.926 60.8826C141.926 59.7425 142.837 58.8304 143.976 58.8304C145.115 58.8304 146.026 59.7425 146.026 60.8826C146.026 62.0227 145.115 62.9348 143.976 62.9348Z" fill={ color }/>
  <path d="M137.828 62.706C136.689 62.706 135.777 61.7939 135.777 60.6538C135.777 59.5137 136.689 58.6016 137.828 58.6016C138.967 58.6016 139.878 59.5137 139.878 60.6538C139.878 61.7939 138.967 62.706 137.828 62.706Z" fill={ color }/>
  <path d="M131.905 62.7075C130.765 62.7075 130.082 61.7954 130.082 60.8833C130.082 59.7432 130.993 59.0591 131.905 59.0591C133.044 59.0591 133.727 59.9712 133.727 60.8833C133.727 61.7954 133.044 62.7075 131.905 62.7075Z" fill={ color }/>
  <path d="M125.979 62.7075C126.985 62.7075 127.801 61.8908 127.801 60.8833C127.801 59.8758 126.985 59.0591 125.979 59.0591C124.972 59.0591 124.156 59.8758 124.156 60.8833C124.156 61.8908 124.972 62.7075 125.979 62.7075Z" fill={ color }/>
  <path d="M119.826 62.7075C120.833 62.7075 121.649 61.8908 121.649 60.8833C121.649 59.8758 120.833 59.0591 119.826 59.0591C118.82 59.0591 118.004 59.8758 118.004 60.8833C118.004 61.8908 118.82 62.7075 119.826 62.7075Z" fill={ color }/>
  <path d="M113.905 62.4787C112.993 62.4787 112.082 61.7947 112.082 60.6545C112.082 59.7425 112.765 58.8304 113.905 58.8304C114.816 58.8304 115.727 59.5144 115.727 60.6545C115.727 61.7947 114.816 62.4787 113.905 62.4787Z" fill={ color }/>
  <path d="M107.981 62.4779C108.862 62.4779 109.576 61.7632 109.576 60.8817C109.576 60.0002 108.862 59.2855 107.981 59.2855C107.101 59.2855 106.387 60.0002 106.387 60.8817C106.387 61.7632 107.101 62.4779 107.981 62.4779Z" fill={ color }/>
  <path d="M102.06 62.4779C102.94 62.4779 103.654 61.7632 103.654 60.8817C103.654 60.0002 102.94 59.2855 102.06 59.2855C101.179 59.2855 100.465 60.0002 100.465 60.8817C100.465 61.7632 101.179 62.4779 102.06 62.4779Z" fill={ color }/>
  <path d="M95.9072 62.4779C96.7879 62.4779 97.5018 61.7632 97.5018 60.8817C97.5018 60.0002 96.7879 59.2855 95.9072 59.2855C95.0265 59.2855 94.3125 60.0002 94.3125 60.8817C94.3125 61.7632 95.0265 62.4779 95.9072 62.4779Z" fill={ color }/>
  <path d="M89.984 62.2506C89.0728 62.2506 88.6172 61.5665 88.6172 60.8824C88.6172 60.1984 89.3006 59.5143 89.984 59.5143C90.6675 59.5143 91.3509 60.1984 91.3509 60.8824C91.3509 61.5665 90.8953 62.2506 89.984 62.2506Z" fill={ color }/>
  <path d="M84.0622 62.2506C84.8171 62.2506 85.429 61.638 85.429 60.8824C85.429 60.1268 84.8171 59.5143 84.0622 59.5143C83.3073 59.5143 82.6953 60.1268 82.6953 60.8824C82.6953 61.638 83.3073 62.2506 84.0622 62.2506Z" fill={ color }/>
  <path d="M77.9098 62.0218C77.2264 62.0218 76.543 61.3377 76.543 60.6537C76.543 59.9696 77.2264 59.2855 77.9098 59.2855C78.5933 59.2855 79.2767 59.9696 79.2767 60.6537C79.2767 61.5658 78.8211 62.0218 77.9098 62.0218Z" fill={ color }/>
  <path d="M71.9906 62.0221C71.3072 62.0221 70.8516 61.5661 70.8516 60.882C70.8516 60.1979 71.3072 59.7419 71.9906 59.7419C72.674 59.7419 73.1297 60.1979 73.1297 60.882C73.1297 61.5661 72.674 62.0221 71.9906 62.0221Z" fill={ color }/>
  <path d="M66.0648 62.0221C65.3814 62.0221 64.9258 61.5661 64.9258 60.882C64.9258 60.1979 65.3814 59.7419 66.0648 59.7419C66.7483 59.7419 67.2039 60.1979 67.2039 60.882C67.2039 61.338 66.7483 62.0221 66.0648 62.0221Z" fill={ color }/>
  <path d="M59.9125 61.7945C59.2291 61.7945 58.7734 61.3385 58.7734 60.6544C58.7734 59.9703 59.2291 59.5143 59.9125 59.5143C60.5959 59.5143 61.0515 59.9703 61.0515 60.6544C61.0515 61.3385 60.5959 61.7945 59.9125 61.7945Z" fill={ color }/>
  <path d="M53.9894 61.7948C53.5337 61.7948 53.0781 61.3388 53.0781 60.8827C53.0781 60.4267 53.5337 59.9706 53.9894 59.9706C54.445 59.9706 54.9006 60.4267 54.9006 60.8827C55.1284 61.3388 54.6728 61.7948 53.9894 61.7948Z" fill={ color }/>
  <path d="M48.0675 61.7948C47.6119 61.7948 47.1562 61.3388 47.1562 60.8827C47.1562 60.4267 47.6119 59.9706 48.0675 59.9706C48.5231 59.9706 48.9787 60.4267 48.9787 60.8827C48.9787 61.3388 48.5231 61.7948 48.0675 61.7948Z" fill={ color }/>
  <path d="M42.1456 61.5661C41.69 61.5661 41.2344 61.11 41.2344 60.654C41.2344 60.1979 41.69 59.7419 42.1456 59.7419C42.6012 59.7419 43.0569 60.1979 43.0569 60.654C42.8291 61.338 42.6012 61.5661 42.1456 61.5661Z" fill={ color }/>
  <path d="M35.9959 61.5663C35.5403 61.5663 35.3125 61.3383 35.3125 60.8823C35.3125 60.4262 35.5403 60.1982 35.9959 60.1982C36.4516 60.1982 36.6794 60.4262 36.6794 60.8823C36.6794 61.3383 36.4516 61.5663 35.9959 61.5663Z" fill={ color }/>
  <path d="M30.0702 61.3388C29.6145 61.3388 29.3867 61.1107 29.3867 60.6547C29.3867 60.1986 29.6145 59.9706 30.0702 59.9706C30.5258 59.9706 30.7536 60.1986 30.7536 60.6547C30.7536 61.1107 30.5258 61.3388 30.0702 61.3388Z" fill={ color }/>
  <path d="M24.147 61.3379C24.3987 61.3379 24.6027 61.1337 24.6027 60.8818C24.6027 60.63 24.3987 60.4258 24.147 60.4258C23.8954 60.4258 23.6914 60.63 23.6914 60.8818C23.6914 61.1337 23.8954 61.3379 24.147 61.3379Z" fill={ color }/>
  <path d="M17.9986 61.3379C17.7708 61.3379 17.543 61.1099 17.543 60.8818C17.543 60.6538 17.7708 60.4258 17.9986 60.4258C18.2264 60.4258 18.4542 60.6538 18.4542 60.8818C18.4542 61.1099 18.2264 61.3379 17.9986 61.3379Z" fill={ color }/>
  <path d="M12.0728 61.3379C12.3244 61.3379 12.5284 61.1337 12.5284 60.8818C12.5284 60.63 12.3244 60.4258 12.0728 60.4258C11.8212 60.4258 11.6172 60.63 11.6172 60.8818C11.6172 61.1337 11.8212 61.3379 12.0728 61.3379Z" fill={ color }/>
  <path d="M6.14969 61.1106C5.92187 61.1106 5.92188 60.8826 5.92188 60.8826C5.92188 60.6545 6.14969 60.6545 6.14969 60.6545C6.3775 60.6545 6.3775 60.8826 6.3775 60.8826L6.14969 61.1106Z" fill={ color }/>
  <path d="M0.225545 60.8818C-0.00226515 60.8818 -0.00226515 60.8818 0.225545 60.8818C-0.00226515 60.6538 0.225545 60.4258 0.225545 60.4258C0.225545 60.6538 0.225545 60.6538 0.225545 60.8818Z" fill={ color }/>
  <path d="M356.525 67.0393C356.297 67.0393 356.297 66.8113 356.297 66.8113C356.297 66.5832 356.525 66.5832 356.525 66.5832C356.752 66.5832 356.752 66.8113 356.752 66.8113C356.752 67.0393 356.752 67.0393 356.525 67.0393Z" fill={ color }/>
  <path d="M350.6 67.9509C349.917 67.9509 349.461 67.4948 349.461 66.8108C349.461 66.1267 349.917 65.6707 350.6 65.6707C351.283 65.6707 351.739 66.1267 351.739 66.8108C351.739 67.4948 351.283 67.9509 350.6 67.9509Z" fill={ color }/>
  <path d="M344.679 68.8636C343.54 68.8636 342.629 67.9515 342.629 66.8114C342.629 65.6712 343.54 64.7592 344.679 64.7592C345.818 64.7592 346.73 65.6712 346.73 66.8114C346.502 67.9515 345.59 68.8636 344.679 68.8636Z" fill={ color }/>
  <path d="M338.525 69.3193C339.909 69.3193 341.031 68.1963 341.031 66.8111C341.031 65.4258 339.909 64.3028 338.525 64.3028C337.141 64.3028 336.02 65.4258 336.02 66.8111C336.02 68.1963 337.141 69.3193 338.525 69.3193Z" fill={ color }/>
  <path d="M332.605 69.5466C331.01 69.5466 329.871 68.4065 329.871 66.8103C329.871 65.2142 331.01 64.0741 332.605 64.0741C334.2 64.0741 335.339 65.2142 335.339 66.8103C335.339 68.4065 334.2 69.5466 332.605 69.5466Z" fill={ color }/>
  <path d="M326.68 69.5475C325.086 69.5475 323.719 68.1794 323.719 66.5832C323.719 64.987 325.086 63.6189 326.68 63.6189C328.275 63.6189 329.642 64.987 329.642 66.5832C329.414 68.4074 328.275 69.5475 326.68 69.5475Z" fill={ color }/>
  <path d="M320.758 69.7751C319.164 69.7751 317.797 68.4069 317.797 66.8108C317.797 65.2146 319.164 63.8465 320.758 63.8465C322.353 63.8465 323.72 65.2146 323.72 66.8108C323.492 68.4069 322.353 69.7751 320.758 69.7751Z" fill={ color }/>
  <path d="M314.606 69.7751C313.011 69.7751 311.645 68.4069 311.645 66.8108C311.645 65.2146 313.011 63.8465 314.606 63.8465C316.201 63.8465 317.568 65.2146 317.568 66.8108C317.568 68.4069 316.201 69.7751 314.606 69.7751Z" fill={ color }/>
  <path d="M308.684 69.7751C307.09 69.7751 305.723 68.4069 305.723 66.8108C305.723 65.2146 307.09 63.8465 308.684 63.8465C310.279 63.8465 311.646 65.2146 311.646 66.8108C311.646 68.4069 310.279 69.7751 308.684 69.7751Z" fill={ color }/>
  <path d="M302.758 69.7751C301.164 69.7751 299.797 68.4069 299.797 66.8108C299.797 65.2146 301.164 63.8465 302.758 63.8465C304.353 63.8465 305.72 65.2146 305.72 66.8108C305.492 68.4069 304.353 69.7751 302.758 69.7751Z" fill={ color }/>
  <path d="M296.61 69.7751C295.015 69.7751 293.648 68.4069 293.648 66.8108C293.648 65.2146 295.015 63.8465 296.61 63.8465C298.205 63.8465 299.572 65.2146 299.572 66.8108C299.572 68.4069 298.205 69.7751 296.61 69.7751Z" fill={ color }/>
  <path d="M290.688 69.7751C289.093 69.7751 287.727 68.4069 287.727 66.8108C287.727 65.2146 289.093 63.8465 290.688 63.8465C292.283 63.8465 293.65 65.2146 293.65 66.8108C293.65 68.4069 292.283 69.7751 290.688 69.7751Z" fill={ color }/>
  <path d="M284.762 69.7751C283.168 69.7751 281.801 68.4069 281.801 66.8108C281.801 65.2146 283.168 63.8465 284.762 63.8465C286.357 63.8465 287.724 65.2146 287.724 66.8108C287.496 68.4069 286.357 69.7751 284.762 69.7751Z" fill={ color }/>
  <path d="M278.614 69.7751C277.019 69.7751 275.652 68.4069 275.652 66.8108C275.652 65.2146 277.019 63.8465 278.614 63.8465C280.209 63.8465 281.575 65.2146 281.575 66.8108C281.575 68.4069 280.209 69.7751 278.614 69.7751Z" fill={ color }/>
  <path d="M272.691 69.5466C271.096 69.5466 269.957 68.1785 269.957 66.8103C269.957 65.2142 271.324 64.0741 272.691 64.0741C274.285 64.0741 275.424 65.4422 275.424 66.8103C275.652 68.4065 274.285 69.5466 272.691 69.5466Z" fill={ color }/>
  <path d="M266.765 69.5466C265.17 69.5466 264.031 68.1785 264.031 66.8103C264.031 65.2142 265.398 64.0741 266.765 64.0741C268.132 64.0741 269.499 65.4422 269.499 66.8103C269.499 68.4065 268.36 69.5466 266.765 69.5466Z" fill={ color }/>
  <path d="M260.843 69.5466C259.248 69.5466 258.109 68.1785 258.109 66.8103C258.109 65.2142 259.476 64.0741 260.843 64.0741C262.438 64.0741 263.577 65.4422 263.577 66.8103C263.577 68.4065 262.21 69.5466 260.843 69.5466Z" fill={ color }/>
  <path d="M254.695 69.5466C253.1 69.5466 251.961 68.4065 251.961 66.8103C251.961 65.2142 253.1 64.0741 254.695 64.0741C256.289 64.0741 257.428 65.2142 257.428 66.8103C257.428 68.4065 256.289 69.5466 254.695 69.5466Z" fill={ color }/>
  <path d="M248.769 69.5466C247.174 69.5466 246.035 68.4065 246.035 66.8103C246.035 65.2142 247.174 64.0741 248.769 64.0741C250.364 64.0741 251.503 65.2142 251.503 66.8103C251.503 68.4065 250.364 69.5466 248.769 69.5466Z" fill={ color }/>
  <path d="M242.847 69.5466C241.252 69.5466 240.113 68.4065 240.113 66.8103C240.113 65.2142 241.252 64.0741 242.847 64.0741C244.442 64.0741 245.581 65.2142 245.581 66.8103C245.581 68.4065 244.214 69.5466 242.847 69.5466Z" fill={ color }/>
  <path d="M236.695 69.5466C235.1 69.5466 233.961 68.4065 233.961 66.8103C233.961 65.2142 235.1 64.0741 236.695 64.0741C238.289 64.0741 239.428 65.2142 239.428 66.8103C239.428 68.1785 238.289 69.5466 236.695 69.5466Z" fill={ color }/>
  <path d="M230.773 69.5466C229.406 69.5466 228.039 68.4065 228.039 66.8103C228.039 65.4422 229.178 64.0741 230.773 64.0741C232.14 64.0741 233.507 65.2142 233.507 66.8103C233.507 68.1785 232.14 69.5466 230.773 69.5466Z" fill={ color }/>
  <path d="M224.85 69.3193C223.483 69.3193 222.344 68.1792 222.344 66.8111C222.344 65.4429 223.483 64.3028 224.85 64.3028C226.217 64.3028 227.356 65.4429 227.356 66.8111C227.356 68.1792 226.217 69.3193 224.85 69.3193Z" fill={ color }/>
  <path d="M218.697 69.3193C217.33 69.3193 216.191 68.1792 216.191 66.8111C216.191 65.4429 217.33 64.3028 218.697 64.3028C220.064 64.3028 221.203 65.4429 221.203 66.8111C221.203 68.1792 220.292 69.3193 218.697 69.3193Z" fill={ color }/>
  <path d="M212.775 69.3193C211.409 69.3193 210.27 68.1792 210.27 66.8111C210.27 65.4429 211.409 64.3028 212.775 64.3028C214.142 64.3028 215.281 65.4429 215.281 66.8111C215.281 68.1792 214.142 69.3193 212.775 69.3193Z" fill={ color }/>
  <path d="M206.854 69.3193C205.487 69.3193 204.348 68.1792 204.348 66.8111C204.348 65.4429 205.487 64.3028 206.854 64.3028C208.22 64.3028 209.359 65.4429 209.359 66.8111C209.359 68.1792 208.22 69.3193 206.854 69.3193Z" fill={ color }/>
  <path d="M200.928 69.3193C199.561 69.3193 198.422 68.1792 198.422 66.8111C198.422 65.4429 199.561 64.3028 200.928 64.3028C202.295 64.3028 203.434 65.4429 203.434 66.8111C203.206 68.1792 202.067 69.3193 200.928 69.3193Z" fill={ color }/>
  <path d="M194.779 69.3193C193.413 69.3193 192.273 68.1792 192.273 66.8111C192.273 65.4429 193.413 64.3028 194.779 64.3028C196.146 64.3028 197.285 65.4429 197.285 66.8111C197.285 68.1792 196.146 69.3193 194.779 69.3193Z" fill={ color }/>
  <path d="M188.856 69.0909C187.489 69.0909 186.578 67.9507 186.578 66.8106C186.578 65.4425 187.717 64.5304 188.856 64.5304C189.995 64.5304 191.134 65.6705 191.134 66.8106C191.134 68.1788 190.223 69.0909 188.856 69.0909Z" fill={ color }/>
  <path d="M182.93 69.0909C181.564 69.0909 180.652 67.9507 180.652 66.8106C180.652 65.4425 181.791 64.5304 182.93 64.5304C184.297 64.5304 185.209 65.6705 185.209 66.8106C185.209 68.1788 184.07 69.0909 182.93 69.0909Z" fill={ color }/>
  <path d="M176.782 69.0909C175.415 69.0909 174.504 68.1788 174.504 66.8106C174.504 65.4425 175.415 64.5304 176.782 64.5304C178.149 64.5304 179.06 65.4425 179.06 66.8106C179.06 67.9507 178.149 69.0909 176.782 69.0909Z" fill={ color }/>
  <path d="M170.86 69.0909C169.721 69.0909 168.582 68.1788 168.582 66.8106C168.582 65.6705 169.493 64.5304 170.86 64.5304C171.999 64.5304 173.138 65.4425 173.138 66.8106C173.138 67.9507 171.999 69.0909 170.86 69.0909Z" fill={ color }/>
  <path d="M164.934 69.0909C163.795 69.0909 162.656 68.1788 162.656 66.8106C162.656 65.6705 163.567 64.5304 164.934 64.5304C166.073 64.5304 167.212 65.4425 167.212 66.8106C166.985 67.9507 166.073 69.0909 164.934 69.0909Z" fill={ color }/>
  <path d="M158.785 68.8636C157.646 68.8636 156.734 67.9515 156.734 66.8114C156.734 65.6712 157.646 64.7592 158.785 64.7592C159.924 64.7592 160.835 65.6712 160.835 66.8114C161.063 67.9515 160.152 68.8636 158.785 68.8636Z" fill={ color }/>
  <path d="M152.863 68.8636C153.995 68.8636 154.913 67.9448 154.913 66.8114C154.913 65.678 153.995 64.7592 152.863 64.7592C151.73 64.7592 150.812 65.678 150.812 66.8114C150.812 67.9448 151.73 68.8636 152.863 68.8636Z" fill={ color }/>
  <path d="M146.937 68.8636C145.798 68.8636 144.887 67.9515 144.887 66.8114C144.887 65.6712 145.798 64.7592 146.937 64.7592C148.076 64.7592 148.987 65.6712 148.987 66.8114C148.987 67.9515 148.076 68.8636 146.937 68.8636Z" fill={ color }/>
  <path d="M141.015 68.8636C142.148 68.8636 143.065 67.9448 143.065 66.8114C143.065 65.678 142.148 64.7592 141.015 64.7592C139.883 64.7592 138.965 65.678 138.965 66.8114C138.965 67.9448 139.883 68.8636 141.015 68.8636Z" fill={ color }/>
  <path d="M134.865 68.6351C133.726 68.6351 133.043 67.723 133.043 66.8109C133.043 65.6708 133.954 64.9867 134.865 64.9867C136.005 64.9867 136.688 65.8988 136.688 66.8109C136.916 67.951 136.005 68.6351 134.865 68.6351Z" fill={ color }/>
  <path d="M128.944 68.6351C128.032 68.6351 127.121 67.723 127.121 66.8109C127.121 65.8988 128.032 64.9867 128.944 64.9867C129.855 64.9867 130.766 65.8988 130.766 66.8109C130.766 67.723 129.855 68.6351 128.944 68.6351Z" fill={ color }/>
  <path d="M123.018 68.6351C122.107 68.6351 121.195 67.951 121.195 66.8109C121.195 65.8988 121.879 64.9867 123.018 64.9867C123.929 64.9867 124.84 65.6708 124.84 66.8109C124.612 67.723 123.929 68.6351 123.018 68.6351Z" fill={ color }/>
  <path d="M116.868 68.4067C115.957 68.4067 115.273 67.7226 115.273 66.8105C115.273 65.8984 115.957 65.2143 116.868 65.2143C117.779 65.2143 118.463 65.8984 118.463 66.8105C118.691 67.7226 117.779 68.4067 116.868 68.4067Z" fill={ color }/>
  <path d="M110.942 68.4067C110.031 68.4067 109.348 67.7226 109.348 66.8105C109.348 65.8984 110.031 65.2143 110.942 65.2143C111.854 65.2143 112.537 65.8984 112.537 66.8105C112.537 67.7226 111.854 68.4067 110.942 68.4067Z" fill={ color }/>
  <path d="M105.02 68.4067C104.109 68.4067 103.426 67.7226 103.426 66.8105C103.426 65.8984 104.109 65.2143 105.02 65.2143C105.932 65.2143 106.615 65.8984 106.615 66.8105C106.615 67.7226 105.932 68.4067 105.02 68.4067Z" fill={ color }/>
  <path d="M98.8681 68.1791C97.9569 68.1791 97.2734 67.495 97.2734 66.5829C97.2734 65.6708 97.9569 64.9867 98.8681 64.9867C99.7794 64.9867 100.463 65.6708 100.463 66.5829C100.463 67.723 99.7794 68.1791 98.8681 68.1791Z" fill={ color }/>
  <path d="M92.9489 68.1794C92.2655 68.1794 91.582 67.4953 91.582 66.8112C91.582 66.1271 92.2655 65.4431 92.9489 65.4431C93.6323 65.4431 94.3158 66.1271 94.3158 66.8112C94.3158 67.4953 93.8601 68.1794 92.9489 68.1794Z" fill={ color }/>
  <path d="M87.0231 68.1794C86.3397 68.1794 85.6562 67.4953 85.6562 66.8112C85.6562 66.1271 86.3397 65.4431 87.0231 65.4431C87.7065 65.4431 88.39 66.1271 88.39 66.8112C88.39 67.4953 87.7065 68.1794 87.0231 68.1794Z" fill={ color }/>
  <path d="M81.1012 67.9506C80.4178 67.9506 79.7344 67.4946 79.7344 66.5825C79.7344 65.8984 80.19 65.2143 81.1012 65.2143C81.7847 65.2143 82.4681 65.6704 82.4681 66.5825C82.2403 67.4946 81.7847 67.9506 81.1012 67.9506Z" fill={ color }/>
  <path d="M74.9516 67.9509C74.2681 67.9509 73.8125 67.4948 73.8125 66.8108C73.8125 66.1267 74.2681 65.6707 74.9516 65.6707C75.635 65.6707 76.0906 66.1267 76.0906 66.8108C76.0906 67.4948 75.635 67.9509 74.9516 67.9509Z" fill={ color }/>
  <path d="M69.0258 67.9509C68.3423 67.9509 67.8867 67.4948 67.8867 66.8108C67.8867 66.1267 68.3423 65.6707 69.0258 65.6707C69.7092 65.6707 70.1648 66.1267 70.1648 66.8108C70.1648 67.4948 69.7092 67.9509 69.0258 67.9509Z" fill={ color }/>
  <path d="M63.1027 67.7235C62.647 67.7235 62.1914 67.2674 62.1914 66.8114C62.1914 66.3554 62.647 65.8993 63.1027 65.8993C63.5583 65.8993 64.0139 66.3554 64.0139 66.8114C64.0139 67.2674 63.5583 67.7235 63.1027 67.7235Z" fill={ color }/>
  <path d="M56.9542 67.7235C56.4986 67.7235 56.043 67.2674 56.043 66.8114C56.043 66.3554 56.4986 65.8993 56.9542 65.8993C57.4098 65.8993 57.8655 66.3554 57.8655 66.8114C57.8655 67.2674 57.6376 67.7235 56.9542 67.7235Z" fill={ color }/>
  <path d="M51.0284 67.7235C50.5728 67.7235 50.1172 67.2674 50.1172 66.8114C50.1172 66.3554 50.5728 65.8993 51.0284 65.8993C51.4841 65.8993 51.9397 66.3554 51.9397 66.8114C51.9397 67.2674 51.4841 67.7235 51.0284 67.7235Z" fill={ color }/>
  <path d="M45.1053 67.4951C44.6497 67.4951 44.4219 67.0391 44.4219 66.8111C44.4219 66.355 44.8775 66.127 45.1053 66.127C45.5609 66.127 45.7887 66.583 45.7887 66.8111C45.7887 67.2671 45.5609 67.4951 45.1053 67.4951Z" fill={ color }/>
  <path d="M38.9569 67.4951C38.5012 67.4951 38.2734 67.2671 38.2734 66.8111C38.2734 66.355 38.5012 66.127 38.9569 66.127C39.4125 66.127 39.6403 66.355 39.6403 66.8111C39.6403 67.2671 39.4125 67.4951 38.9569 67.4951Z" fill={ color }/>
  <path d="M33.0311 67.4951C32.8033 67.4951 32.3477 67.2671 32.3477 66.8111C32.3477 66.355 32.5755 66.127 33.0311 66.127C33.2589 66.127 33.7145 66.355 33.7145 66.8111C33.7145 67.2671 33.4867 67.4951 33.0311 67.4951Z" fill={ color }/>
  <path d="M27.1119 67.2667C26.8841 67.2667 26.6562 67.0387 26.6562 66.8106C26.6562 66.5826 26.8841 66.3546 27.1119 66.3546C27.3397 66.3546 27.5675 66.5826 27.5675 66.8106C27.5675 67.0387 27.3397 67.2667 27.1119 67.2667Z" fill={ color }/>
  <path d="M21.1861 67.2667C20.9583 67.2667 20.7305 67.0387 20.7305 66.8106C20.7305 66.5826 20.9583 66.3546 21.1861 66.3546C21.4139 66.3546 21.6417 66.5826 21.6417 66.8106C21.4139 67.0387 21.4139 67.2667 21.1861 67.2667Z" fill={ color }/>
  <path d="M15.0364 67.0393C14.8086 67.0393 14.8086 66.8113 14.8086 66.8113C14.8086 66.5832 15.0364 66.5832 15.0364 66.5832C15.2642 66.5832 15.2642 66.8113 15.2642 66.8113C15.492 67.0393 15.2642 67.0393 15.0364 67.0393Z" fill={ color }/>
  <path d="M9.11226 67.0393C8.88445 67.0393 8.88445 66.8113 9.11226 67.0393C8.88445 66.5832 8.88445 66.5832 9.11226 66.5832C9.11226 66.5832 9.34007 66.5832 9.11226 67.0393C9.34007 66.8113 9.11226 67.0393 9.11226 67.0393Z" fill={ color }/>
  <path d="M3.19039 67.0387C2.96258 67.0387 2.96258 66.8107 3.19039 67.0387C2.96258 66.8107 3.19039 66.8107 3.19039 66.8107C3.19039 66.5826 3.19039 66.8107 3.19039 67.0387C3.19039 66.8107 3.19039 67.0387 3.19039 67.0387Z" fill={ color }/>
  <path d="M347.64 73.4238C348.018 73.4238 348.324 73.1176 348.324 72.7398C348.324 72.362 348.018 72.0557 347.64 72.0557C347.263 72.0557 346.957 72.362 346.957 72.7398C346.957 73.1176 347.263 73.4238 347.64 73.4238Z" fill={ color }/>
  <path d="M341.715 74.1082C342.469 74.1082 343.081 73.4956 343.081 72.74C343.081 71.9844 342.469 71.3719 341.715 71.3719C340.96 71.3719 340.348 71.9844 340.348 72.74C340.348 73.4956 340.96 74.1082 341.715 74.1082Z" fill={ color }/>
  <path d="M335.565 74.7915C334.426 74.7915 333.742 73.8794 333.742 72.9673C333.742 72.0552 334.653 71.1431 335.565 71.1431C336.704 71.1431 337.387 72.0552 337.387 72.9673C337.387 73.8794 336.704 74.7915 335.565 74.7915Z" fill={ color }/>
  <path d="M329.641 75.0197C330.9 75.0197 331.919 73.9988 331.919 72.7394C331.919 71.4801 330.9 70.4592 329.641 70.4592C328.383 70.4592 327.363 71.4801 327.363 72.7394C327.363 73.9988 328.383 75.0197 329.641 75.0197Z" fill={ color }/>
  <path d="M323.721 75.2481C325.105 75.2481 326.227 74.1251 326.227 72.7399C326.227 71.3546 325.105 70.2316 323.721 70.2316C322.337 70.2316 321.215 71.3546 321.215 72.7399C321.215 74.1251 322.337 75.2481 323.721 75.2481Z" fill={ color }/>
  <path d="M317.57 75.4754C316.203 75.4754 314.836 74.3353 314.836 72.7391C314.836 71.371 315.975 70.0029 317.57 70.0029C318.937 70.0029 320.303 71.143 320.303 72.7391C320.303 74.3353 319.164 75.4754 317.57 75.4754Z" fill={ color }/>
  <path d="M311.644 75.4754C310.049 75.4754 308.91 74.3353 308.91 72.7391C308.91 71.143 310.049 70.0029 311.644 70.0029C313.239 70.0029 314.378 71.143 314.378 72.7391C314.378 74.3353 313.239 75.4754 311.644 75.4754Z" fill={ color }/>
  <path d="M305.722 75.4754C304.127 75.4754 302.988 74.3353 302.988 72.7391C302.988 71.143 304.127 70.0029 305.722 70.0029C307.317 70.0029 308.456 71.143 308.456 72.7391C308.456 74.3353 307.317 75.4754 305.722 75.4754Z" fill={ color }/>
  <path d="M299.57 75.4754C297.975 75.4754 296.836 74.3353 296.836 72.7391C296.836 71.143 297.975 70.0029 299.57 70.0029C301.164 70.0029 302.303 71.143 302.303 72.7391C302.531 74.3353 301.164 75.4754 299.57 75.4754Z" fill={ color }/>
  <path d="M293.648 75.4754C295.158 75.4754 296.381 74.2503 296.381 72.7391C296.381 71.2279 295.158 70.0029 293.648 70.0029C292.138 70.0029 290.914 71.2279 290.914 72.7391C290.914 74.2503 292.138 75.4754 293.648 75.4754Z" fill={ color }/>
  <path d="M287.726 75.4754C286.131 75.4754 284.992 74.3353 284.992 72.7391C284.992 71.143 286.131 70.0029 287.726 70.0029C289.321 70.0029 290.46 71.143 290.46 72.7391C290.46 74.3353 289.321 75.4754 287.726 75.4754Z" fill={ color }/>
  <path d="M281.804 75.4754C283.314 75.4754 284.538 74.2503 284.538 72.7391C284.538 71.2279 283.314 70.0029 281.804 70.0029C280.294 70.0029 279.07 71.2279 279.07 72.7391C279.07 74.2503 280.294 75.4754 281.804 75.4754Z" fill={ color }/>
  <path d="M275.652 75.4754C274.057 75.4754 272.918 74.3353 272.918 72.7391C272.918 71.143 274.057 70.0029 275.652 70.0029C277.246 70.0029 278.385 71.143 278.385 72.7391C278.385 74.3353 277.246 75.4754 275.652 75.4754Z" fill={ color }/>
  <path d="M269.726 75.4754C271.236 75.4754 272.46 74.2503 272.46 72.7391C272.46 71.2279 271.236 70.0029 269.726 70.0029C268.216 70.0029 266.992 71.2279 266.992 72.7391C266.992 74.2503 268.216 75.4754 269.726 75.4754Z" fill={ color }/>
  <path d="M263.804 75.4754C262.209 75.4754 261.07 74.3353 261.07 72.7391C261.07 71.143 262.209 70.0029 263.804 70.0029C265.399 70.0029 266.538 71.143 266.538 72.7391C266.538 74.3353 265.171 75.4754 263.804 75.4754Z" fill={ color }/>
  <path d="M257.656 75.4754C259.165 75.4754 260.389 74.2503 260.389 72.7391C260.389 71.2279 259.165 70.0029 257.656 70.0029C256.146 70.0029 254.922 71.2279 254.922 72.7391C254.922 74.2503 256.146 75.4754 257.656 75.4754Z" fill={ color }/>
  <path d="M251.73 75.4754C250.135 75.4754 248.996 74.3353 248.996 72.7391C248.996 71.143 250.135 70.0029 251.73 70.0029C253.325 70.0029 254.464 71.143 254.464 72.7391C254.464 74.3353 253.325 75.4754 251.73 75.4754Z" fill={ color }/>
  <path d="M245.808 75.4754C247.318 75.4754 248.542 74.2503 248.542 72.7391C248.542 71.2279 247.318 70.0029 245.808 70.0029C244.298 70.0029 243.074 71.2279 243.074 72.7391C243.074 74.2503 244.298 75.4754 245.808 75.4754Z" fill={ color }/>
  <path d="M239.656 75.4754C238.289 75.4754 236.922 74.3353 236.922 72.7391C236.922 71.143 238.061 70.0029 239.656 70.0029C241.022 70.0029 242.389 71.143 242.389 72.7391C242.389 74.3353 241.25 75.4754 239.656 75.4754Z" fill={ color }/>
  <path d="M233.732 75.2481C235.116 75.2481 236.238 74.1251 236.238 72.7399C236.238 71.3546 235.116 70.2316 233.732 70.2316C232.348 70.2316 231.227 71.3546 231.227 72.7399C231.227 74.1251 232.348 75.2481 233.732 75.2481Z" fill={ color }/>
  <path d="M227.811 75.4768C226.444 75.4768 225.305 74.3367 225.305 72.9685C225.305 71.6004 226.444 70.4603 227.811 70.4603C229.177 70.4603 230.317 71.6004 230.317 72.9685C230.317 74.3367 229.177 75.4768 227.811 75.4768Z" fill={ color }/>
  <path d="M221.889 75.2481C223.273 75.2481 224.395 74.1251 224.395 72.7399C224.395 71.3546 223.273 70.2316 221.889 70.2316C220.505 70.2316 219.383 71.3546 219.383 72.7399C219.383 74.1251 220.505 75.2481 221.889 75.2481Z" fill={ color }/>
  <path d="M215.736 75.2481C214.37 75.2481 213.23 74.108 213.23 72.7399C213.23 71.3717 214.37 70.2316 215.736 70.2316C217.103 70.2316 218.242 71.3717 218.242 72.7399C218.242 74.108 217.103 75.2481 215.736 75.2481Z" fill={ color }/>
  <path d="M209.815 75.2481C211.198 75.2481 212.32 74.1251 212.32 72.7399C212.32 71.3546 211.198 70.2316 209.815 70.2316C208.431 70.2316 207.309 71.3546 207.309 72.7399C207.309 74.1251 208.431 75.2481 209.815 75.2481Z" fill={ color }/>
  <path d="M203.889 75.2481C202.522 75.2481 201.383 74.108 201.383 72.7399C201.383 71.3717 202.522 70.2316 203.889 70.2316C205.256 70.2316 206.395 71.3717 206.395 72.7399C206.167 74.108 205.256 75.2481 203.889 75.2481Z" fill={ color }/>
  <path d="M197.74 75.2481C196.373 75.2481 195.234 74.108 195.234 72.7399C195.234 71.3717 196.373 70.2316 197.74 70.2316C199.107 70.2316 200.246 71.3717 200.246 72.7399C200.246 74.108 199.107 75.2481 197.74 75.2481Z" fill={ color }/>
  <path d="M191.817 75.2472C190.45 75.2472 189.539 74.1071 189.539 72.967C189.539 71.8269 190.678 70.6868 191.817 70.6868C193.184 70.6868 194.095 71.8269 194.095 72.967C194.095 74.1071 193.184 75.2472 191.817 75.2472Z" fill={ color }/>
  <path d="M185.895 75.0207C184.528 75.0207 183.617 73.8806 183.617 72.7405C183.617 71.3724 184.756 70.4603 185.895 70.4603C187.262 70.4603 188.173 71.6004 188.173 72.7405C188.173 74.1086 187.034 75.0207 185.895 75.0207Z" fill={ color }/>
  <path d="M179.743 75.0207C178.604 75.0207 177.465 74.1086 177.465 72.7405C177.465 71.6004 178.376 70.4603 179.743 70.4603C180.882 70.4603 182.021 71.3724 182.021 72.7405C182.021 74.1086 181.11 75.0207 179.743 75.0207Z" fill={ color }/>
  <path d="M173.821 75.0207C172.682 75.0207 171.543 74.1086 171.543 72.7405C171.543 71.3724 172.454 70.4603 173.821 70.4603C174.96 70.4603 176.099 71.3724 176.099 72.7405C176.099 74.1086 175.188 75.0207 173.821 75.0207Z" fill={ color }/>
  <path d="M167.895 75.0207C166.756 75.0207 165.617 74.1086 165.617 72.7405C165.617 71.3724 166.528 70.4603 167.895 70.4603C169.262 70.4603 170.173 71.3724 170.173 72.7405C170.173 74.1086 169.034 75.0207 167.895 75.0207Z" fill={ color }/>
  <path d="M161.972 75.02C160.833 75.02 159.922 74.1079 159.922 72.9677C159.922 71.8276 160.833 70.9155 161.972 70.9155C163.111 70.9155 164.022 71.8276 164.022 72.9677C164.022 74.1079 163.111 75.02 161.972 75.02Z" fill={ color }/>
  <path d="M155.824 74.7912C156.956 74.7912 157.874 73.8724 157.874 72.739C157.874 71.6056 156.956 70.6868 155.824 70.6868C154.691 70.6868 153.773 71.6056 153.773 72.739C153.773 73.8724 154.691 74.7912 155.824 74.7912Z" fill={ color }/>
  <path d="M149.898 74.7912C148.759 74.7912 147.848 73.8791 147.848 72.739C147.848 71.5989 148.759 70.6868 149.898 70.6868C151.037 70.6868 151.948 71.5989 151.948 72.739C151.948 73.8791 151.037 74.7912 149.898 74.7912Z" fill={ color }/>
  <path d="M143.976 74.7912C145.108 74.7912 146.026 73.8724 146.026 72.739C146.026 71.6056 145.108 70.6868 143.976 70.6868C142.844 70.6868 141.926 71.6056 141.926 72.739C141.926 73.8724 142.844 74.7912 143.976 74.7912Z" fill={ color }/>
  <path d="M137.826 74.7915C136.687 74.7915 136.004 73.8794 136.004 72.9673C136.004 72.0552 136.915 71.1431 137.826 71.1431C138.965 71.1431 139.649 72.0552 139.649 72.9673C139.649 73.8794 138.965 74.7915 137.826 74.7915Z" fill={ color }/>
  <path d="M131.905 74.5639C132.911 74.5639 133.727 73.7472 133.727 72.7397C133.727 71.7323 132.911 70.9155 131.905 70.9155C130.898 70.9155 130.082 71.7323 130.082 72.7397C130.082 73.7472 130.898 74.5639 131.905 74.5639Z" fill={ color }/>
  <path d="M125.979 74.5639C126.985 74.5639 127.801 73.7472 127.801 72.7397C127.801 71.7323 126.985 70.9155 125.979 70.9155C124.972 70.9155 124.156 71.7323 124.156 72.7397C124.156 73.7472 124.972 74.5639 125.979 74.5639Z" fill={ color }/>
  <path d="M119.829 74.3354C120.71 74.3354 121.424 73.6208 121.424 72.7393C121.424 71.8577 120.71 71.1431 119.829 71.1431C118.948 71.1431 118.234 71.8577 118.234 72.7393C118.234 73.6208 118.948 74.3354 119.829 74.3354Z" fill={ color }/>
  <path d="M113.907 74.3354C114.788 74.3354 115.502 73.6208 115.502 72.7393C115.502 71.8577 114.788 71.1431 113.907 71.1431C113.026 71.1431 112.312 71.8577 112.312 72.7393C112.312 73.6208 113.026 74.3354 113.907 74.3354Z" fill={ color }/>
  <path d="M107.981 74.3354C107.07 74.3354 106.387 73.6514 106.387 72.7393C106.387 71.8272 107.07 71.1431 107.981 71.1431C108.893 71.1431 109.576 71.8272 109.576 72.7393C109.576 73.6514 108.893 74.3354 107.981 74.3354Z" fill={ color }/>
  <path d="M102.06 74.3354C101.148 74.3354 100.465 73.6514 100.465 72.7393C100.465 71.8272 101.148 71.1431 102.06 71.1431C102.971 71.1431 103.654 71.8272 103.654 72.7393C103.654 73.6514 102.743 74.3354 102.06 74.3354Z" fill={ color }/>
  <path d="M95.9098 74.1081C95.2264 74.1081 94.543 73.424 94.543 72.7399C94.543 72.0558 95.2264 71.3718 95.9098 71.3718C96.5933 71.3718 97.2767 72.0558 97.2767 72.7399C97.2767 73.424 96.8211 74.1081 95.9098 74.1081Z" fill={ color }/>
  <path d="M89.984 74.1081C90.7389 74.1081 91.3509 73.4955 91.3509 72.7399C91.3509 71.9843 90.7389 71.3718 89.984 71.3718C89.2292 71.3718 88.6172 71.9843 88.6172 72.7399C88.6172 73.4955 89.2292 74.1081 89.984 74.1081Z" fill={ color }/>
  <path d="M84.0622 74.1081C84.8171 74.1081 85.429 73.4955 85.429 72.7399C85.429 71.9843 84.8171 71.3718 84.0622 71.3718C83.3073 71.3718 82.6953 71.9843 82.6953 72.7399C82.6953 73.4955 83.3073 74.1081 84.0622 74.1081Z" fill={ color }/>
  <path d="M77.9125 73.8797C77.2291 73.8797 76.7734 73.4236 76.7734 72.7396C76.7734 72.0555 77.2291 71.5995 77.9125 71.5995C78.5959 71.5995 79.0515 72.0555 79.0515 72.7396C79.0515 73.4236 78.5959 73.8797 77.9125 73.8797Z" fill={ color }/>
  <path d="M71.9906 73.8797C71.3072 73.8797 70.8516 73.4236 70.8516 72.7396C70.8516 72.0555 71.3072 71.5995 71.9906 71.5995C72.674 71.5995 73.1297 72.0555 73.1297 72.7396C73.1297 73.4236 72.674 73.8797 71.9906 73.8797Z" fill={ color }/>
  <path d="M66.0648 73.8797C65.3814 73.8797 64.9258 73.4236 64.9258 72.7396C64.9258 72.0555 65.3814 71.5995 66.0648 71.5995C66.7483 71.5995 67.2039 72.0555 67.2039 72.7396C66.9761 73.4236 66.5205 73.8797 66.0648 73.8797Z" fill={ color }/>
  <path d="M59.9151 73.8799C59.4595 73.8799 59.0039 73.4238 59.0039 72.9678C59.0039 72.5117 59.4595 72.0557 59.9151 72.0557C60.3708 72.0557 60.8264 72.5117 60.8264 72.9678C60.8264 73.4238 60.5986 73.8799 59.9151 73.8799Z" fill={ color }/>
  <path d="M53.9894 73.6512C53.5337 73.6512 53.0781 73.1952 53.0781 72.7391C53.0781 72.2831 53.5337 71.827 53.9894 71.827C54.445 71.827 54.9006 72.2831 54.9006 72.7391C54.9006 73.1952 54.445 73.6512 53.9894 73.6512Z" fill={ color }/>
  <path d="M48.0675 73.6512C47.6119 73.6512 47.1562 73.1952 47.1562 72.7391C47.1562 72.2831 47.6119 71.827 48.0675 71.827C48.5231 71.827 48.9787 72.2831 48.9787 72.7391C48.7509 73.1952 48.5231 73.6512 48.0675 73.6512Z" fill={ color }/>
  <path d="M42.1444 73.4238C41.6887 73.4238 41.4609 73.1958 41.4609 72.7398C41.4609 72.2837 41.6887 72.0557 42.1444 72.0557C42.6 72.0557 42.8278 72.2837 42.8278 72.7398C42.8278 73.1958 42.3722 73.4238 42.1444 73.4238Z" fill={ color }/>
  <path d="M35.9959 73.4234C35.5403 73.4234 35.3125 73.1954 35.3125 72.7393C35.3125 72.2833 35.7681 72.2833 35.9959 72.2833C36.2237 72.2833 36.6794 72.5113 36.6794 72.9674C36.6794 73.4234 36.4516 73.4234 35.9959 73.4234Z" fill={ color }/>
  <path d="M30.0728 73.4241C29.845 73.4241 29.6172 73.1961 29.6172 72.9681C29.6172 72.7401 29.845 72.512 30.0728 72.512C30.3006 72.512 30.5284 72.7401 30.5284 72.9681C30.5284 73.1961 30.3006 73.4241 30.0728 73.4241Z" fill={ color }/>
  <path d="M24.147 73.1954C24.3987 73.1954 24.6027 72.9912 24.6027 72.7393C24.6027 72.4875 24.3987 72.2833 24.147 72.2833C23.8954 72.2833 23.6914 72.4875 23.6914 72.7393C23.6914 72.9912 23.8954 73.1954 24.147 73.1954Z" fill={ color }/>
  <path d="M17.9986 73.1954C17.7708 73.1954 17.543 72.9674 17.543 72.7393C17.543 72.5113 17.7708 72.2833 17.9986 72.2833C18.2264 72.2833 18.4542 72.5113 18.4542 72.7393C18.4542 72.9674 18.2264 73.1954 17.9986 73.1954Z" fill={ color }/>
  <path d="M12.0755 72.9681C12.2013 72.9681 12.3033 72.866 12.3033 72.7401C12.3033 72.6141 12.2013 72.512 12.0755 72.512C11.9497 72.512 11.8477 72.6141 11.8477 72.7401C11.8477 72.866 11.9497 72.9681 12.0755 72.9681Z" fill={ color }/>
  <path d="M6.15133 72.9681C5.92352 72.9681 5.92352 72.9681 6.15133 72.9681C5.92352 72.7401 6.15133 72.512 6.15133 72.512C6.15133 72.512 6.37914 72.7401 6.15133 72.9681C6.37914 72.9681 6.15133 72.9681 6.15133 72.9681Z" fill={ color }/>
  <path d="M344.678 79.3529C344.45 79.3529 344.223 79.1249 344.223 78.8969C344.223 78.6689 344.45 78.4408 344.678 78.4408C344.906 78.4408 345.134 78.6689 345.134 78.8969C345.134 79.1249 344.906 79.3529 344.678 79.3529Z" fill={ color }/>
  <path d="M338.526 80.0361C337.842 80.0361 337.387 79.58 337.387 78.896C337.387 78.2119 337.842 77.7558 338.526 77.7558C339.209 77.7558 339.665 78.2119 339.665 78.896C339.893 79.352 339.209 80.0361 338.526 80.0361Z" fill={ color }/>
  <path d="M332.602 80.4929C331.691 80.4929 331.008 79.8088 331.008 78.8967C331.008 77.9846 331.691 77.3006 332.602 77.3006C333.514 77.3006 334.197 77.9846 334.197 78.8967C334.197 79.8088 333.514 80.4929 332.602 80.4929Z" fill={ color }/>
  <path d="M326.679 80.72C325.54 80.72 324.629 79.8079 324.629 78.6678C324.629 77.5277 325.54 76.6156 326.679 76.6156C327.818 76.6156 328.73 77.5277 328.73 78.6678C328.73 79.8079 327.818 80.72 326.679 80.72Z" fill={ color }/>
  <path d="M320.759 80.9485C319.62 80.9485 318.48 80.0364 318.48 78.6682C318.48 77.5281 319.392 76.388 320.759 76.388C322.125 76.388 323.037 77.3001 323.037 78.6682C322.809 80.0364 321.898 80.9485 320.759 80.9485Z" fill={ color }/>
  <path d="M314.606 81.176C313.239 81.176 312.328 80.0359 312.328 78.8958C312.328 77.5277 313.467 76.6156 314.606 76.6156C315.973 76.6156 316.884 77.7557 316.884 78.8958C317.112 80.0359 315.973 81.176 314.606 81.176Z" fill={ color }/>
  <path d="M308.686 81.1769C307.319 81.1769 306.18 80.0368 306.18 78.6687C306.18 77.3005 307.319 76.1604 308.686 76.1604C310.052 76.1604 311.192 77.3005 311.192 78.6687C311.192 80.2648 310.052 81.1769 308.686 81.1769Z" fill={ color }/>
  <path d="M302.76 81.4045C301.393 81.4045 300.254 80.2644 300.254 78.8963C300.254 77.5281 301.393 76.388 302.76 76.388C304.127 76.388 305.266 77.5281 305.266 78.8963C305.266 80.2644 304.127 81.4045 302.76 81.4045Z" fill={ color }/>
  <path d="M296.608 81.4045C295.241 81.4045 294.102 80.2644 294.102 78.8963C294.102 77.5281 295.241 76.388 296.608 76.388C297.974 76.388 299.113 77.5281 299.113 78.8963C299.341 80.2644 298.202 81.4045 296.608 81.4045Z" fill={ color }/>
  <path d="M290.686 81.4045C289.319 81.4045 288.18 80.2644 288.18 78.8963C288.18 77.5281 289.319 76.388 290.686 76.388C292.052 76.388 293.192 77.5281 293.192 78.8963C293.192 80.2644 292.052 81.4045 290.686 81.4045Z" fill={ color }/>
  <path d="M284.764 81.4045C283.397 81.4045 282.258 80.2644 282.258 78.8963C282.258 77.5281 283.397 76.388 284.764 76.388C286.131 76.388 287.27 77.5281 287.27 78.8963C287.27 80.2644 286.131 81.4045 284.764 81.4045Z" fill={ color }/>
  <path d="M278.611 81.4045C277.245 81.4045 276.105 80.2644 276.105 78.8963C276.105 77.5281 277.245 76.388 278.611 76.388C279.978 76.388 281.117 77.5281 281.117 78.8963C281.345 80.2644 280.206 81.4045 278.611 81.4045Z" fill={ color }/>
  <path d="M272.689 81.4045C271.323 81.4045 270.184 80.2644 270.184 78.8963C270.184 77.5281 271.323 76.388 272.689 76.388C274.056 76.388 275.195 77.5281 275.195 78.8963C275.423 80.2644 274.056 81.4045 272.689 81.4045Z" fill={ color }/>
  <path d="M266.768 81.4045C265.401 81.4045 264.262 80.2644 264.262 78.8963C264.262 77.5281 265.401 76.388 266.768 76.388C268.134 76.388 269.274 77.5281 269.274 78.8963C269.274 80.2644 268.134 81.4045 266.768 81.4045Z" fill={ color }/>
  <path d="M260.842 81.4045C259.475 81.4045 258.336 80.2644 258.336 78.8963C258.336 77.5281 259.475 76.388 260.842 76.388C262.209 76.388 263.348 77.5281 263.348 78.8963C263.348 80.2644 262.209 81.4045 260.842 81.4045Z" fill={ color }/>
  <path d="M254.693 81.4045C256.077 81.4045 257.199 80.2815 257.199 78.8963C257.199 77.511 256.077 76.388 254.693 76.388C253.309 76.388 252.188 77.511 252.188 78.8963C252.188 80.2815 253.309 81.4045 254.693 81.4045Z" fill={ color }/>
  <path d="M248.772 81.4045C247.405 81.4045 246.266 80.2644 246.266 78.8963C246.266 77.5281 247.405 76.388 248.772 76.388C250.138 76.388 251.277 77.5281 251.277 78.8963C251.277 80.2644 250.138 81.4045 248.772 81.4045Z" fill={ color }/>
  <path d="M242.846 81.4045C244.23 81.4045 245.352 80.2815 245.352 78.8963C245.352 77.511 244.23 76.388 242.846 76.388C241.462 76.388 240.34 77.511 240.34 78.8963C240.34 80.2815 241.462 81.4045 242.846 81.4045Z" fill={ color }/>
  <path d="M236.693 81.4045C235.327 81.4045 234.188 80.2644 234.188 78.8963C234.188 77.5281 235.327 76.388 236.693 76.388C238.06 76.388 239.199 77.5281 239.199 78.8963C239.199 80.2644 238.06 81.4045 236.693 81.4045Z" fill={ color }/>
  <path d="M230.772 81.1769C229.405 81.1769 228.266 80.0368 228.266 78.6687C228.266 77.3005 229.405 76.1604 230.772 76.1604C232.138 76.1604 233.277 77.3005 233.277 78.6687C233.277 80.2648 232.138 81.1769 230.772 81.1769Z" fill={ color }/>
  <path d="M224.85 81.1769C223.483 81.1769 222.344 80.0368 222.344 78.6687C222.344 77.3005 223.483 76.1604 224.85 76.1604C226.217 76.1604 227.356 77.3005 227.356 78.6687C227.128 80.0368 226.217 81.1769 224.85 81.1769Z" fill={ color }/>
  <path d="M218.697 81.1769C217.33 81.1769 216.191 80.0368 216.191 78.6687C216.191 77.3005 217.33 76.1604 218.697 76.1604C220.064 76.1604 221.203 77.3005 221.203 78.6687C221.203 80.0368 220.064 81.1769 218.697 81.1769Z" fill={ color }/>
  <path d="M212.774 81.176C211.407 81.176 210.496 80.0359 210.496 78.8958C210.496 77.5277 211.635 76.6156 212.774 76.6156C214.141 76.6156 215.052 77.7557 215.052 78.8958C215.28 80.0359 214.141 81.176 212.774 81.176Z" fill={ color }/>
  <path d="M206.852 81.176C205.485 81.176 204.574 80.0359 204.574 78.8958C204.574 77.5277 205.713 76.6156 206.852 76.6156C208.219 76.6156 209.13 77.7557 209.13 78.8958C209.13 80.0359 207.991 81.176 206.852 81.176Z" fill={ color }/>
  <path d="M200.93 81.176C199.564 81.176 198.652 80.2639 198.652 78.8958C198.652 77.5277 199.564 76.6156 200.93 76.6156C202.297 76.6156 203.209 77.5277 203.209 78.8958C203.209 80.0359 202.07 81.176 200.93 81.176Z" fill={ color }/>
  <path d="M194.778 80.9485C193.639 80.9485 192.5 80.0364 192.5 78.6682C192.5 77.5281 193.411 76.388 194.778 76.388C195.917 76.388 197.056 77.3001 197.056 78.6682C197.056 80.0364 196.145 80.9485 194.778 80.9485Z" fill={ color }/>
  <path d="M188.856 80.9485C187.717 80.9485 186.578 80.0364 186.578 78.6682C186.578 77.5281 187.489 76.388 188.856 76.388C189.995 76.388 191.134 77.3001 191.134 78.6682C191.134 80.0364 189.995 80.9485 188.856 80.9485Z" fill={ color }/>
  <path d="M182.933 80.9486C181.794 80.9486 180.883 80.0366 180.883 78.8964C180.883 77.7563 181.794 76.8442 182.933 76.8442C184.072 76.8442 184.983 77.7563 184.983 78.8964C184.983 80.0366 184.072 80.9486 182.933 80.9486Z" fill={ color }/>
  <path d="M176.781 80.9486C175.642 80.9486 174.73 80.0366 174.73 78.8964C174.73 77.7563 175.642 76.8442 176.781 76.8442C177.92 76.8442 178.831 77.7563 178.831 78.8964C179.059 80.0366 177.92 80.9486 176.781 80.9486Z" fill={ color }/>
  <path d="M170.859 80.9486C169.72 80.9486 168.809 80.0366 168.809 78.8964C168.809 77.7563 169.72 76.8442 170.859 76.8442C171.998 76.8442 172.909 77.7563 172.909 78.8964C172.909 80.0366 171.998 80.9486 170.859 80.9486Z" fill={ color }/>
  <path d="M164.937 80.72C163.798 80.72 162.887 79.8079 162.887 78.6678C162.887 77.5277 163.798 76.6156 164.937 76.6156C166.076 76.6156 166.987 77.5277 166.987 78.6678C166.987 79.8079 166.076 80.72 164.937 80.72Z" fill={ color }/>
  <path d="M158.785 80.72C157.646 80.72 156.734 79.8079 156.734 78.6678C156.734 77.5277 157.646 76.6156 158.785 76.6156C159.924 76.6156 160.835 77.5277 160.835 78.6678C160.835 79.8079 159.924 80.72 158.785 80.72Z" fill={ color }/>
  <path d="M152.862 80.7203C151.722 80.7203 151.039 79.8082 151.039 78.8961C151.039 77.756 151.95 77.0719 152.862 77.0719C154.001 77.0719 154.684 77.984 154.684 78.8961C154.684 79.8082 154.001 80.7203 152.862 80.7203Z" fill={ color }/>
  <path d="M146.94 80.7203C146.028 80.7203 145.117 79.8082 145.117 78.8961C145.117 77.984 146.028 77.0719 146.94 77.0719C147.851 77.0719 148.762 77.984 148.762 78.8961C148.762 79.8082 147.851 80.7203 146.94 80.7203Z" fill={ color }/>
  <path d="M141.014 80.4926C140.103 80.4926 139.191 79.5805 139.191 78.6684C139.191 77.7563 140.103 76.8442 141.014 76.8442C141.925 76.8442 142.836 77.7563 142.836 78.6684C142.609 79.8085 141.925 80.4926 141.014 80.4926Z" fill={ color }/>
  <path d="M134.865 80.4926C133.954 80.4926 133.043 79.8085 133.043 78.6684C133.043 77.7563 133.726 76.8442 134.865 76.8442C135.777 76.8442 136.688 77.5283 136.688 78.6684C136.688 79.8085 135.777 80.4926 134.865 80.4926Z" fill={ color }/>
  <path d="M128.942 80.4929C128.031 80.4929 127.348 79.8088 127.348 78.8967C127.348 77.9846 128.031 77.3006 128.942 77.3006C129.854 77.3006 130.537 77.9846 130.537 78.8967C130.537 79.8088 129.854 80.4929 128.942 80.4929Z" fill={ color }/>
  <path d="M123.02 80.4929C122.109 80.4929 121.426 79.8088 121.426 78.8967C121.426 77.9846 122.109 77.3006 123.02 77.3006C123.932 77.3006 124.615 77.9846 124.615 78.8967C124.615 79.5808 123.932 80.4929 123.02 80.4929Z" fill={ color }/>
  <path d="M116.868 80.2643C115.957 80.2643 115.273 79.5802 115.273 78.6681C115.273 77.756 115.957 77.0719 116.868 77.0719C117.779 77.0719 118.463 77.756 118.463 78.6681C118.463 79.5802 117.779 80.2643 116.868 80.2643Z" fill={ color }/>
  <path d="M110.945 80.2644C110.034 80.2644 109.578 79.5804 109.578 78.8963C109.578 77.9842 110.262 77.5282 110.945 77.5282C111.856 77.5282 112.312 78.2122 112.312 78.8963C112.312 79.5804 111.856 80.2644 110.945 80.2644Z" fill={ color }/>
  <path d="M105.019 80.2644C104.336 80.2644 103.652 79.5804 103.652 78.8963C103.652 78.2122 104.336 77.5282 105.019 77.5282C105.703 77.5282 106.386 78.2122 106.386 78.8963C106.386 79.5804 105.703 80.2644 105.019 80.2644Z" fill={ color }/>
  <path d="M98.8708 80.0368C98.1873 80.0368 97.5039 79.3528 97.5039 78.6687C97.5039 77.9846 98.1873 77.3006 98.8708 77.3006C99.5542 77.3006 100.238 77.9846 100.238 78.6687C100.238 79.5808 99.782 80.0368 98.8708 80.0368Z" fill={ color }/>
  <path d="M92.9489 80.0368C92.2655 80.0368 91.582 79.5808 91.582 78.6687C91.582 77.9846 92.0376 77.3006 92.9489 77.3006C93.6323 77.3006 94.3158 77.7566 94.3158 78.6687C94.3158 79.5808 93.6323 80.0368 92.9489 80.0368Z" fill={ color }/>
  <path d="M87.0258 80.0361C86.3423 80.0361 85.8867 79.58 85.8867 78.896C85.8867 78.2119 86.3423 77.7558 87.0258 77.7558C87.7092 77.7558 88.1648 78.2119 88.1648 78.896C88.1648 79.352 87.7092 80.0361 87.0258 80.0361Z" fill={ color }/>
  <path d="M81.1 79.8084C80.4166 79.8084 79.9609 79.3523 79.9609 78.6683C79.9609 77.9842 80.4166 77.5282 81.1 77.5282C81.7834 77.5282 82.239 77.9842 82.239 78.6683C82.0112 79.3523 81.5556 79.8084 81.1 79.8084Z" fill={ color }/>
  <path d="M74.9516 79.8084C74.2681 79.8084 73.8125 79.3523 73.8125 78.6683C73.8125 77.9842 74.2681 77.5282 74.9516 77.5282C75.635 77.5282 76.0906 77.9842 76.0906 78.6683C76.0906 79.3523 75.635 79.8084 74.9516 79.8084Z" fill={ color }/>
  <path d="M69.0284 79.8087C68.5728 79.8087 68.1172 79.3526 68.1172 78.8966C68.1172 78.4405 68.5728 77.9845 69.0284 77.9845C69.484 77.9845 69.9397 78.4405 69.9397 78.8966C69.9397 79.3526 69.484 79.8087 69.0284 79.8087Z" fill={ color }/>
  <path d="M63.1027 79.58C62.647 79.58 62.1914 79.124 62.1914 78.6679C62.1914 78.2119 62.647 77.7558 63.1027 77.7558C63.5583 77.7558 64.0139 78.2119 64.0139 78.6679C63.7861 79.352 63.5583 79.58 63.1027 79.58Z" fill={ color }/>
  <path d="M56.953 79.5802C56.4973 79.5802 56.2695 79.1242 56.2695 78.8961C56.2695 78.4401 56.7252 78.2121 56.953 78.2121C57.4086 78.2121 57.6364 78.6681 57.6364 78.8961C57.8642 79.1242 57.4086 79.5802 56.953 79.5802Z" fill={ color }/>
  <path d="M51.0311 79.5802C50.5755 79.5802 50.3477 79.3522 50.3477 78.8961C50.3477 78.4401 50.5755 78.2121 51.0311 78.2121C51.4867 78.2121 51.7145 78.4401 51.7145 78.8961C51.7145 79.1242 51.4867 79.5802 51.0311 79.5802Z" fill={ color }/>
  <path d="M45.1053 79.3526C44.6497 79.3526 44.4219 79.1246 44.4219 78.6686C44.4219 78.2125 44.6497 77.9845 45.1053 77.9845C45.5609 77.9845 45.7887 78.2125 45.7887 78.6686C45.5609 79.1246 45.3331 79.3526 45.1053 79.3526Z" fill={ color }/>
  <path d="M38.9556 79.3529C38.7278 79.3529 38.5 79.1249 38.5 78.8969C38.5 78.6689 38.7278 78.4408 38.9556 78.4408C39.1834 78.4408 39.4112 78.6689 39.4112 78.8969C39.6391 79.1249 39.4112 79.3529 38.9556 79.3529Z" fill={ color }/>
  <path d="M33.0337 79.3529C32.8059 79.3529 32.5781 79.1249 32.5781 78.8969C32.5781 78.6689 32.8059 78.4408 33.0337 78.4408C33.2616 78.4408 33.4894 78.6689 33.4894 78.8969C33.4894 79.1249 33.2616 79.3529 33.0337 79.3529Z" fill={ color }/>
  <path d="M27.1119 79.1242C26.8841 79.1242 26.6562 78.8962 26.6562 78.6681C26.6562 78.4401 26.8841 78.2121 27.1119 78.2121C27.3397 78.2121 27.5675 78.4401 27.5675 78.6681C27.3397 78.8962 27.3397 79.1242 27.1119 79.1242Z" fill={ color }/>
  <path d="M21.1887 79.1245C20.9609 79.1245 20.9609 78.8964 20.9609 78.8964C20.9609 78.6684 21.1887 78.6684 21.1887 78.6684C21.4166 78.6684 21.4166 78.8964 21.4166 78.8964L21.1887 79.1245Z" fill={ color }/>
  <path d="M15.0348 78.8969C15.0348 78.8969 14.807 78.8969 15.0348 78.8969C14.807 78.6689 15.0348 78.4408 15.0348 78.4408C15.2627 78.6689 15.2627 78.6689 15.0348 78.8969C15.2627 78.8969 15.2627 78.8969 15.0348 78.8969Z" fill={ color }/>
  <path d="M335.564 85.0533C335.336 85.0533 335.336 84.8252 335.336 84.8252L335.564 84.5972C335.792 84.5972 335.792 84.8252 335.792 84.8252C335.792 84.8252 335.792 85.0533 335.564 85.0533Z" fill={ color }/>
  <path d="M329.642 85.7375C330.145 85.7375 330.553 85.3291 330.553 84.8254C330.553 84.3216 330.145 83.9133 329.642 83.9133C329.138 83.9133 328.73 84.3216 328.73 84.8254C328.73 85.3291 329.138 85.7375 329.642 85.7375Z" fill={ color }/>
  <path d="M323.718 86.1932C324.473 86.1932 325.085 85.5807 325.085 84.8251C325.085 84.0695 324.473 83.4569 323.718 83.4569C322.964 83.4569 322.352 84.0695 322.352 84.8251C322.352 85.5807 322.964 86.1932 323.718 86.1932Z" fill={ color }/>
  <path d="M317.567 86.4206C316.656 86.4206 315.973 85.7366 315.973 84.8245C315.973 83.9124 316.656 83.2283 317.567 83.2283C318.479 83.2283 319.162 83.9124 319.162 84.8245C319.162 85.7366 318.479 86.4206 317.567 86.4206Z" fill={ color }/>
  <path d="M311.647 86.649C310.735 86.649 309.824 85.7369 309.824 84.8248C309.824 83.9127 310.735 83.0006 311.647 83.0006C312.558 83.0006 313.469 83.9127 313.469 84.8248C313.469 85.7369 312.786 86.649 311.647 86.649Z" fill={ color }/>
  <path d="M305.722 86.8775C306.855 86.8775 307.772 85.9586 307.772 84.8252C307.772 83.6918 306.855 82.773 305.722 82.773C304.59 82.773 303.672 83.6918 303.672 84.8252C303.672 85.9586 304.59 86.8775 305.722 86.8775Z" fill={ color }/>
  <path d="M299.574 86.8775C300.706 86.8775 301.624 85.9586 301.624 84.8252C301.624 83.6918 300.706 82.773 299.574 82.773C298.441 82.773 297.523 83.6918 297.523 84.8252C297.523 85.9586 298.441 86.8775 299.574 86.8775Z" fill={ color }/>
  <path d="M293.649 87.1048C292.51 87.1048 291.371 86.1927 291.371 84.8246C291.371 83.4565 292.282 82.5444 293.649 82.5444C294.788 82.5444 295.927 83.4565 295.927 84.8246C295.927 86.1927 295.016 87.1048 293.649 87.1048Z" fill={ color }/>
  <path d="M287.723 87.1048C286.357 87.1048 285.445 86.1927 285.445 84.8246C285.445 83.4565 286.357 82.5444 287.723 82.5444C289.09 82.5444 290.002 83.4565 290.002 84.8246C290.002 86.1927 288.862 87.1048 287.723 87.1048Z" fill={ color }/>
  <path d="M281.802 87.1048C283.06 87.1048 284.08 86.0839 284.08 84.8246C284.08 83.5653 283.06 82.5444 281.802 82.5444C280.543 82.5444 279.523 83.5653 279.523 84.8246C279.523 86.0839 280.543 87.1048 281.802 87.1048Z" fill={ color }/>
  <path d="M275.649 87.1048C274.282 87.1048 273.371 85.9647 273.371 84.8246C273.371 83.6845 274.51 82.5444 275.649 82.5444C277.016 82.5444 277.927 83.6845 277.927 84.8246C277.927 85.9647 277.016 87.1048 275.649 87.1048Z" fill={ color }/>
  <path d="M269.727 87.1048C270.986 87.1048 272.005 86.0839 272.005 84.8246C272.005 83.5653 270.986 82.5444 269.727 82.5444C268.469 82.5444 267.449 83.5653 267.449 84.8246C267.449 86.0839 268.469 87.1048 269.727 87.1048Z" fill={ color }/>
  <path d="M263.805 87.1048C262.439 87.1048 261.527 85.9647 261.527 84.8246C261.527 83.6845 262.666 82.5444 263.805 82.5444C265.172 82.5444 266.084 83.6845 266.084 84.8246C266.084 85.9647 264.944 87.1048 263.805 87.1048Z" fill={ color }/>
  <path d="M257.653 87.1048C256.286 87.1048 255.375 85.9647 255.375 84.8246C255.375 83.6845 256.514 82.5444 257.653 82.5444C258.792 82.5444 259.931 83.6845 259.931 84.8246C259.931 85.9647 259.02 87.1048 257.653 87.1048Z" fill={ color }/>
  <path d="M251.731 87.1048C250.364 87.1048 249.453 85.9647 249.453 84.8246C249.453 83.4565 250.592 82.5444 251.731 82.5444C253.098 82.5444 254.009 83.6845 254.009 84.8246C254.009 86.1927 253.098 87.1048 251.731 87.1048Z" fill={ color }/>
  <path d="M245.809 87.1048C247.068 87.1048 248.087 86.0839 248.087 84.8246C248.087 83.5653 247.068 82.5444 245.809 82.5444C244.551 82.5444 243.531 83.5653 243.531 84.8246C243.531 86.0839 244.551 87.1048 245.809 87.1048Z" fill={ color }/>
  <path d="M239.657 87.1048C238.29 87.1048 237.379 85.9647 237.379 84.8246C237.379 83.6845 238.518 82.5444 239.657 82.5444C241.024 82.5444 241.935 83.6845 241.935 84.8246C241.935 85.9647 241.024 87.1048 239.657 87.1048Z" fill={ color }/>
  <path d="M233.735 87.1048C234.993 87.1048 236.013 86.0839 236.013 84.8246C236.013 83.5653 234.993 82.5444 233.735 82.5444C232.477 82.5444 231.457 83.5653 231.457 84.8246C231.457 86.0839 232.477 87.1048 233.735 87.1048Z" fill={ color }/>
  <path d="M227.809 87.1048C226.442 87.1048 225.531 85.9647 225.531 84.8246C225.531 83.6845 226.67 82.5444 227.809 82.5444C229.176 82.5444 230.087 83.6845 230.087 84.8246C230.087 85.9647 228.948 87.1048 227.809 87.1048Z" fill={ color }/>
  <path d="M221.887 87.1048C220.521 87.1048 219.609 86.1927 219.609 84.8246C219.609 83.4565 220.521 82.5444 221.887 82.5444C223.254 82.5444 224.166 83.4565 224.166 84.8246C224.166 86.1927 223.027 87.1048 221.887 87.1048Z" fill={ color }/>
  <path d="M215.735 87.1048C214.596 87.1048 213.457 86.1927 213.457 84.8246C213.457 83.6845 214.368 82.5444 215.735 82.5444C216.874 82.5444 218.013 83.4565 218.013 84.8246C218.013 85.9647 217.102 87.1048 215.735 87.1048Z" fill={ color }/>
  <path d="M209.813 87.1048C211.071 87.1048 212.091 86.0839 212.091 84.8246C212.091 83.5653 211.071 82.5444 209.813 82.5444C208.555 82.5444 207.535 83.5653 207.535 84.8246C207.535 86.0839 208.555 87.1048 209.813 87.1048Z" fill={ color }/>
  <path d="M203.891 86.8771C202.752 86.8771 201.613 85.9651 201.613 84.5969C201.613 83.2288 202.525 82.3167 203.891 82.3167C205.03 82.3167 206.169 83.2288 206.169 84.5969C206.169 85.9651 205.03 86.8771 203.891 86.8771Z" fill={ color }/>
  <path d="M197.742 86.8775C198.874 86.8775 199.792 85.9586 199.792 84.8252C199.792 83.6918 198.874 82.773 197.742 82.773C196.609 82.773 195.691 83.6918 195.691 84.8252C195.691 85.9586 196.609 86.8775 197.742 86.8775Z" fill={ color }/>
  <path d="M191.816 86.8775C192.948 86.8775 193.866 85.9586 193.866 84.8252C193.866 83.6918 192.948 82.773 191.816 82.773C190.684 82.773 189.766 83.6918 189.766 84.8252C189.766 85.9586 190.684 86.8775 191.816 86.8775Z" fill={ color }/>
  <path d="M185.894 86.8775C187.026 86.8775 187.944 85.9586 187.944 84.8252C187.944 83.6918 187.026 82.773 185.894 82.773C184.762 82.773 183.844 83.6918 183.844 84.8252C183.844 85.9586 184.762 86.8775 185.894 86.8775Z" fill={ color }/>
  <path d="M179.742 86.8775C178.603 86.8775 177.691 85.9654 177.691 84.8252C177.691 83.6851 178.603 82.773 179.742 82.773C180.881 82.773 181.792 83.6851 181.792 84.8252C181.792 85.9654 180.881 86.8775 179.742 86.8775Z" fill={ color }/>
  <path d="M173.82 86.6488C172.681 86.6488 171.77 85.7367 171.77 84.5966C171.77 83.4565 172.681 82.5444 173.82 82.5444C174.959 82.5444 175.87 83.4565 175.87 84.5966C175.87 85.9647 174.959 86.6488 173.82 86.6488Z" fill={ color }/>
  <path d="M167.897 86.649C166.758 86.649 166.074 85.7369 166.074 84.8248C166.074 83.9127 166.985 83.0006 167.897 83.0006C169.036 83.0006 169.719 83.9127 169.719 84.8248C169.719 85.7369 169.036 86.649 167.897 86.649Z" fill={ color }/>
  <path d="M161.975 86.649C162.981 86.649 163.797 85.8323 163.797 84.8248C163.797 83.8173 162.981 83.0006 161.975 83.0006C160.968 83.0006 160.152 83.8173 160.152 84.8248C160.152 85.8323 160.968 86.649 161.975 86.649Z" fill={ color }/>
  <path d="M155.822 86.649C156.829 86.649 157.645 85.8323 157.645 84.8248C157.645 83.8173 156.829 83.0006 155.822 83.0006C154.816 83.0006 154 83.8173 154 84.8248C154 85.8323 154.816 86.649 155.822 86.649Z" fill={ color }/>
  <path d="M149.901 86.649C148.989 86.649 148.078 85.9649 148.078 84.8248C148.078 83.6847 148.762 83.0006 149.901 83.0006C150.812 83.0006 151.723 83.6847 151.723 84.8248C151.723 85.9649 150.812 86.649 149.901 86.649Z" fill={ color }/>
  <path d="M143.975 86.649C144.981 86.649 145.797 85.8323 145.797 84.8248C145.797 83.8173 144.981 83.0006 143.975 83.0006C142.968 83.0006 142.152 83.8173 142.152 84.8248C142.152 85.8323 142.968 86.649 143.975 86.649Z" fill={ color }/>
  <path d="M137.825 86.4206C136.914 86.4206 136.23 85.7366 136.23 84.8245C136.23 83.9124 136.914 83.2283 137.825 83.2283C138.736 83.2283 139.42 83.9124 139.42 84.8245C139.648 85.7366 138.736 86.4206 137.825 86.4206Z" fill={ color }/>
  <path d="M131.903 86.4206C132.784 86.4206 133.498 85.706 133.498 84.8245C133.498 83.9429 132.784 83.2283 131.903 83.2283C131.023 83.2283 130.309 83.9429 130.309 84.8245C130.309 85.706 131.023 86.4206 131.903 86.4206Z" fill={ color }/>
  <path d="M125.981 86.4206C125.07 86.4206 124.387 85.7366 124.387 84.8245C124.387 83.9124 125.07 83.2283 125.981 83.2283C126.893 83.2283 127.576 83.9124 127.576 84.8245C127.576 85.7366 126.665 86.4206 125.981 86.4206Z" fill={ color }/>
  <path d="M119.828 86.1932C118.917 86.1932 118.461 85.5092 118.461 84.8251C118.461 84.141 119.144 83.4569 119.828 83.4569C120.739 83.4569 121.195 84.141 121.195 84.8251C121.195 85.5092 120.739 86.1932 119.828 86.1932Z" fill={ color }/>
  <path d="M113.906 86.1932C113.222 86.1932 112.539 85.5092 112.539 84.8251C112.539 84.141 113.222 83.4569 113.906 83.4569C114.589 83.4569 115.273 84.141 115.273 84.8251C115.273 85.5092 114.817 86.1932 113.906 86.1932Z" fill={ color }/>
  <path d="M107.984 86.1932C107.301 86.1932 106.617 85.5092 106.617 84.8251C106.617 84.141 107.301 83.4569 107.984 83.4569C108.667 83.4569 109.351 84.141 109.351 84.8251C109.351 85.5092 108.667 86.1932 107.984 86.1932Z" fill={ color }/>
  <path d="M102.058 86.1932C102.813 86.1932 103.425 85.5807 103.425 84.8251C103.425 84.0695 102.813 83.4569 102.058 83.4569C101.303 83.4569 100.691 84.0695 100.691 84.8251C100.691 85.5807 101.303 86.1932 102.058 86.1932Z" fill={ color }/>
  <path d="M95.9086 85.9648C96.5377 85.9648 97.0476 85.4543 97.0476 84.8246C97.0476 84.195 96.5377 83.6845 95.9086 83.6845C95.2795 83.6845 94.7695 84.195 94.7695 84.8246C94.7695 85.4543 95.2795 85.9648 95.9086 85.9648Z" fill={ color }/>
  <path d="M89.9867 85.9648C90.6158 85.9648 91.1258 85.4543 91.1258 84.8246C91.1258 84.195 90.6158 83.6845 89.9867 83.6845C89.3576 83.6845 88.8477 84.195 88.8477 84.8246C88.8477 85.4543 89.3576 85.9648 89.9867 85.9648Z" fill={ color }/>
  <path d="M84.0609 85.9648C84.69 85.9648 85.2 85.4543 85.2 84.8246C85.2 84.195 84.69 83.6845 84.0609 83.6845C83.4318 83.6845 82.9219 84.195 82.9219 84.8246C82.9219 85.4543 83.4318 85.9648 84.0609 85.9648Z" fill={ color }/>
  <path d="M77.9112 85.7375C77.4556 85.7375 77 85.2814 77 84.8254C77 84.3693 77.4556 83.9133 77.9112 83.9133C78.3669 83.9133 78.8225 84.3693 78.8225 84.8254C78.8225 85.2814 78.5947 85.7375 77.9112 85.7375Z" fill={ color }/>
  <path d="M71.9894 85.7375C72.4926 85.7375 72.9006 85.3291 72.9006 84.8254C72.9006 84.3216 72.4926 83.9133 71.9894 83.9133C71.4861 83.9133 71.0781 84.3216 71.0781 84.8254C71.0781 85.3291 71.4861 85.7375 71.9894 85.7375Z" fill={ color }/>
  <path d="M66.0675 85.5087C65.6119 85.5087 65.1562 85.0527 65.1562 84.5966C65.1562 84.1406 65.6119 83.6845 66.0675 83.6845C66.5231 83.6845 66.9787 84.1406 66.9787 84.5966C66.9787 85.0527 66.5231 85.5087 66.0675 85.5087Z" fill={ color }/>
  <path d="M59.9139 85.509C59.4583 85.509 59.2305 85.281 59.2305 84.8249C59.2305 84.3689 59.4583 84.1409 59.9139 84.1409C60.3695 84.1409 60.5973 84.3689 60.5973 84.8249C60.5973 85.281 60.3695 85.509 59.9139 85.509Z" fill={ color }/>
  <path d="M53.992 85.509C53.5364 85.509 53.3086 85.281 53.3086 84.8249C53.3086 84.3689 53.5364 84.1409 53.992 84.1409C54.4476 84.1409 54.6755 84.3689 54.6755 84.8249C54.6755 85.053 54.4476 85.509 53.992 85.509Z" fill={ color }/>
  <path d="M48.0662 85.509C48.4437 85.509 48.7497 85.2027 48.7497 84.8249C48.7497 84.4471 48.4437 84.1409 48.0662 84.1409C47.6888 84.1409 47.3828 84.4471 47.3828 84.8249C47.3828 85.2027 47.6888 85.509 48.0662 85.509Z" fill={ color }/>
  <path d="M42.147 85.2806C42.3987 85.2806 42.6027 85.0764 42.6027 84.8245C42.6027 84.5726 42.3987 84.3685 42.147 84.3685C41.8954 84.3685 41.6914 84.5726 41.6914 84.8245C41.6914 85.0764 41.8954 85.2806 42.147 85.2806Z" fill={ color }/>
  <path d="M35.9947 85.2806C36.2463 85.2806 36.4503 85.0764 36.4503 84.8245C36.4503 84.5726 36.2463 84.3685 35.9947 84.3685C35.7431 84.3685 35.5391 84.5726 35.5391 84.8245C35.5391 85.0764 35.7431 85.2806 35.9947 85.2806Z" fill={ color }/>
  <path d="M30.0716 85.0533C29.8438 85.0533 29.8438 84.8252 29.8438 84.8252L30.0716 84.5972C30.2994 84.5972 30.2994 84.8252 30.2994 84.8252C30.2994 84.8252 30.2994 85.0533 30.0716 85.0533Z" fill={ color }/>
  <path d="M24.1497 85.0533C24.2755 85.0533 24.3775 84.9512 24.3775 84.8252C24.3775 84.6993 24.2755 84.5972 24.1497 84.5972C24.0239 84.5972 23.9219 84.6993 23.9219 84.8252C23.9219 84.9512 24.0239 85.0533 24.1497 85.0533Z" fill={ color }/>
  <path d="M17.9997 84.8252C17.7719 84.5972 17.9997 84.5972 17.9997 84.5972C18.2275 84.5972 18.2275 84.5972 17.9997 84.8252C18.2275 84.8252 18.2275 84.8252 17.9997 84.8252Z" fill={ color }/>
  <path d="M326.682 91.2093C326.454 91.2093 326.227 90.9813 326.227 90.7533C326.227 90.5253 326.454 90.2973 326.682 90.2973C326.91 90.2973 327.138 90.5253 327.138 90.7533C327.138 90.9813 326.91 91.2093 326.682 91.2093Z" fill={ color }/>
  <path d="M320.759 91.6663C320.303 91.6663 319.848 91.2102 319.848 90.7542C319.848 90.2981 320.303 89.8421 320.759 89.8421C321.215 89.8421 321.67 90.2981 321.67 90.7542C321.442 91.2102 320.987 91.6663 320.759 91.6663Z" fill={ color }/>
  <path d="M314.608 91.8936C313.924 91.8936 313.469 91.4375 313.469 90.7534C313.469 90.0694 313.924 89.6133 314.608 89.6133C315.291 89.6133 315.747 90.0694 315.747 90.7534C315.747 91.4375 315.291 91.8936 314.608 91.8936Z" fill={ color }/>
  <path d="M308.683 92.122C308 92.122 307.316 91.4379 307.316 90.7539C307.316 90.0698 308 89.3857 308.683 89.3857C309.367 89.3857 310.05 90.0698 310.05 90.7539C310.05 91.4379 309.367 92.122 308.683 92.122Z" fill={ color }/>
  <path d="M302.759 92.3493C301.847 92.3493 301.164 91.6652 301.164 90.7531C301.164 89.841 301.847 89.157 302.759 89.157C303.67 89.157 304.353 89.841 304.353 90.7531C304.353 91.6652 303.442 92.3493 302.759 92.3493Z" fill={ color }/>
  <path d="M296.612 92.5778C295.7 92.5778 294.789 91.8937 294.789 90.7536C294.789 89.8415 295.473 88.9294 296.612 88.9294C297.523 88.9294 298.434 89.6135 298.434 90.7536C298.434 91.6657 297.751 92.5778 296.612 92.5778Z" fill={ color }/>
  <path d="M290.686 92.5778C289.774 92.5778 288.863 91.6657 288.863 90.7536C288.863 89.8415 289.774 88.9294 290.686 88.9294C291.597 88.9294 292.508 89.8415 292.508 90.7536C292.508 91.8937 291.597 92.5778 290.686 92.5778Z" fill={ color }/>
  <path d="M284.764 92.8053C283.625 92.8053 282.941 91.8933 282.941 90.9812C282.941 89.8411 283.853 89.157 284.764 89.157C285.903 89.157 286.586 90.0691 286.586 90.9812C286.586 91.8933 285.675 92.8053 284.764 92.8053Z" fill={ color }/>
  <path d="M278.613 92.8062C277.474 92.8062 276.562 91.8941 276.562 90.754C276.562 89.6139 277.474 88.7018 278.613 88.7018C279.752 88.7018 280.663 89.6139 280.663 90.754C280.663 91.8941 279.752 92.8062 278.613 92.8062Z" fill={ color }/>
  <path d="M272.691 92.8062C271.552 92.8062 270.641 91.8941 270.641 90.754C270.641 89.6139 271.552 88.7018 272.691 88.7018C273.83 88.7018 274.741 89.6139 274.741 90.754C274.741 91.8941 273.83 92.8062 272.691 92.8062Z" fill={ color }/>
  <path d="M266.765 92.8062C265.626 92.8062 264.715 91.8941 264.715 90.754C264.715 89.6139 265.626 88.7018 266.765 88.7018C267.904 88.7018 268.815 89.6139 268.815 90.754C268.815 91.8941 267.904 92.8062 266.765 92.8062Z" fill={ color }/>
  <path d="M260.843 92.8062C259.704 92.8062 258.793 91.8941 258.793 90.754C258.793 89.6139 259.704 88.7018 260.843 88.7018C261.982 88.7018 262.894 89.6139 262.894 90.754C262.894 91.8941 261.982 92.8062 260.843 92.8062Z" fill={ color }/>
  <path d="M254.691 92.8062C253.552 92.8062 252.641 91.8941 252.641 90.754C252.641 89.6139 253.552 88.7018 254.691 88.7018C255.83 88.7018 256.741 89.6139 256.741 90.754C256.741 91.8941 255.83 92.8062 254.691 92.8062Z" fill={ color }/>
  <path d="M248.769 92.8062C247.63 92.8062 246.719 91.8941 246.719 90.754C246.719 89.6139 247.63 88.7018 248.769 88.7018C249.908 88.7018 250.819 89.6139 250.819 90.754C250.819 91.8941 249.908 92.8062 248.769 92.8062Z" fill={ color }/>
  <path d="M242.847 92.8062C241.708 92.8062 240.797 91.8941 240.797 90.754C240.797 89.6139 241.708 88.7018 242.847 88.7018C243.986 88.7018 244.897 89.6139 244.897 90.754C244.897 91.8941 243.986 92.8062 242.847 92.8062Z" fill={ color }/>
  <path d="M236.695 92.8062C235.556 92.8062 234.645 91.8941 234.645 90.754C234.645 89.6139 235.556 88.7018 236.695 88.7018C237.834 88.7018 238.745 89.6139 238.745 90.754C238.745 91.8941 237.834 92.8062 236.695 92.8062Z" fill={ color }/>
  <path d="M230.773 92.8062C229.634 92.8062 228.723 91.8941 228.723 90.754C228.723 89.6139 229.634 88.7018 230.773 88.7018C231.912 88.7018 232.823 89.6139 232.823 90.754C232.823 91.8941 231.912 92.8062 230.773 92.8062Z" fill={ color }/>
  <path d="M224.851 92.8062C223.712 92.8062 222.801 91.8941 222.801 90.754C222.801 89.6139 223.712 88.7018 224.851 88.7018C225.99 88.7018 226.901 89.6139 226.901 90.754C226.901 91.8941 225.99 92.8062 224.851 92.8062Z" fill={ color }/>
  <path d="M218.699 92.8062C219.831 92.8062 220.749 91.8874 220.749 90.754C220.749 89.6206 219.831 88.7018 218.699 88.7018C217.566 88.7018 216.648 89.6206 216.648 90.754C216.648 91.8874 217.566 92.8062 218.699 92.8062Z" fill={ color }/>
  <path d="M212.777 92.8062C211.638 92.8062 210.727 91.8941 210.727 90.754C210.727 89.6139 211.638 88.7018 212.777 88.7018C213.916 88.7018 214.827 89.6139 214.827 90.754C214.827 91.8941 213.916 92.8062 212.777 92.8062Z" fill={ color }/>
  <path d="M206.851 92.8062C205.712 92.8062 204.801 91.8941 204.801 90.754C204.801 89.6139 205.712 88.7018 206.851 88.7018C207.99 88.7018 208.901 89.6139 208.901 90.754C208.674 91.8941 207.99 92.8062 206.851 92.8062Z" fill={ color }/>
  <path d="M200.928 92.8053C199.789 92.8053 199.105 91.8933 199.105 90.9812C199.105 89.8411 200.017 89.157 200.928 89.157C202.067 89.157 202.75 90.0691 202.75 90.9812C202.75 91.8933 201.839 92.8053 200.928 92.8053Z" fill={ color }/>
  <path d="M194.78 92.5778C193.64 92.5778 192.957 91.6657 192.957 90.7536C192.957 89.6135 193.868 88.9294 194.78 88.9294C195.919 88.9294 196.602 89.8415 196.602 90.7536C196.83 91.8937 195.919 92.5778 194.78 92.5778Z" fill={ color }/>
  <path d="M188.854 92.5778C187.715 92.5778 187.031 91.6657 187.031 90.7536C187.031 89.8415 187.942 88.9294 188.854 88.9294C189.993 88.9294 190.676 89.8415 190.676 90.7536C190.676 91.6657 189.765 92.5778 188.854 92.5778Z" fill={ color }/>
  <path d="M182.932 92.5778C182.021 92.5778 181.109 91.6657 181.109 90.7536C181.109 89.8415 182.021 88.9294 182.932 88.9294C183.843 88.9294 184.754 89.8415 184.754 90.7536C184.754 91.8937 183.843 92.5778 182.932 92.5778Z" fill={ color }/>
  <path d="M176.78 92.5778C175.868 92.5778 174.957 91.6657 174.957 90.7536C174.957 89.8415 175.868 88.9294 176.78 88.9294C177.691 88.9294 178.602 89.6135 178.602 90.7536C178.602 91.6657 177.919 92.5778 176.78 92.5778Z" fill={ color }/>
  <path d="M170.858 92.5778C169.946 92.5778 169.035 91.8937 169.035 90.7536C169.035 89.8415 169.719 88.9294 170.858 88.9294C171.769 88.9294 172.68 89.6135 172.68 90.7536C172.68 91.6657 171.769 92.5778 170.858 92.5778Z" fill={ color }/>
  <path d="M164.935 92.5781C164.023 92.5781 163.34 91.894 163.34 90.9819C163.34 90.0698 164.023 89.3857 164.935 89.3857C165.846 89.3857 166.529 90.0698 166.529 90.9819C166.529 91.666 165.846 92.5781 164.935 92.5781Z" fill={ color }/>
  <path d="M158.782 92.3493C157.871 92.3493 157.188 91.6652 157.188 90.7531C157.188 89.841 157.871 89.157 158.782 89.157C159.693 89.157 160.377 89.841 160.377 90.7531C160.605 91.6652 159.693 92.3493 158.782 92.3493Z" fill={ color }/>
  <path d="M152.86 92.3493C151.949 92.3493 151.266 91.6652 151.266 90.7531C151.266 89.841 151.949 89.157 152.86 89.157C153.772 89.157 154.455 89.841 154.455 90.7531C154.455 91.6652 153.772 92.3493 152.86 92.3493Z" fill={ color }/>
  <path d="M146.938 92.3493C146.027 92.3493 145.344 91.6652 145.344 90.7531C145.344 89.841 146.027 89.157 146.938 89.157C147.85 89.157 148.533 89.841 148.533 90.7531C148.533 91.6652 147.85 92.3493 146.938 92.3493Z" fill={ color }/>
  <path d="M141.015 92.3496C140.104 92.3496 139.648 91.6655 139.648 90.9815C139.648 90.0694 140.332 89.6133 141.015 89.6133C141.927 89.6133 142.382 90.2974 142.382 90.9815C142.382 91.6655 141.699 92.3496 141.015 92.3496Z" fill={ color }/>
  <path d="M134.863 92.122C134.18 92.122 133.496 91.4379 133.496 90.7539C133.496 90.0698 134.18 89.3857 134.863 89.3857C135.546 89.3857 136.23 90.0698 136.23 90.7539C136.23 91.666 135.774 92.122 134.863 92.122Z" fill={ color }/>
  <path d="M128.941 92.122C128.258 92.122 127.574 91.4379 127.574 90.7539C127.574 90.0698 128.258 89.3857 128.941 89.3857C129.625 89.3857 130.308 90.0698 130.308 90.7539C130.308 91.4379 129.625 92.122 128.941 92.122Z" fill={ color }/>
  <path d="M123.015 92.122C122.332 92.122 121.648 91.4379 121.648 90.7539C121.648 90.0698 122.332 89.3857 123.015 89.3857C123.699 89.3857 124.382 90.0698 124.382 90.7539C124.154 91.4379 123.699 92.122 123.015 92.122Z" fill={ color }/>
  <path d="M116.866 92.1223C116.182 92.1223 115.727 91.6663 115.727 90.9822C115.727 90.2981 116.182 89.8421 116.866 89.8421C117.549 89.8421 118.005 90.2981 118.005 90.9822C118.232 91.4382 117.549 92.1223 116.866 92.1223Z" fill={ color }/>
  <path d="M110.944 91.8936C110.26 91.8936 109.805 91.4375 109.805 90.7534C109.805 90.0694 110.26 89.6133 110.944 89.6133C111.627 89.6133 112.083 90.0694 112.083 90.7534C112.083 91.4375 111.627 91.8936 110.944 91.8936Z" fill={ color }/>
  <path d="M105.022 91.8936C104.338 91.8936 103.883 91.4375 103.883 90.7534C103.883 90.0694 104.338 89.6133 105.022 89.6133C105.705 89.6133 106.161 90.0694 106.161 90.7534C106.161 91.4375 105.477 91.8936 105.022 91.8936Z" fill={ color }/>
  <path d="M98.8695 91.8936C98.1861 91.8936 97.7305 91.4375 97.7305 90.7534C97.7305 90.0694 98.1861 89.6133 98.8695 89.6133C99.553 89.6133 100.009 90.0694 100.009 90.7534C100.009 91.4375 99.553 91.8936 98.8695 91.8936Z" fill={ color }/>
  <path d="M92.9464 91.6663C92.4908 91.6663 92.0352 91.2102 92.0352 90.7542C92.0352 90.2981 92.4908 89.8421 92.9464 89.8421C93.402 89.8421 93.8576 90.2981 93.8576 90.7542C93.8576 91.2102 93.402 91.6663 92.9464 91.6663Z" fill={ color }/>
  <path d="M87.0245 91.6663C86.5689 91.6663 86.1133 91.2102 86.1133 90.7542C86.1133 90.2981 86.5689 89.8421 87.0245 89.8421C87.4801 89.8421 87.9358 90.2981 87.9358 90.7542C87.9358 91.2102 87.4801 91.6663 87.0245 91.6663Z" fill={ color }/>
  <path d="M81.1027 91.6663C80.647 91.6663 80.1914 91.2102 80.1914 90.7542C80.1914 90.2981 80.647 89.8421 81.1027 89.8421C81.5583 89.8421 82.0139 90.2981 82.0139 90.7542C81.7861 91.2102 81.5583 91.6663 81.1027 91.6663Z" fill={ color }/>
  <path d="M74.949 91.4366C74.4934 91.4366 74.2656 91.2086 74.2656 90.7526C74.2656 90.2965 74.4934 90.0685 74.949 90.0685C75.4047 90.0685 75.6325 90.2965 75.6325 90.7526C75.8603 91.2086 75.4047 91.4366 74.949 91.4366Z" fill={ color }/>
  <path d="M69.0272 91.4366C68.5716 91.4366 68.3438 91.2086 68.3438 90.7526C68.3438 90.2965 68.5716 90.0685 69.0272 90.0685C69.4828 90.0685 69.7106 90.2965 69.7106 90.7526C69.7106 91.2086 69.4828 91.4366 69.0272 91.4366Z" fill={ color }/>
  <path d="M63.1053 91.4366C62.8775 91.4366 62.4219 91.2086 62.4219 90.7526C62.4219 90.5245 62.6497 90.0685 63.1053 90.0685C63.3331 90.0685 63.7887 90.2965 63.7887 90.7526C63.5609 91.2086 63.3331 91.4366 63.1053 91.4366Z" fill={ color }/>
  <path d="M56.9556 91.2093C56.7278 91.2093 56.5 90.9813 56.5 90.7533C56.5 90.5253 56.7278 90.2973 56.9556 90.2973C57.1834 90.2973 57.4112 90.5253 57.4112 90.7533C57.639 90.9813 57.4112 91.2093 56.9556 91.2093Z" fill={ color }/>
  <path d="M51.0298 91.2093C50.802 91.2093 50.5742 90.9813 50.5742 90.7533C50.5742 90.5253 50.802 90.2973 51.0298 90.2973C51.2576 90.2973 51.4855 90.5253 51.4855 90.7533C51.4855 90.9813 51.2576 91.2093 51.0298 91.2093Z" fill={ color }/>
  <path d="M45.108 91.2093C44.8802 91.2093 44.6523 90.9813 44.6523 90.7533C44.6523 90.5253 44.8802 90.2973 45.108 90.2973C45.3358 90.2973 45.5636 90.5253 45.5636 90.7533C45.3358 90.9813 45.3358 91.2093 45.108 91.2093Z" fill={ color }/>
  <path d="M38.9544 90.9809C38.7266 90.9809 38.7266 90.9809 38.7266 90.7529C38.7266 90.5248 38.7266 90.5248 38.9544 90.5248C39.1822 90.5248 39.1822 90.5248 39.1822 90.7529C39.41 90.9809 39.1822 90.9809 38.9544 90.9809Z" fill={ color }/>
  <path d="M33.0309 90.9809C33.0309 90.9809 32.8031 90.9809 33.0309 90.9809C32.8031 90.7529 33.0309 90.5248 33.0309 90.5248C33.2587 90.5248 33.2587 90.7529 33.0309 90.9809C33.2587 90.9809 33.2587 90.9809 33.0309 90.9809Z" fill={ color }/>
  <path d="M311.646 97.1384C311.418 97.1384 311.418 97.1384 311.418 96.9104C311.418 96.6824 311.418 96.6824 311.646 96.6824C311.874 96.6824 311.874 96.6824 311.874 96.9104C311.874 96.9104 311.874 97.1384 311.646 97.1384Z" fill={ color }/>
  <path d="M305.722 97.3654C306.1 97.3654 306.406 97.0592 306.406 96.6814C306.406 96.3036 306.1 95.9973 305.722 95.9973C305.345 95.9973 305.039 96.3036 305.039 96.6814C305.039 97.0592 305.345 97.3654 305.722 97.3654Z" fill={ color }/>
  <path d="M299.571 97.5939C300.075 97.5939 300.483 97.1855 300.483 96.6818C300.483 96.1781 300.075 95.7697 299.571 95.7697C299.068 95.7697 298.66 96.1781 298.66 96.6818C298.66 97.1855 299.068 97.5939 299.571 97.5939Z" fill={ color }/>
  <path d="M293.647 97.8224C292.963 97.8224 292.508 97.3663 292.508 96.6822C292.508 95.9982 292.963 95.5421 293.647 95.5421C294.33 95.5421 294.786 95.9982 294.786 96.6822C294.786 97.3663 294.33 97.8224 293.647 97.8224Z" fill={ color }/>
  <path d="M287.725 98.0499C287.042 98.0499 286.586 97.5939 286.586 96.9098C286.586 96.2258 287.042 95.7697 287.725 95.7697C288.408 95.7697 288.864 96.2258 288.864 96.9098C288.864 97.3659 288.408 98.0499 287.725 98.0499Z" fill={ color }/>
  <path d="M281.8 98.0508C281.117 98.0508 280.434 97.3668 280.434 96.6827C280.434 95.9986 281.117 95.3145 281.8 95.3145C282.484 95.3145 283.167 95.9986 283.167 96.6827C283.167 97.5948 282.484 98.0508 281.8 98.0508Z" fill={ color }/>
  <path d="M275.652 98.0508C276.407 98.0508 277.019 97.4383 277.019 96.6827C277.019 95.9271 276.407 95.3145 275.652 95.3145C274.897 95.3145 274.285 95.9271 274.285 96.6827C274.285 97.4383 274.897 98.0508 275.652 98.0508Z" fill={ color }/>
  <path d="M269.727 98.2781C270.608 98.2781 271.322 97.5635 271.322 96.6819C271.322 95.8004 270.608 95.0858 269.727 95.0858C268.847 95.0858 268.133 95.8004 268.133 96.6819C268.133 97.5635 268.847 98.2781 269.727 98.2781Z" fill={ color }/>
  <path d="M263.806 98.5069C262.894 98.5069 262.211 97.8228 262.211 96.9107C262.211 95.9986 262.894 95.3145 263.806 95.3145C264.717 95.3145 265.4 95.9986 265.4 96.9107C265.4 97.5948 264.717 98.5069 263.806 98.5069Z" fill={ color }/>
  <path d="M257.653 98.2781C258.534 98.2781 259.248 97.5635 259.248 96.6819C259.248 95.8004 258.534 95.0858 257.653 95.0858C256.773 95.0858 256.059 95.8004 256.059 96.6819C256.059 97.5635 256.773 98.2781 257.653 98.2781Z" fill={ color }/>
  <path d="M251.731 98.5069C250.82 98.5069 250.137 97.8228 250.137 96.9107C250.137 95.9986 250.82 95.3145 251.731 95.3145C252.643 95.3145 253.326 95.9986 253.326 96.9107C253.554 97.8228 252.643 98.5069 251.731 98.5069Z" fill={ color }/>
  <path d="M245.807 98.5066C244.896 98.5066 243.984 97.8225 243.984 96.6824C243.984 95.5423 244.668 94.8582 245.807 94.8582C246.946 94.8582 247.629 95.5423 247.629 96.6824C247.629 97.8225 246.718 98.5066 245.807 98.5066Z" fill={ color }/>
  <path d="M239.655 98.5066C238.743 98.5066 237.832 97.8225 237.832 96.6824C237.832 95.5423 238.515 94.8582 239.655 94.8582C240.794 94.8582 241.477 95.5423 241.477 96.6824C241.477 97.8225 240.794 98.5066 239.655 98.5066Z" fill={ color }/>
  <path d="M233.733 98.5066C234.739 98.5066 235.555 97.6898 235.555 96.6824C235.555 95.6749 234.739 94.8582 233.733 94.8582C232.726 94.8582 231.91 95.6749 231.91 96.6824C231.91 97.6898 232.726 98.5066 233.733 98.5066Z" fill={ color }/>
  <path d="M227.811 98.5066C226.9 98.5066 225.988 97.8225 225.988 96.6824C225.988 95.7703 226.672 94.8582 227.811 94.8582C228.722 94.8582 229.633 95.5423 229.633 96.6824C229.633 97.8225 228.722 98.5066 227.811 98.5066Z" fill={ color }/>
  <path d="M221.889 98.5066C222.895 98.5066 223.711 97.6898 223.711 96.6824C223.711 95.6749 222.895 94.8582 221.889 94.8582C220.882 94.8582 220.066 95.6749 220.066 96.6824C220.066 97.6898 220.882 98.5066 221.889 98.5066Z" fill={ color }/>
  <path d="M215.737 98.5061C214.825 98.5061 213.914 97.8221 213.914 96.6819C213.914 95.5418 214.825 95.0858 215.737 95.0858C216.648 95.0858 217.559 95.7699 217.559 96.91C217.559 98.0501 216.648 98.5061 215.737 98.5061Z" fill={ color }/>
  <path d="M209.815 98.5066C210.821 98.5066 211.637 97.6898 211.637 96.6824C211.637 95.6749 210.821 94.8582 209.815 94.8582C208.808 94.8582 207.992 95.6749 207.992 96.6824C207.992 97.6898 208.808 98.5066 209.815 98.5066Z" fill={ color }/>
  <path d="M203.892 98.5069C202.98 98.5069 202.297 97.8228 202.297 96.9107C202.297 95.9986 202.98 95.3145 203.892 95.3145C204.803 95.3145 205.486 95.9986 205.486 96.9107C205.486 97.8228 204.803 98.5069 203.892 98.5069Z" fill={ color }/>
  <path d="M197.739 98.2781C198.62 98.2781 199.334 97.5635 199.334 96.6819C199.334 95.8004 198.62 95.0858 197.739 95.0858C196.858 95.0858 196.145 95.8004 196.145 96.6819C196.145 97.5635 196.858 98.2781 197.739 98.2781Z" fill={ color }/>
  <path d="M191.817 98.2781C192.698 98.2781 193.412 97.5635 193.412 96.6819C193.412 95.8004 192.698 95.0858 191.817 95.0858C190.937 95.0858 190.223 95.8004 190.223 96.6819C190.223 97.5635 190.937 98.2781 191.817 98.2781Z" fill={ color }/>
  <path d="M185.895 98.5069C184.984 98.5069 184.301 97.8228 184.301 96.9107C184.301 95.9986 184.984 95.3145 185.895 95.3145C186.807 95.3145 187.49 95.9986 187.49 96.9107C187.49 97.8228 186.807 98.5069 185.895 98.5069Z" fill={ color }/>
  <path d="M179.743 98.2781C178.832 98.2781 178.148 97.594 178.148 96.6819C178.148 95.7699 178.832 95.0858 179.743 95.0858C180.654 95.0858 181.338 95.7699 181.338 96.6819C181.338 97.594 180.654 98.2781 179.743 98.2781Z" fill={ color }/>
  <path d="M173.821 98.2781C172.91 98.2781 172.227 97.594 172.227 96.6819C172.227 95.7699 172.91 95.0858 173.821 95.0858C174.732 95.0858 175.416 95.7699 175.416 96.6819C175.416 97.594 174.732 98.2781 173.821 98.2781Z" fill={ color }/>
  <path d="M167.895 98.2781C166.984 98.2781 166.301 97.594 166.301 96.6819C166.301 95.7699 166.984 95.0858 167.895 95.0858C168.807 95.0858 169.49 95.7699 169.49 96.6819C169.262 97.594 168.807 98.2781 167.895 98.2781Z" fill={ color }/>
  <path d="M161.972 98.2784C161.061 98.2784 160.605 97.5943 160.605 96.9103C160.605 96.2262 161.289 95.5421 161.972 95.5421C162.884 95.5421 163.339 96.2262 163.339 96.9103C163.339 97.5943 162.656 98.2784 161.972 98.2784Z" fill={ color }/>
  <path d="M155.824 98.2784C155.14 98.2784 154.457 97.5943 154.457 96.9103C154.457 96.2262 155.14 95.5421 155.824 95.5421C156.507 95.5421 157.191 96.2262 157.191 96.9103C157.191 97.5943 156.735 98.2784 155.824 98.2784Z" fill={ color }/>
  <path d="M149.898 98.0508C150.653 98.0508 151.265 97.4383 151.265 96.6827C151.265 95.9271 150.653 95.3145 149.898 95.3145C149.143 95.3145 148.531 95.9271 148.531 96.6827C148.531 97.4383 149.143 98.0508 149.898 98.0508Z" fill={ color }/>
  <path d="M143.976 98.0508C143.293 98.0508 142.609 97.3668 142.609 96.6827C142.609 95.9986 143.293 95.3145 143.976 95.3145C144.66 95.3145 145.343 95.9986 145.343 96.6827C145.343 97.3668 144.66 98.0508 143.976 98.0508Z" fill={ color }/>
  <path d="M137.824 98.0508C137.14 98.0508 136.457 97.5948 136.457 96.6827C136.457 95.9986 136.913 95.3145 137.824 95.3145C138.735 95.3145 139.191 95.7706 139.191 96.6827C139.191 97.5948 138.507 98.0508 137.824 98.0508Z" fill={ color }/>
  <path d="M131.901 97.8224C132.53 97.8224 133.04 97.3119 133.04 96.6822C133.04 96.0526 132.53 95.5421 131.901 95.5421C131.272 95.5421 130.762 96.0526 130.762 96.6822C130.762 97.3119 131.272 97.8224 131.901 97.8224Z" fill={ color }/>
  <path d="M125.979 97.8224C125.295 97.8224 124.84 97.3663 124.84 96.6822C124.84 95.9982 125.295 95.5421 125.979 95.5421C126.662 95.5421 127.118 95.9982 127.118 96.6822C127.118 97.3663 126.662 97.8224 125.979 97.8224Z" fill={ color }/>
  <path d="M119.827 97.8224C120.456 97.8224 120.966 97.3119 120.966 96.6822C120.966 96.0526 120.456 95.5421 119.827 95.5421C119.197 95.5421 118.688 96.0526 118.688 96.6822C118.688 97.3119 119.197 97.8224 119.827 97.8224Z" fill={ color }/>
  <path d="M113.903 97.8215C113.448 97.8215 112.992 97.3654 112.992 96.9094C112.992 96.4533 113.448 95.9973 113.903 95.9973C114.359 95.9973 114.815 96.4533 114.815 96.9094C114.815 97.3654 114.587 97.8215 113.903 97.8215Z" fill={ color }/>
  <path d="M107.982 97.8215C107.526 97.8215 107.07 97.3654 107.07 96.9094C107.07 96.4533 107.526 95.9973 107.982 95.9973C108.437 95.9973 108.893 96.4533 108.893 96.9094C108.893 97.3654 108.437 97.8215 107.982 97.8215Z" fill={ color }/>
  <path d="M102.06 97.5939C101.604 97.5939 101.148 97.1379 101.148 96.6818C101.148 96.2258 101.604 95.7697 102.06 95.7697C102.515 95.7697 102.971 96.2258 102.971 96.6818C102.971 97.3659 102.515 97.5939 102.06 97.5939Z" fill={ color }/>
  <path d="M95.9073 97.5939C95.4517 97.5939 94.9961 97.1379 94.9961 96.6818C94.9961 96.2258 95.4517 95.7697 95.9073 95.7697C96.363 95.7697 96.8186 96.2258 96.8186 96.6818C96.8186 97.1379 96.363 97.5939 95.9073 97.5939Z" fill={ color }/>
  <path d="M89.9842 97.5942C89.5286 97.5942 89.3008 97.3662 89.3008 96.9101C89.3008 96.4541 89.5286 96.2261 89.9842 96.2261C90.4398 96.2261 90.6676 96.4541 90.6676 96.9101C90.6676 97.3662 90.4398 97.5942 89.9842 97.5942Z" fill={ color }/>
  <path d="M84.0623 97.3654C84.4398 97.3654 84.7458 97.0592 84.7458 96.6814C84.7458 96.3036 84.4398 95.9973 84.0623 95.9973C83.6849 95.9973 83.3789 96.3036 83.3789 96.6814C83.3789 97.0592 83.6849 97.3654 84.0623 97.3654Z" fill={ color }/>
  <path d="M77.91 97.3654C77.4544 97.3654 77.2266 97.1374 77.2266 96.6814C77.2266 96.2253 77.4544 95.9973 77.91 95.9973C78.3656 95.9973 78.5934 96.2253 78.5934 96.6814C78.5934 97.1374 78.3656 97.3654 77.91 97.3654Z" fill={ color }/>
  <path d="M71.9869 97.1381C72.2385 97.1381 72.4425 96.934 72.4425 96.6821C72.4425 96.4302 72.2385 96.2261 71.9869 96.2261C71.7352 96.2261 71.5312 96.4302 71.5312 96.6821C71.5312 96.934 71.7352 97.1381 71.9869 97.1381Z" fill={ color }/>
  <path d="M66.065 97.1381C66.3166 97.1381 66.5206 96.934 66.5206 96.6821C66.5206 96.4302 66.3166 96.2261 66.065 96.2261C65.8134 96.2261 65.6094 96.4302 65.6094 96.6821C65.6094 96.934 65.8134 97.1381 66.065 97.1381Z" fill={ color }/>
  <path d="M59.9166 97.1381C60.1682 97.1381 60.3722 96.934 60.3722 96.6821C60.3722 96.4302 60.1682 96.2261 59.9166 96.2261C59.6649 96.2261 59.4609 96.4302 59.4609 96.6821C59.4609 96.934 59.6649 97.1381 59.9166 97.1381Z" fill={ color }/>
  <path d="M53.9895 97.1384C53.7617 97.1384 53.7617 96.9104 53.7617 96.9104C53.7617 96.6824 53.9895 96.6824 53.9895 96.6824C54.2173 96.6824 54.2173 96.9104 54.2173 96.9104C54.4452 96.9104 54.2173 97.1384 53.9895 97.1384Z" fill={ color }/>
  <path d="M48.0677 96.9097C48.1935 96.9097 48.2955 96.8076 48.2955 96.6817C48.2955 96.5557 48.1935 96.4536 48.0677 96.4536C47.9418 96.4536 47.8398 96.5557 47.8398 96.6817C47.8398 96.8076 47.9418 96.9097 48.0677 96.9097Z" fill={ color }/>
  <path d="M42.1435 96.9104C41.9157 96.9104 41.9157 96.9104 42.1435 96.9104C41.9157 96.6824 42.1435 96.6824 42.1435 96.6824C42.1435 96.6824 42.1435 96.6824 42.1435 96.9104Z" fill={ color }/>
  <path d="M296.611 103.066C296.383 103.066 296.383 103.066 296.383 102.838C296.383 102.61 296.383 102.61 296.611 102.61C296.838 102.61 296.838 102.61 296.838 102.838C296.838 102.838 296.838 103.066 296.611 103.066Z" fill={ color }/>
  <path d="M290.686 103.295C290.458 103.295 290.23 103.066 290.23 102.838C290.23 102.61 290.458 102.382 290.686 102.382C290.914 102.382 291.142 102.61 291.142 102.838C291.142 103.066 290.914 103.295 290.686 103.295Z" fill={ color }/>
  <path d="M284.762 103.523C284.306 103.523 284.078 103.295 284.078 102.839C284.078 102.383 284.306 102.155 284.762 102.155C285.217 102.155 285.445 102.383 285.445 102.839C285.445 103.067 284.989 103.523 284.762 103.523Z" fill={ color }/>
  <path d="M278.61 103.523C278.155 103.523 277.699 103.067 277.699 102.611C277.699 102.155 278.155 101.699 278.61 101.699C279.066 101.699 279.522 102.155 279.522 102.611C279.522 103.295 279.066 103.523 278.61 103.523Z" fill={ color }/>
  <path d="M272.689 103.75C272.233 103.75 271.777 103.294 271.777 102.838C271.777 102.382 272.233 101.926 272.689 101.926C273.144 101.926 273.6 102.382 273.6 102.838C273.6 103.294 273.144 103.75 272.689 103.75Z" fill={ color }/>
  <path d="M266.768 103.75C266.085 103.75 265.629 103.294 265.629 102.61C265.629 101.926 266.085 101.47 266.768 101.47C267.451 101.47 267.907 101.926 267.907 102.61C267.679 103.294 267.224 103.75 266.768 103.75Z" fill={ color }/>
  <path d="M260.842 103.979C260.159 103.979 259.703 103.523 259.703 102.839C259.703 102.155 260.159 101.699 260.842 101.699C261.526 101.699 261.981 102.155 261.981 102.839C261.981 103.523 261.298 103.979 260.842 103.979Z" fill={ color }/>
  <path d="M254.694 103.979C254.01 103.979 253.555 103.523 253.555 102.839C253.555 102.155 254.01 101.699 254.694 101.699C255.377 101.699 255.833 102.155 255.833 102.839C256.061 103.523 255.377 103.979 254.694 103.979Z" fill={ color }/>
  <path d="M248.769 103.98C248.086 103.98 247.402 103.524 247.402 102.611C247.402 101.927 247.858 101.243 248.769 101.243C249.68 101.243 250.136 101.699 250.136 102.611C249.908 103.524 249.453 103.98 248.769 103.98Z" fill={ color }/>
  <path d="M242.847 103.98C242.164 103.98 241.48 103.296 241.48 102.611C241.48 101.927 242.164 101.243 242.847 101.243C243.531 101.243 244.214 101.927 244.214 102.611C243.986 103.524 243.531 103.98 242.847 103.98Z" fill={ color }/>
  <path d="M236.695 104.206C236.012 104.206 235.328 103.522 235.328 102.838C235.328 102.154 236.012 101.47 236.695 101.47C237.378 101.47 238.062 102.154 238.062 102.838C238.062 103.522 237.606 104.206 236.695 104.206Z" fill={ color }/>
  <path d="M230.773 104.206C230.09 104.206 229.406 103.522 229.406 102.838C229.406 102.154 230.09 101.47 230.773 101.47C231.457 101.47 232.14 102.154 232.14 102.838C232.14 103.522 231.457 104.206 230.773 104.206Z" fill={ color }/>
  <path d="M224.847 104.206C224.164 104.206 223.48 103.522 223.48 102.838C223.48 102.154 224.164 101.47 224.847 101.47C225.531 101.47 226.214 102.154 226.214 102.838C226.214 103.522 225.531 104.206 224.847 104.206Z" fill={ color }/>
  <path d="M218.699 104.206C218.015 104.206 217.332 103.522 217.332 102.838C217.332 102.154 218.015 101.47 218.699 101.47C219.382 101.47 220.066 102.154 220.066 102.838C220.066 103.522 219.61 104.206 218.699 104.206Z" fill={ color }/>
  <path d="M212.777 104.206C212.094 104.206 211.41 103.522 211.41 102.838C211.41 102.154 212.094 101.47 212.777 101.47C213.46 101.47 214.144 102.154 214.144 102.838C214.144 103.522 213.46 104.206 212.777 104.206Z" fill={ color }/>
  <path d="M206.851 104.206C206.168 104.206 205.484 103.522 205.484 102.838C205.484 102.154 206.168 101.47 206.851 101.47C207.535 101.47 208.218 102.154 208.218 102.838C208.218 103.522 207.535 104.206 206.851 104.206Z" fill={ color }/>
  <path d="M200.929 104.206C200.246 104.206 199.562 103.522 199.562 102.838C199.562 102.154 200.246 101.47 200.929 101.47C201.613 101.47 202.296 102.154 202.296 102.838C202.068 103.522 201.613 104.206 200.929 104.206Z" fill={ color }/>
  <path d="M194.777 104.206C194.094 104.206 193.41 103.522 193.41 102.838C193.41 102.154 194.094 101.47 194.777 101.47C195.46 101.47 196.144 102.154 196.144 102.838C196.144 103.522 195.46 104.206 194.777 104.206Z" fill={ color }/>
  <path d="M188.855 103.98C188.172 103.98 187.488 103.296 187.488 102.611C187.488 101.927 188.172 101.243 188.855 101.243C189.539 101.243 190.222 101.927 190.222 102.611C190.222 103.524 189.539 103.98 188.855 103.98Z" fill={ color }/>
  <path d="M182.933 103.98C182.25 103.98 181.566 103.524 181.566 102.611C181.566 101.927 182.022 101.243 182.933 101.243C183.617 101.243 184.3 101.699 184.3 102.611C184.072 103.524 183.617 103.98 182.933 103.98Z" fill={ color }/>
  <path d="M176.784 103.979C176.1 103.979 175.645 103.523 175.645 102.839C175.645 102.155 176.1 101.699 176.784 101.699C177.467 101.699 177.923 102.155 177.923 102.839C178.15 103.523 177.467 103.979 176.784 103.979Z" fill={ color }/>
  <path d="M170.858 103.979C170.174 103.979 169.719 103.523 169.719 102.839C169.719 102.155 170.174 101.699 170.858 101.699C171.541 101.699 171.997 102.155 171.997 102.839C171.997 103.523 171.541 103.979 170.858 103.979Z" fill={ color }/>
  <path d="M164.936 103.979C164.252 103.979 163.797 103.523 163.797 102.839C163.797 102.155 164.252 101.699 164.936 101.699C165.619 101.699 166.075 102.155 166.075 102.839C166.075 103.523 165.619 103.979 164.936 103.979Z" fill={ color }/>
  <path d="M158.784 103.979C158.1 103.979 157.645 103.523 157.645 102.839C157.645 102.155 158.1 101.699 158.784 101.699C159.467 101.699 159.923 102.155 159.923 102.839C159.923 103.295 159.467 103.979 158.784 103.979Z" fill={ color }/>
  <path d="M152.862 103.75C152.178 103.75 151.723 103.294 151.723 102.61C151.723 101.926 152.178 101.47 152.862 101.47C153.545 101.47 154.001 101.926 154.001 102.61C154.001 103.294 153.545 103.75 152.862 103.75Z" fill={ color }/>
  <path d="M146.94 103.75C146.256 103.75 145.801 103.294 145.801 102.61C145.801 101.926 146.256 101.47 146.94 101.47C147.623 101.47 148.079 101.926 148.079 102.61C147.851 103.294 147.395 103.75 146.94 103.75Z" fill={ color }/>
  <path d="M141.017 103.75C140.561 103.75 140.105 103.294 140.105 102.838C140.105 102.382 140.561 101.926 141.017 101.926C141.472 101.926 141.928 102.382 141.928 102.838C141.928 103.294 141.472 103.75 141.017 103.75Z" fill={ color }/>
  <path d="M134.864 103.75C134.409 103.75 133.953 103.294 133.953 102.838C133.953 102.382 134.409 101.926 134.864 101.926C135.32 101.926 135.776 102.382 135.776 102.838C135.776 103.294 135.32 103.75 134.864 103.75Z" fill={ color }/>
  <path d="M128.942 103.75C128.487 103.75 128.031 103.294 128.031 102.838C128.031 102.382 128.487 101.926 128.942 101.926C129.398 101.926 129.854 102.382 129.854 102.838C129.854 103.294 129.398 103.75 128.942 103.75Z" fill={ color }/>
  <path d="M123.017 103.523C122.561 103.523 122.105 103.067 122.105 102.611C122.105 102.155 122.561 101.699 123.017 101.699C123.472 101.699 123.928 102.155 123.928 102.611C123.7 103.295 123.472 103.523 123.017 103.523Z" fill={ color }/>
  <path d="M116.867 103.523C116.411 103.523 116.184 103.067 116.184 102.839C116.184 102.383 116.639 102.155 116.867 102.155C117.095 102.155 117.55 102.611 117.55 102.839C117.778 103.295 117.323 103.523 116.867 103.523Z" fill={ color }/>
  <path d="M110.945 103.523C110.49 103.523 110.262 103.295 110.262 102.839C110.262 102.383 110.49 102.155 110.945 102.155C111.401 102.155 111.629 102.383 111.629 102.839C111.629 103.067 111.401 103.523 110.945 103.523Z" fill={ color }/>
  <path d="M105.019 103.523C104.564 103.523 104.336 103.295 104.336 102.839C104.336 102.383 104.564 102.155 105.019 102.155C105.475 102.155 105.703 102.383 105.703 102.839C105.703 103.067 105.247 103.523 105.019 103.523Z" fill={ color }/>
  <path d="M98.8709 103.294C98.6431 103.294 98.1875 103.066 98.1875 102.61C98.1875 102.382 98.4153 101.926 98.8709 101.926C99.0987 101.926 99.5544 102.154 99.5544 102.61C99.5544 103.066 99.3266 103.294 98.8709 103.294Z" fill={ color }/>
  <path d="M92.9478 103.295C92.72 103.295 92.4922 103.066 92.4922 102.838C92.4922 102.61 92.72 102.382 92.9478 102.382C93.1756 102.382 93.4034 102.61 93.4034 102.838C93.4034 103.066 93.1756 103.295 92.9478 103.295Z" fill={ color }/>
  <path d="M87.0259 103.295C86.7981 103.295 86.5703 103.066 86.5703 102.838C86.5703 102.61 86.7981 102.382 87.0259 102.382C87.2537 102.382 87.4816 102.61 87.4816 102.838C87.4816 103.066 87.2537 103.295 87.0259 103.295Z" fill={ color }/>
  <path d="M81.1002 103.067C80.8723 103.067 80.6445 102.839 80.6445 102.611C80.6445 102.383 80.8723 102.155 81.1002 102.155C81.328 102.155 81.5558 102.383 81.5558 102.611C81.328 103.067 81.1002 103.067 81.1002 103.067Z" fill={ color }/>
  <path d="M74.9505 103.066C74.7227 103.066 74.7227 102.838 74.7227 102.838C74.7227 102.61 74.9505 102.61 74.9505 102.61C75.1783 102.61 75.1783 102.838 75.1783 102.838C75.4061 102.838 75.1783 103.066 74.9505 103.066Z" fill={ color }/>
  <path d="M69.0286 103.066C68.8008 103.066 68.8008 103.066 68.8008 102.838C68.8008 102.61 68.8008 102.61 69.0286 102.61C69.2564 102.61 69.2564 102.61 69.2564 102.838C69.2564 103.066 69.2564 103.066 69.0286 103.066Z" fill={ color }/>
  <path d="M63.1045 102.838C62.8766 102.838 62.8766 102.838 63.1045 102.838C62.8766 102.61 63.1045 102.61 63.1045 102.61C63.1045 102.61 63.1045 102.61 63.1045 102.838Z" fill={ color }/>
  <path d="M56.9531 102.839C56.9531 102.839 56.9531 102.611 56.9531 102.839C57.1809 102.611 57.1809 102.839 56.9531 102.839C57.1809 102.839 57.1809 102.839 56.9531 102.839Z" fill={ color }/>
  <path d="M269.729 108.995C269.502 108.995 269.502 108.995 269.729 108.995C269.502 108.539 269.502 108.539 269.729 108.539C269.957 108.539 269.957 108.539 269.729 108.995C269.957 108.995 269.957 108.995 269.729 108.995Z" fill={ color }/>
  <path d="M263.803 109.223C263.575 109.223 263.348 108.995 263.348 108.767C263.348 108.539 263.575 108.311 263.803 108.311C264.031 108.311 264.259 108.539 264.259 108.767C264.031 108.995 264.031 109.223 263.803 109.223Z" fill={ color }/>
  <path d="M257.655 109.223C257.427 109.223 257.199 108.995 257.199 108.767C257.199 108.539 257.427 108.311 257.655 108.311C257.883 108.311 258.11 108.539 258.11 108.767C258.338 108.995 258.11 109.223 257.655 109.223Z" fill={ color }/>
  <path d="M251.73 109.452C251.503 109.452 251.047 109.224 251.047 108.768C251.047 108.54 251.275 108.084 251.73 108.084C251.958 108.084 252.414 108.312 252.414 108.768C252.414 108.996 252.186 109.452 251.73 109.452Z" fill={ color }/>
  <path d="M245.808 109.452C245.353 109.452 245.125 109.224 245.125 108.768C245.125 108.312 245.353 108.084 245.808 108.084C246.264 108.084 246.492 108.312 246.492 108.768C246.492 109.224 246.036 109.452 245.808 109.452Z" fill={ color }/>
  <path d="M239.656 109.452C239.2 109.452 238.973 109.224 238.973 108.768C238.973 108.312 239.2 108.084 239.656 108.084C240.112 108.084 240.34 108.312 240.34 108.768C240.567 109.224 240.112 109.452 239.656 109.452Z" fill={ color }/>
  <path d="M233.735 109.679C234.239 109.679 234.647 109.271 234.647 108.767C234.647 108.263 234.239 107.855 233.735 107.855C233.232 107.855 232.824 108.263 232.824 108.767C232.824 109.271 233.232 109.679 233.735 109.679Z" fill={ color }/>
  <path d="M227.81 109.679C227.354 109.679 226.898 109.223 226.898 108.767C226.898 108.311 227.354 107.855 227.81 107.855C228.265 107.855 228.721 108.311 228.721 108.767C228.721 109.223 228.265 109.679 227.81 109.679Z" fill={ color }/>
  <path d="M221.888 109.679C222.391 109.679 222.799 109.271 222.799 108.767C222.799 108.263 222.391 107.855 221.888 107.855C221.385 107.855 220.977 108.263 220.977 108.767C220.977 109.271 221.385 109.679 221.888 109.679Z" fill={ color }/>
  <path d="M215.735 109.679C215.28 109.679 214.824 109.223 214.824 108.767C214.824 108.311 215.28 107.855 215.735 107.855C216.191 107.855 216.647 108.311 216.647 108.767C216.647 109.223 216.191 109.679 215.735 109.679Z" fill={ color }/>
  <path d="M209.814 109.679C210.317 109.679 210.725 109.271 210.725 108.767C210.725 108.263 210.317 107.855 209.814 107.855C209.31 107.855 208.902 108.263 208.902 108.767C208.902 109.271 209.31 109.679 209.814 109.679Z" fill={ color }/>
  <path d="M203.892 109.679C203.436 109.679 202.98 109.223 202.98 108.767C202.98 108.311 203.436 107.855 203.892 107.855C204.347 107.855 204.803 108.311 204.803 108.767C204.803 109.223 204.347 109.679 203.892 109.679Z" fill={ color }/>
  <path d="M197.739 109.679C198.243 109.679 198.651 109.271 198.651 108.767C198.651 108.263 198.243 107.855 197.739 107.855C197.236 107.855 196.828 108.263 196.828 108.767C196.828 109.271 197.236 109.679 197.739 109.679Z" fill={ color }/>
  <path d="M191.817 109.679C191.362 109.679 190.906 109.223 190.906 108.767C190.906 108.311 191.362 107.855 191.817 107.855C192.273 107.855 192.729 108.311 192.729 108.767C192.729 109.223 192.273 109.679 191.817 109.679Z" fill={ color }/>
  <path d="M185.896 109.679C186.399 109.679 186.807 109.271 186.807 108.767C186.807 108.263 186.399 107.855 185.896 107.855C185.392 107.855 184.984 108.263 184.984 108.767C184.984 109.271 185.392 109.679 185.896 109.679Z" fill={ color }/>
  <path d="M179.743 109.679C179.288 109.679 178.832 109.223 178.832 108.767C178.832 108.311 179.288 107.855 179.743 107.855C180.199 107.855 180.654 108.311 180.654 108.767C180.654 109.223 180.427 109.679 179.743 109.679Z" fill={ color }/>
  <path d="M173.817 109.679C173.362 109.679 172.906 109.223 172.906 108.767C172.906 108.311 173.362 107.855 173.817 107.855C174.273 107.855 174.729 108.311 174.729 108.767C174.729 109.223 174.273 109.679 173.817 109.679Z" fill={ color }/>
  <path d="M167.896 109.679C167.44 109.679 166.984 109.223 166.984 108.767C166.984 108.311 167.44 107.855 167.896 107.855C168.351 107.855 168.807 108.311 168.807 108.767C168.807 109.223 168.351 109.679 167.896 109.679Z" fill={ color }/>
  <path d="M161.974 109.679C161.518 109.679 161.062 109.223 161.062 108.767C161.062 108.311 161.518 107.855 161.974 107.855C162.429 107.855 162.885 108.311 162.885 108.767C162.657 109.223 162.429 109.679 161.974 109.679Z" fill={ color }/>
  <path d="M155.824 109.452C155.368 109.452 155.141 108.996 155.141 108.768C155.141 108.312 155.596 108.084 155.824 108.084C156.052 108.084 156.507 108.54 156.507 108.768C156.735 109.224 156.28 109.452 155.824 109.452Z" fill={ color }/>
  <path d="M149.898 109.452C150.276 109.452 150.582 109.146 150.582 108.768C150.582 108.39 150.276 108.084 149.898 108.084C149.521 108.084 149.215 108.39 149.215 108.768C149.215 109.146 149.521 109.452 149.898 109.452Z" fill={ color }/>
  <path d="M143.976 109.452C144.354 109.452 144.66 109.146 144.66 108.768C144.66 108.39 144.354 108.084 143.976 108.084C143.599 108.084 143.293 108.39 143.293 108.768C143.293 109.146 143.599 109.452 143.976 109.452Z" fill={ color }/>
  <path d="M137.828 109.452C137.372 109.452 137.145 109.224 137.145 108.768C137.145 108.312 137.372 108.084 137.828 108.084C138.284 108.084 138.511 108.312 138.511 108.768C138.511 109.224 138.284 109.452 137.828 109.452Z" fill={ color }/>
  <path d="M131.902 109.452C131.674 109.452 131.219 109.224 131.219 108.768C131.219 108.54 131.447 108.084 131.902 108.084C132.13 108.084 132.586 108.312 132.586 108.768C132.586 109.224 132.358 109.452 131.902 109.452Z" fill={ color }/>
  <path d="M125.98 109.452C125.752 109.452 125.297 109.224 125.297 108.768C125.297 108.54 125.525 108.084 125.98 108.084C126.208 108.084 126.664 108.312 126.664 108.768C126.436 108.996 126.208 109.452 125.98 109.452Z" fill={ color }/>
  <path d="M119.831 109.223C119.603 109.223 119.375 108.995 119.375 108.767C119.375 108.539 119.603 108.311 119.831 108.311C120.058 108.311 120.286 108.539 120.286 108.767C120.514 108.995 120.286 109.223 119.831 109.223Z" fill={ color }/>
  <path d="M113.905 109.223C114.156 109.223 114.36 109.019 114.36 108.767C114.36 108.515 114.156 108.311 113.905 108.311C113.653 108.311 113.449 108.515 113.449 108.767C113.449 109.019 113.653 109.223 113.905 109.223Z" fill={ color }/>
  <path d="M107.983 109.223C107.755 109.223 107.527 108.995 107.527 108.767C107.527 108.539 107.755 108.311 107.983 108.311C108.211 108.311 108.439 108.539 108.439 108.767C108.439 108.995 108.211 109.223 107.983 109.223Z" fill={ color }/>
  <path d="M102.061 109.223C102.313 109.223 102.517 109.019 102.517 108.767C102.517 108.515 102.313 108.311 102.061 108.311C101.809 108.311 101.605 108.515 101.605 108.767C101.605 109.019 101.809 109.223 102.061 109.223Z" fill={ color }/>
  <path d="M95.9075 108.995C95.6797 108.995 95.6797 108.767 95.6797 108.767L95.9075 108.539L96.1353 108.767C96.1353 108.767 96.1353 108.995 95.9075 108.995Z" fill={ color }/>
  <path d="M89.9873 108.995C89.7595 108.995 89.7595 108.995 89.9873 108.995C89.7595 108.539 89.7595 108.539 89.9873 108.539C90.2151 108.539 90.2151 108.539 89.9873 108.995C90.2151 108.995 90.2151 108.995 89.9873 108.995Z" fill={ color }/>
  <path d="M84.0638 108.995C84.1896 108.995 84.2916 108.893 84.2916 108.767C84.2916 108.641 84.1896 108.539 84.0638 108.539C83.9379 108.539 83.8359 108.641 83.8359 108.767C83.8359 108.893 83.9379 108.995 84.0638 108.995Z" fill={ color }/>
  <path d="M242.847 114.924C242.619 114.924 242.619 114.924 242.847 114.924C242.619 114.696 242.619 114.696 242.847 114.924C242.847 114.696 242.847 114.696 242.847 114.924Z" fill={ color }/>
  <path d="M236.695 114.924C236.467 114.696 236.695 114.468 236.695 114.468C236.923 114.696 236.923 114.696 236.695 114.924C236.923 114.924 236.923 114.924 236.695 114.924Z" fill={ color }/>
  <path d="M230.775 114.924C230.547 114.924 230.547 114.924 230.547 114.696C230.547 114.468 230.547 114.468 230.775 114.468C231.002 114.468 231.002 114.468 231.002 114.696C231.002 114.924 231.002 114.924 230.775 114.924Z" fill={ color }/>
  <path d="M224.849 115.152C224.621 115.152 224.621 114.924 224.621 114.924C224.621 114.696 224.849 114.696 224.849 114.696C225.077 114.696 225.077 114.924 225.077 114.924L224.849 115.152Z" fill={ color }/>
  <path d="M218.698 115.152C218.47 115.152 218.242 114.924 218.242 114.696C218.242 114.468 218.47 114.24 218.698 114.24C218.926 114.24 219.153 114.468 219.153 114.696C219.153 114.924 218.926 115.152 218.698 115.152Z" fill={ color }/>
  <path d="M212.776 115.152C212.548 115.152 212.32 114.924 212.32 114.696C212.32 114.468 212.548 114.24 212.776 114.24C213.004 114.24 213.232 114.468 213.232 114.696C213.232 114.924 213.004 115.152 212.776 115.152Z" fill={ color }/>
  <path d="M206.854 115.152C206.626 115.152 206.398 114.924 206.398 114.696C206.398 114.468 206.626 114.24 206.854 114.24C207.082 114.24 207.31 114.468 207.31 114.696C207.31 114.924 207.082 115.152 206.854 115.152Z" fill={ color }/>
  <path d="M194.78 115.152C194.552 115.152 194.324 114.924 194.324 114.696C194.324 114.468 194.552 114.24 194.78 114.24C195.008 114.24 195.235 114.468 195.235 114.696C195.235 114.924 195.008 115.152 194.78 115.152Z" fill={ color }/>
  <path d="M188.854 115.152C188.626 115.152 188.398 114.924 188.398 114.696C188.398 114.468 188.626 114.24 188.854 114.24C189.082 114.24 189.31 114.468 189.31 114.696C189.31 114.924 189.082 115.152 188.854 115.152Z" fill={ color }/>
  <path d="M182.932 115.152C182.704 115.152 182.477 114.924 182.477 114.696C182.477 114.468 182.704 114.24 182.932 114.24C183.16 114.24 183.388 114.468 183.388 114.696C183.388 114.924 183.16 115.152 182.932 115.152Z" fill={ color }/>
  <path d="M176.78 115.152C176.552 115.152 176.324 114.924 176.324 114.696C176.324 114.468 176.552 114.24 176.78 114.24C177.008 114.24 177.235 114.468 177.235 114.696C177.235 114.924 177.008 115.152 176.78 115.152Z" fill={ color }/>
  <path d="M170.858 115.152C170.63 115.152 170.402 114.924 170.402 114.696C170.402 114.468 170.63 114.24 170.858 114.24C171.086 114.24 171.314 114.468 171.314 114.696C171.314 114.924 171.086 115.152 170.858 115.152Z" fill={ color }/>
  <path d="M164.936 115.152C164.708 115.152 164.48 114.924 164.48 114.696C164.48 114.468 164.708 114.24 164.936 114.24C165.164 114.24 165.392 114.468 165.392 114.696C165.164 114.924 165.164 115.152 164.936 115.152Z" fill={ color }/>
  <path d="M158.784 115.152C158.556 115.152 158.328 114.924 158.328 114.696C158.328 114.468 158.556 114.24 158.784 114.24C159.012 114.24 159.239 114.468 159.239 114.696C159.239 114.924 159.012 115.152 158.784 115.152Z" fill={ color }/>
  <path d="M152.862 115.152C152.634 115.152 152.406 114.924 152.406 114.696C152.406 114.468 152.634 114.24 152.862 114.24C153.09 114.24 153.317 114.468 153.317 114.696C153.317 114.924 153.09 115.152 152.862 115.152Z" fill={ color }/>
  <path d="M146.939 115.152C146.711 115.152 146.711 114.924 146.711 114.924C146.711 114.696 146.939 114.696 146.939 114.696C147.167 114.696 147.167 114.924 147.167 114.924C147.167 114.924 147.167 115.152 146.939 115.152Z" fill={ color }/>
  <path d="M141.017 115.152C140.789 115.152 140.789 114.924 140.789 114.924C140.789 114.696 141.017 114.696 141.017 114.696C141.245 114.696 141.245 114.924 141.245 114.924L141.017 115.152Z" fill={ color }/>
  <path d="M134.865 114.924C134.637 114.924 134.637 114.924 134.637 114.696C134.637 114.468 134.637 114.468 134.865 114.468C135.092 114.468 135.092 114.468 135.092 114.696C135.092 114.924 135.092 114.924 134.865 114.924Z" fill={ color }/>
  <path d="M128.94 114.924C128.713 114.924 128.713 114.924 128.94 114.924C128.713 114.696 128.94 114.468 128.94 114.468C128.94 114.468 129.168 114.696 128.94 114.924C129.168 114.924 128.94 114.924 128.94 114.924Z" fill={ color }/>
  <path d="M123.019 114.924C122.791 114.924 122.791 114.924 123.019 114.924C122.791 114.696 123.019 114.696 123.019 114.696C123.019 114.696 123.019 114.696 123.019 114.924Z" fill={ color }/>
  </svg>
}

const Noise = ({ className = '', color = 'purple', multiColor }) => {

  const colorVariants = {
    red: '#FF386B',
    yellow: '#EBFF38',
    blue: '#405BFF',
    pink: '#FF386B',
    cyan: '#3DD6F5',
    purple: '#A34FDE',
  }

  return (
    <div className={multiColor ? `${className} ${styles.multiColor}` : className}>
      <NoiseSVG color={colorVariants[color]} />
    </div>
  )
};

export default Noise;
